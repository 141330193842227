import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { APIRoutes } from "../../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
const UploadFile: any = ({
  title,
  buttonName,
  open,
  handleClose,
  submitClose,
  data,
}: {
  title: string;
  buttonName: string;
  open: boolean;
  handleClose: any;
  submitClose: any;
  data: any;
}) => {
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [image, setImage] = useState<any>(null);

  const submitPopup = async () => {
    if (!image) {
      toast.error("Please select image");
      return;
    }
    try {
      setIsSkeleton(true);
      const formData = new FormData();

      formData.append("files", image);

      const res = await client.post(APIRoutes.UploadCampaignsImage, formData);

      const imageUrl = res.data.Location;
      submitClose({
        item: data,
        imageUrl
      })
      setIsSkeleton(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsSkeleton(false);
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          bgcolor: "common.blue",
          minWidth: "400px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "22px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <Button variant="contained" component="label">
            Upload
            <input
              onChange={(event) => {
                console.log(event.target.files);
                setImage(event.target.files ? event.target.files[0] : null);
              }}
              hidden
              accept="image/*"
              multiple
              type="file"
            />
          </Button>
          {image && image?.name && (
            <Typography sx={{ color: "#fff", backgroundColor: "transparent !important",marginLeft: "5px" }} component={"body"}>
              {image?.name}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            sx={{ marginRight: "10px" }}
            variant="outlined"
            onClick={handleClose}
          >
            <Typography variant="body2">Cancel</Typography>
          </Button>
          <LoadingButton
            onClick={() => {
              submitPopup();
            }}
            loading={isSkeleton}
            type="button"
            loadingPosition="start"
            startIcon={isSkeleton && <SaveIcon />}
            variant="contained"
          >
            {buttonName}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default UploadFile;
