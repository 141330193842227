import {
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { TimeZoneData } from "../../../utils/constants";
import Style from "../../MyCampaigns/MyCampaigns.style";
import { useNavigate } from "react-router";
const AccountSetting = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.MyCampaignsHeader}>
          <Typography variant="h2">Account Settings</Typography>
        </Box>
      </Box>

      <Divider sx={{ borderColor: "#cccccc", pt: 4 }} />
      <Box>
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Weekly report
            </Typography>
            <Typography variant="body2">
              Do you want to receive a weekly report on your sending activities
              and statistics?
            </Typography>
          </Box>
          <Box>
            <Switch />
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Account notifications
            </Typography>
            <Typography variant="body2">
              Do you want to receive an SMS to be alerted if your account is
              blocked?
            </Typography>
          </Box>
          <Box>
            <Switch />
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Your timezone
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Please select a timezone to schedule your emails
            </Typography>
            <Select
              size="small"
              displayEmpty
              defaultValue={"Asia/Kolkata"}
              sx={{
                ".MuiSelect-select": {
                  py: 1,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                sx: {
                  ".MuiPaper-root": {
                    bgcolor: "#072B41",
                  },
                },
              }}
            >
              {TimeZoneData.map((el) => (
                <MenuItem
                  sx={{
                    fontSize: "14px",
                  }}
                  value={el.name}
                >
                  {`(${el.offset})`} {el?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Your Language
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Please select a your Language.
            </Typography>
            <Select
              size="small"
              displayEmpty
              defaultValue={""}
              sx={{
                ".MuiSelect-select": {
                  py: 1,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                sx: {
                  ".MuiPaper-root": {
                    fontSize: "14px",
                    bgcolor: "#072B41",
                  },
                },
              }}
            >
              <MenuItem
                sx={{
                  fontSize: "14px",
                }}
                value=""
              >
                English(US)
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {" "}
          <Box>
            <Button variant="contained">Save</Button>
          </Box>
          <Box>
            <Button variant="text" onClick={()=>{navigate(-1)}}>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default AccountSetting;
