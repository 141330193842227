import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  FooterBlock,
  FooterBody,
  PaddingBlock,
  StyleDivider,
} from "./Footer.style";

import FooterLogo from "../../../assets/img/FooterLogo.svg";
import CallIcon from "../../../assets/img/call.png";
import LocationIcon from "../../../assets/img/location.png";
import InstaIcon from "../../../assets/img/insta.png";
import TiktokIcon from "../../../assets/img/tiktok.png";
import YouTubeIcon from "../../../assets/img/youtube.png";
import AppStoreIcon from "../../../assets/img/appstore.png";
import PlayStoreIcon from "../../../assets/img/playstore.png";
import FooterBG from "../../../assets/img/FooterBG.png";
import { Call, CallOutlined, RoomOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import PageRoutes from "../../../utils/constants";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      id="footer"
      sx={{ ...FooterBlock, backgroundImage: `url(${FooterBG})` }}
    >
      {/* <Box sx={PaddingBlock} /> */}
      <Box sx={FooterBody}>
        <Container
          maxWidth="xl"
          sx={
            {
              // background: " #EDF0F7",
            }
          }
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 5,
              padding: "20px",
              justifyContent: { xs: "start", lg: "space-between;" },
            }}
          >
            <Box
              sx={{
                width: "250px",
                m: { xs: "auto", md: 0 },
              }}
            >
              <img src={FooterLogo} width="100%" alt="Logo" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                textAlign: "left",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <RoomOutlined sx={{ color: "#58F2FD", fontSize: "28px" }} />
                <Typography
                  variant="body1"
                  sx={{
                    // fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,

                    lineHeight: "28px",
                  }}
                >
                  2111 Kramer St <br />
                  Los Angeles, CA 90013
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <CallOutlined sx={{ color: "#58F2FD", fontSize: "28px" }} />
                <Typography
                  variant="body1"
                  sx={{
                    // fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,

                    lineHeight: "28px",
                  }}
                >
                  (970) 312-9736
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                textAlign: "left",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                onClick={() => navigate(PageRoutes.PrivacyPolicy)}
              >
                Privacy & Legal
              </Typography>
              <Typography
                variant="body1"
                onClick={() => navigate(PageRoutes.TermAndCondition)}
              >
                Terms and Conditions
              </Typography>
              <Typography variant="body1">Contact</Typography>
              <Typography variant="body1">Data Deletion Request</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                m: { xs: "auto", md: 0 },
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <IconButton>
                  <img
                    src={InstaIcon}
                    alt="Insta Icon"
                    height="40px"
                    width="40px"
                  />
                </IconButton>

                <Divider
                  sx={StyleDivider}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <IconButton>
                  <img
                    src={YouTubeIcon}
                    alt="YouTube Icon "
                    height="50px"
                    width="50px"
                  />
                </IconButton>
                <Divider
                  sx={StyleDivider}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
                <IconButton>
                  <img
                    src={TiktokIcon}
                    alt="Ticktok Icon "
                    height="44px"
                    width="44px"
                  />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <img
                  src={AppStoreIcon}
                  alt="Appstore Icon "
                  height="41px"
                  width="136px"
                />
                <img
                  src={PlayStoreIcon}
                  alt="Playstore Icon "
                  height="41px"
                  width="136px"
                />
              </Box>
            </Box>
          </Box>
        </Container>
        <Divider sx={StyleDivider} variant="middle" />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
            padding: "20px 20px",
          }}
        >
          <Typography variant="body1">
            2023 © AIRYIS All rights reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
