import { Box } from "@mui/material";
import React from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import MainContainer from "../Components/MainContainer";
import Style from "../Components/MainContainer/MainContainer.style";
import Sidebar from "../Components/Sidebar";

import PageRoutes from "../../utils/constants";
import useCommonDetails from "../Hooks/useCommonDetails";

function PublicRoute({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const auth = getCurrentAuth();
  let location = useLocation();
  const navigate = useNavigate();
  const userDetails = useCommonDetails();
  console.log(userDetails,'userDetails')

  if (auth.access) {
    if(userDetails && !userDetails?.IsOnboarded) {
      return (
        <Navigate to={PageRoutes.Onboard} state={{ from: location }} replace />
      );
    } else {
      return (
        <Navigate to={PageRoutes.DashBoard} state={{ from: location }} replace />
      );
    }
    // return (
    //   <Navigate to={PageRoutes.DashBoard} state={{ from: location }} replace />
    // );
  }
  return (
    <Box sx={{ ...Style.MainContainerStyle, ml: 0, minHeight: "100vh" }}>
      <Box
        sx={{
          display:
            location.pathname == PageRoutes.Login ||
            location.pathname == PageRoutes.VerifyEmail ||
            location.pathname == PageRoutes.ResetPassword
              ? "none"
              : "block",
        }}
      >
        <Header />
      </Box>
      <Box sx={{ bgcolor: "#031827", p: 0 }}>{children}</Box>
      <Box
        sx={{
          display:
            location.pathname == PageRoutes.Login ||
            location.pathname == PageRoutes.VerifyEmail ||
            location.pathname == PageRoutes.ResetPassword
              ? "none"
              : "block",
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
}

export default PublicRoute;
