import { Typography, Button, Tab, Tabs, styled, Skeleton, CircularProgress, Badge, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Style from "./ContactPage.style";

import EnhancedTable from "./TableComponent";
import { useNavigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import notFoundImg from "../../assets/img/not_found.png";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const StyledTab = styled(Tab)({
  color: "#FFFFFF",
  textTransform: "none",
  padding: 0,

  minHeight: "40px",
});
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Contacts: FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const client = useAxiosPrivate(axios);
  const [getContact, setGetContact] = useState<any>();
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down('sm'));

  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.ContactGet);
      setGetContact(res.data.data);
      setLoading(false);
      
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGet();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const updateMainAPI = () => {
    handleGet();
  }
  return (
    <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 ,ml:"4px"}}>
          <Typography variant="h2">My contact lists</Typography>
          <Typography variant="body1">
            Manage your contacts in lists to easily send campaigns and automated
            email workflows.
          </Typography>
        </Box>

        <Button
          variant="contained"
          size={isSmallScreen?"small":"medium"}
          sx={{textTransform: 'none'}}
          onClick={() => {
            navigate(PageRoutes.ContactCreate);
          }}
        >
          <Typography variant="body2" fontSize={isSmallScreen?"13px":"16px"} >
            Create contact list</Typography>
        </Button>
      </Box>
      <Box sx={{ py: { xs: 2, md: 5 } }}>
        {loading?(<CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />):(
            <Box sx={{ width: "100%" }}>
              <TabPanel value={value} index={0}>
                {getContact &&(
                  getContact.length?(<EnhancedTable data={getContact} updateMainAPI={updateMainAPI} />):
                  ( <Box color="secondary"  sx={{ py:'40px',display: 'flex', justifyContent: 'center', alignItems: 'center',flex: 1,}}>
                    <Typography variant="h1">No Data Found </Typography>
                    </Box>
                  )
                )}
              </TabPanel>
            </Box>
        )}
      </Box>
    </Box>
  );
};
export default Contacts;
