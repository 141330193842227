import { SxProps } from "@mui/material";
import BgImage from "../../assets/img/SIGNUP.png";
const Style: { [key: string]: SxProps } = {
  MyCampaignsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  optionsColor:{
    color:"black",
    fontWeight:"400",
  }
};
export default Style;
