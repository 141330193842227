import EmailCatogary from "../Pages/EmailCatogary";

const PageRoutes = {
  Login: "/login",
  DashBoard: "/dashboard",
  CustomerExperience: "/customer-experience",
  HomePage: "/",
  Apps: "/apps",
  MachineLearning: "/machine-learning",
  Pricing: "/pricing",
  AccountPage: "/account",
  WaitListPage: "/wait-list",
  Onboard: "/onboard",
  Profile: "/account",
  Any: "/*",
  Contact: "/contact",
  UpdateProfile: "/account/update-profile",
  AccountSetting: "/account/account-profile",
  StoreSetting: "/account/email",
  UpdateOnboard: "/account/update-onboard",
  VerifyEmail: "/verify-email",
  ResetPassword: "/reset-password",
  MyCampaigns: "/campaigns",
  MyCampaignsInnerList: "/campaigns/campaign-details/:id",
  MyCampaignDetail: "/campaigns/edit/:id",
  EmailEditor: "/email-editor/:id",
  EmailCatogary: "/Industry",
  EmailTemplate: "/email-category/template/:subId",
  // IndustryCatogary: "/Industry/Category/:id",
  IndustryCatogary: "/email-category",
  ShopifyProducts: "/shopify-products",
  ContactCreate: "/contact/creation",
  ContactDetails: "/contact/contact-details/:id",
  PrivacyPolicy: "/privacy-policy",
  DataDeletion: "/data-deletion-instruction",
  TermAndCondition: "/term-conditions",
  Calender: "/calender",
};

export const APIRoutes = {
  RefreshToken: "/auth/refresh-tokens",
  SignIn: "/signin",
  GoogleAuth: "/auth/google-auth",
  Login: "/auth/login",
  AppleAuth: "/auth/apple-auth",
  Register: "/auth/register",
  VerifyEmail: "/auth/verify-email",
  ForgetPassword: "/auth/forgot-password",
  ResetPassword: "/auth/reset-password",

  ProfileNameUpdate: "/auth/update-profile",
  PasswordChange: "/auth/change-password",

  PostTypeform: "/user-preference/create",
  PostformData: "/user-preference/get",
  PostformUpdate: "/user-preference/update/:id",

  ContactGet: "/user-contact/get-contact",
  ContactAdd: "/user-contact/create-contact",
  UpdateContact: "/user-contact/update-contact/:id",
  ContactDetailAdd: "/user-contact/create-contact-detail",
  DeleteContact: "/user-contact/delete-contact/:id",
  DeleteContactList: "/user-contact/delete-contact-detail/:id",
  ContactDetailGet: "/user-contact/get-contact-detail/:id",

  UploadCampaignsImage: "/user-marketing-campaign/uploadImage",

  GetIndustry: "/miscellaneous/get-industry",
  GetIndustryDetails: "/miscellaneous/get-industry-category/:id",
  GetEmailTemplate: "/miscellaneous/get-email-template",

  UserTemplateDelete: "/user-template/delete/:id",
  UserTemplateList: "/user-template/list/",
  UpdateUserTemplate: "/user-template/update/:id",
  GetUserTemplateById: "/user-template/get/:id",
  DefualtTemplateById: "/user-template/default/:id",

  CategoryList: "/user-marketing-campaign/user-category-list",
  ShopifyProductsList: "/get-shopify-products-list",
  ListCampaign: "/user-marketing-campaign/get-campaign-list",
  LIstInnerCampaign: "/user-marketing-campaign/schedule/:id",
  ScheduleById: "/user-marketing-campaign//getschedule/:scheduleId",
  CreateSchedule: "/user-marketing-campaign/schedule",
  DeleteCampaign: "/user-marketing-campaign/delete-campaign/:id",
  DeleteInnerCampaign: "/user-marketing-campaign/delete/:id",
  AddtoFavourite: "/user-marketing-campaign/add-to-favorite/:id",
  RenameCampaign: "/user-marketing-campaign/renameCampaign",
  UpdateCampaign: "/user-marketing-campaign/update-campaign-schedule",
  GetCampaign: "/user-marketing-campaign/get/:id",
  GetCalenderData: "/user-marketing-campaign/calendar",
  UpdateScheduleCampaign: "/user-marketing-campaign/update",
  GetScheduleById: "/user-marketing-campaign/get/:id",
  DashboardCount: "/dashboard/dashboard-count",
  DashboardGraph: "/dashboard/dashboard-graph"
};

export const TimeZoneData = [
  {
    offset: "GMT-12:00",
    name: "Etc/GMT-12",
  },
  {
    offset: "GMT-11:00",
    name: "Etc/GMT-11",
  },
  {
    offset: "GMT-11:00",
    name: "Pacific/Midway",
  },
  {
    offset: "GMT-10:00",
    name: "America/Adak",
  },
  {
    offset: "GMT-09:00",
    name: "America/Anchorage",
  },
  {
    offset: "GMT-09:00",
    name: "Pacific/Gambier",
  },
  {
    offset: "GMT-08:00",
    name: "America/Dawson_Creek",
  },
  {
    offset: "GMT-08:00",
    name: "America/Ensenada",
  },
  {
    offset: "GMT-08:00",
    name: "America/Los_Angeles",
  },
  {
    offset: "GMT-07:00",
    name: "America/Chihuahua",
  },
  {
    offset: "GMT-07:00",
    name: "America/Denver",
  },
  {
    offset: "GMT-06:00",
    name: "America/Belize",
  },
  {
    offset: "GMT-06:00",
    name: "America/Cancun",
  },
  {
    offset: "GMT-06:00",
    name: "America/Chicago",
  },
  {
    offset: "GMT-06:00",
    name: "Chile/EasterIsland",
  },
  {
    offset: "GMT-05:00",
    name: "America/Bogota",
  },
  {
    offset: "GMT-05:00",
    name: "America/Havana",
  },
  {
    offset: "GMT-05:00",
    name: "America/New_York",
  },
  {
    offset: "GMT-04:30",
    name: "America/Caracas",
  },
  {
    offset: "GMT-04:00",
    name: "America/Campo_Grande",
  },
  {
    offset: "GMT-04:00",
    name: "America/Glace_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Goose_Bay",
  },
  {
    offset: "GMT-04:00",
    name: "America/Santiago",
  },
  {
    offset: "GMT-04:00",
    name: "America/La_Paz",
  },
  {
    offset: "GMT-03:00",
    name: "America/Argentina/Buenos_Aires",
  },
  {
    offset: "GMT-03:00",
    name: "America/Montevideo",
  },
  {
    offset: "GMT-03:00",
    name: "America/Araguaina",
  },
  {
    offset: "GMT-03:00",
    name: "America/Godthab",
  },
  {
    offset: "GMT-03:00",
    name: "America/Miquelon",
  },
  {
    offset: "GMT-03:00",
    name: "America/Sao_Paulo",
  },
  {
    offset: "GMT-03:30",
    name: "America/St_Johns",
  },
  {
    offset: "GMT-02:00",
    name: "America/Noronha",
  },
  {
    offset: "GMT-01:00",
    name: "Atlantic/Cape_Verde",
  },
  {
    offset: "GMT",
    name: "Europe/Belfast",
  },
  {
    offset: "GMT",
    name: "Africa/Abidjan",
  },
  {
    offset: "GMT",
    name: "Europe/Dublin",
  },
  {
    offset: "GMT",
    name: "Europe/Lisbon",
  },
  {
    offset: "GMT",
    name: "Europe/London",
  },
  {
    offset: "UTC",
    name: "UTC",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Algiers",
  },
  {
    offset: "GMT+01:00",
    name: "Africa/Windhoek",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Azores",
  },
  {
    offset: "GMT+01:00",
    name: "Atlantic/Stanley",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Amsterdam",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Belgrade",
  },
  {
    offset: "GMT+01:00",
    name: "Europe/Brussels",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Cairo",
  },
  {
    offset: "GMT+02:00",
    name: "Africa/Blantyre",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Beirut",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Damascus",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Gaza",
  },
  {
    offset: "GMT+02:00",
    name: "Asia/Jerusalem",
  },
  {
    offset: "GMT+03:00",
    name: "Africa/Addis_Ababa",
  },
  {
    offset: "GMT+03:00",
    name: "Asia/Riyadh89",
  },
  {
    offset: "GMT+03:00",
    name: "Europe/Minsk",
  },
  {
    offset: "GMT+03:30",
    name: "Asia/Tehran",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Dubai",
  },
  {
    offset: "GMT+04:00",
    name: "Asia/Yerevan",
  },
  {
    offset: "GMT+04:00",
    name: "Europe/Moscow",
  },
  {
    offset: "GMT+04:30",
    name: "Asia/Kabul",
  },
  {
    offset: "GMT+05:00",
    name: "Asia/Tashkent",
  },
  {
    offset: "GMT+05:30",
    name: "Asia/Kolkata",
  },
  {
    offset: "GMT+05:45",
    name: "Asia/Katmandu",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Dhaka",
  },
  {
    offset: "GMT+06:00",
    name: "Asia/Yekaterinburg",
  },
  {
    offset: "GMT+06:30",
    name: "Asia/Rangoon",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Bangkok",
  },
  {
    offset: "GMT+07:00",
    name: "Asia/Novosibirsk",
  },
  {
    offset: "GMT+08:00",
    name: "Etc/GMT+8",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Hong_Kong",
  },
  {
    offset: "GMT+08:00",
    name: "Asia/Krasnoyarsk",
  },
  {
    offset: "GMT+08:00",
    name: "Australia/Perth",
  },
  {
    offset: "GMT+08:45",
    name: "Australia/Eucla",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Irkutsk",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Seoul",
  },
  {
    offset: "GMT+09:00",
    name: "Asia/Tokyo",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Adelaide",
  },
  {
    offset: "GMT+09:30",
    name: "Australia/Darwin",
  },
  {
    offset: "GMT+09:30",
    name: "Pacific/Marquesas",
  },
  {
    offset: "GMT+10:00",
    name: "Etc/GMT+10",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Brisbane",
  },
  {
    offset: "GMT+10:00",
    name: "Australia/Hobart",
  },
  {
    offset: "GMT+10:00",
    name: "Asia/Yakutsk",
  },
  {
    offset: "GMT+10:30",
    name: "Australia/Lord_Howe",
  },
  {
    offset: "GMT+11:00",
    name: "Asia/Vladivostok",
  },
  {
    offset: "GMT+11:30",
    name: "Pacific/Norfolk",
  },
  {
    offset: "GMT+12:00",
    name: "Etc/GMT+12",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Anadyr",
  },
  {
    offset: "GMT+12:00",
    name: "Asia/Magadan",
  },
  {
    offset: "GMT+12:00",
    name: "Pacific/Auckland",
  },
  {
    offset: "GMT+12:45",
    name: "Pacific/Chatham",
  },
  {
    offset: "GMT+13:00",
    name: "Pacific/Tongatapu",
  },
  {
    offset: "GMT+14:00",
    name: "Pacific/Kiritimati",
  },
];
export const que: any = [
  {
    group: "Business Information",
    quetion: "What is your business name?",
    infoText: "ex. TechCraft Solutions",
    ans: "",
    type: "textfeild",
    id: 1,
    option: [],
  },
  {
    group: "Business Information",
    quetion: "Please provide a brief description of your business?",
    infoText:
      'ex. The combination of "Tech" and "Craft" suggests expertise and skill in technology-related services, while "Solutions" indicates that the business provides problem-solving services to its customers.',
    ans: "",
    type: "textarea",
    id: 2,
    option: [],
  },
  {
    group: "Business Information",
    quetion: "What industry are you in?",
    infoText: "ex. Technology Solutions",
    ans: "",
    type: "select-single-id",
    id: 3,
    option: [
      {
          "id" : "1",
          "name": "Fashion",
          "isDeleted" : false 
      },
      {
          "id" : "2",
          "name": "Travelling",
          "isDeleted" : false 
      },
      {
          "id" : "3",
          "name": "Information & Technology",
          "isDeleted" : false 
      },
      {
          "id" : "4",
          "name": "Mechanical",
          "isDeleted" : false 
      }
  ],
  },
  {
    group: "Business Information",
    quetion: "What products/services do you offer?",
    infoText: "ex. Software Development, Web Development and Data Analytics",
    ans: "",
    type: "textfeild",
    id: 4,
    option: [],
  },
  {
    group: "Business Information",
    quetion: "What sets your business apart from your competitors?",
    ans: "",
    infoText:
      "Specialized Expertise, Quality and Reliability, Competitive Pricing",
    type: "textfeild",
    id: 5,
    option: [],
  },
  {
    group: "Business Information",
    quetion: "What is your company’s mission statement or core values?",
    infoText:
      '"Our mission at TechCraft Solutions is to provide innovative and reliable technology solutions to empower businesses and individuals',
    ans: "",
    type: "textfeild",
    id: 6,
    option: [],
  },
  {
    group: "Business Information",
    quetion: "What is your website address?",
    infoText: "www.techcraft.com",
    ans: "",
    type: "textfeild",
    id: 7,
    option: [],
  },
  {
    group: "Target Audience",
    quetion:
      "Who is your target audience? Please provide as much detail as possible (age, gender, location, interests, occupation, income level, etc.",
    infoText:
      "age:18-24,gender:male,location:mumbai,interests:web development,occupation:IT-field...",
    ans: "",
    type: "textfeild",
    id: 8,
    option: [],
  },
  {
    group: "Target Audience",
    quetion: "How do your customers usually discover your product/service?",
    infoText:
      "Digital Marketing, Referrals and Word-of-Mouth, Collaborations and Partnerships etc.",
    ans: "",
    type: "textfeild",
    id: 9,
    option: [],
  },
  {
    group: "Email Marketing Strategy",
    quetion:
      "What are the goals of your email marketing campaigns? (Select all that apply.)",
    infoText: "Please select option",
    ans: "",
    type: "select-multiple",
    id: 10,
    option: [
      "Increase brand awareness",
      "Drive traffic to the website I provided",
      "Generate new leads",
      "Convert leads into customers",
    ],
  },
  {
    group: "Email Marketing Strategy",
    quetion:
      "What types of emails are you interested in sending? (Select all that apply)",
    infoText: "Please select option",
    ans: "",
    type: "select-multiple",
    id: 11,
    option: ["Newsletters", "Promotional emails", "Transactional emails"],
  },
  {
    group: "Email Marketing Strategy",
    quetion: "How often do you plan to send emails?",
    infoText: "Please select option",
    ans: "",
    type: "select-multiple",
    id: 12,
    option: [
      "As often as recommended",
      "Daily",
      "Weekly",
      "Monthly",
      "Quarterly",
    ],
  },
  {
    group: "Email Marketing Strategy",
    quetion: "How many contacts do you have in your email list?",
    infoText: "Please select option",
    ans: "",
    type: "select-single",
    id: 13,
    option: [
      "500 or less",
      "1,500",
      "2,500",
      "5,000",
      "10,000",
      "15,000",
      "20,000",
      "25,000",
      "30,000",
      "40,000",
      "50,000",
      "75,000",
      "100,000",
      "130,000",
      "150,000",
      "200,000",
      "250,000",
    ],
  },
  {
    group: "Content Strategy",
    quetion: "What types of content are you interested in creating?",
    infoText: "Please select option",
    ans: "",
    type: "select-multiple",
    id: 14,
    option: [
      "Welcome Series for New Subscribers",
      "Promotional Emails",
      "Guidesand Blogs",
      "Loyalty and Rewards, Testimonials and Reviews, Surveys and Feedback",
      "Behavior Based Emails",
      "Newsletters and Company Announcements",
    ],
  },
  {
    group: "Content Strategy",
    quetion:
      "What topics or themes are most relevant to your business and your audience?",
    infoText:
      "Web Development and User Experience,Software Development and Programming",
    ans: "",
    type: "textfeild",
    id: 15,
    option: [],
  },
  {
    group: "Specific Preferences",
    quetion:
      "What tone do you prefer in your content (you can change this later)?",
    ans: "",
    infoText: "Please select option",
    type: "tone",
    id: 16,
    notice: `Remember, the tone can significantly influence how your message is received by the reader, so it's important to choose the right tone for your purpose and audience.`,
    option: [
      {
        itme: "Formal",
        tooltip:
          "This tone is professional, respectful, and devoid of slang or colloquial language. It's often used in academic, scientific, legal, and official documents.",
      },
      {
        itme: "Informal",
        tooltip:
          "This tone is conversational and may include slang, idioms, contractions, and colloquialisms. It's typically used in personal emails, text messages, and certain types of creative writing.",
      },
      {
        itme: "Persuasive",
        tooltip:
          "This tone aims to convince the reader of a certain viewpoint. It's often used in advertising, opinion pieces, and sales pitches.",
      },
      {
        itme: "Objective",
        tooltip:
          "This tone presents facts without personal emotion or opinion. It's commonly used in news reporting and academic writing",
      },
      {
        itme: "Subjective",
        tooltip:
          "This tone presents the writer's personal feelings or opinions. It's commonly used in personal essays, reviews, and opinion pieces.",
      },
      {
        itme: "Humorous",
        tooltip:
          "This tone injects humor to entertain the reader. It's often used in comedy writing, satire, and certain types of creative writing.",
      },
      {
        itme: "Sarcastic",
        tooltip:
          "This tone uses irony to mock or convey contempt. It can be used in satire, but it should be used carefully, as it can come off as rude or disrespectful.",
      },
      {
        itme: "Inspirational",
        tooltip:
          "This tone aims to inspire or motivate the reader. It's often used in self-help books, motivational speeches, and certain types of advertising.",
      },
      {
        itme: "Friendly",
        tooltip:
          "This tone is warm, inviting, and approachable. It's often used in casual letters, blog posts, and certain types of marketing materials.",
      },
      {
        itme: "Serious",
        tooltip:
          "This tone communicates a sense of importance or gravity. It's typically used in formal reports, professional emails, and documents discussing serious subjects.",
      },
    ],
  },
  {
    group: "Additional Information",
    quetion:
      "Is there any other information that you think would be helpful for us to know in order to create effective email marketing content for your business?",
    infoText: "Target Audience, Value Proposition, and Mobile Optimization etc",
    ans: "",
    type: "textfeild",
    id: 17,
    option: [],
  },
];
export default PageRoutes;
