import { Box, Typography, Breadcrumbs } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const pathName = window.location.pathname.split("/").filter((x: any) => {
    return isNaN(x);
  });
  const number = window.location.pathname.split("/").filter((x: any) => {
    return !isNaN(x) && x != "";
  });
  if(pathName.includes('email-editor')) {
    return null;
  }
  return (
    <Box >
        <Breadcrumbs aria-label="breadcrumb" separator="›" sx={{ color: 'gray',p:"12px 0 0 24px" }}>
        {pathName.map((name:any,ind)=>{

            const routeTo = name=="Category"?`/${pathName.slice(0, ind + 1).join('/')}/`+number[0]:`/${pathName.slice(0, ind + 1).join('/')}`;
            
            const isLast = ind === pathName.length - 1;
            return isLast ? (
                <Typography sx={{textTransform:"capitalize",fontSize:"16px",color:"#0094EC"}} key={name}>
                  {name.replace("-"," ")}
                </Typography>
            ) : (
                <Link
                  underline="hover"
                  onClick={()=>navigate(routeTo)}
                  sx={{textTransform:"capitalize",cursor:"pointer"}}
                  color="inherit"
                //   onClick={() => navigate(routeTo)}
                  key={name}
                >
                  {name.replace("-"," ")}
                </Link>
            );      
        })}
        {/* <Typography color="text.primary" sx={{textTransform:"capitalize",fontSize:"16px",color:"#70d8bd"}}>{pathName}</Typography> */}
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
