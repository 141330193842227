import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Archive, Delete, Search } from "@mui/icons-material";
import ContactDetailsTableRow from "./ContactDetailsTableRow";
import {
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "../../../utils/axios";
import { APIRoutes } from "../../../utils/constants";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";

interface Data {
  email: string;
  added: string;
  first_name: string;
  last_name: string;
  dob: string;
  gender: string;
  interest: string;
  location: string;
  action: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },

  {
    id: "added",
    numeric: false,
    disablePadding: false,
    label: "Added",
  },

  {
    id: "first_name",
    numeric: false,
    disablePadding: false,
    label: "FirstName",
  },
  {
    id: "last_name",
    numeric: false,
    disablePadding: false,
    label: "LastName",
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "dob",
    numeric: false,
    disablePadding: false,
    label: "Birthday",
  },
  // {
  //   id: "interest",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Interests",
  // },
  // {
  //   id: "location",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Location",
  // },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

interface EnhancedTableProps {
  numSelected: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;

  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            key={headCell.id}
          >
            <Typography variant="body2">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  name: any;
  subId: any;
  updateMainAPI: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected,name,subId,updateMainAPI } = props;
  const {id} = useParams();
  const [getId,setGetId] = React.useState<any>(id);
  const client = useAxiosPrivate(axios);
  console.log(subId,"id");  
  
  const handleDelete = async()=>{
    subId.map(async(ele:any)=>{
      try {
        const res = await client.delete(APIRoutes.DeleteContactList.replace(":id",`${getId}/${ele}`));
        updateMainAPI();
        // toast.success("Successfully deleted");
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return
      }
    })
  }
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          color="inherit"
          variant="h6"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {name}{"'s contact"}
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <Delete sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function ContactDetailsTable(props:any) {
  const {data,dataList,updateMainAPI} = props;
  const [order, setOrder] = React.useState<Order>("asc");

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [orderBy, setOrderBy] = React.useState<keyof Data>("email");
  const [deleteId,setDeleteId] = React.useState<any>([]);
  const [inputsearch, setInputSearch] = React.useState('');
  const [contactData, setContactData] = React.useState<any>(data);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = contactData.map((n: any) => n.Email);
      const selectedId = contactData.map((i:any)=> i.Id);
      setSelected(newSelected);
      setDeleteId(selectedId);
      return;
    }
    setSelected([]);
    setDeleteId([]);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, name: string,id:number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    
    if(event.target.checked){
      setDeleteId([...deleteId,id]);
    }
    else{
      const index = deleteId.indexOf(id);
      deleteId.splice(index,1)
      setDeleteId(deleteId);
    }
    
    setSelected(newSelected);
  };
  const StyleMenuItem = styled(MenuItem)({
    fontSize: "14px",
    bgcolor: "red",
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contactData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(contactData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, inputsearch]
  );

  const handleChangeSearch = (event) => {
    setInputSearch(event.target.value);
    if(event.target.value) {
      let newdata = data.filter((item) => {
        return (item.FirstName.includes(event.target.value) || item.LastName.includes(event.target.value) || item.Email.includes(event.target.value));
      });
      setContactData(newdata);
    } else {
      setContactData(data);
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: 2, py: 2 }}>
        <TextField
        value={inputsearch}
        onChange={(e) => {
          handleChangeSearch(e)
        }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          placeholder="Search"
        />
        {/* <Select
          name="status"
          displayEmpty
          sx={{
            ".MuiSelect-select": {
              py: 1,

              display: "flex",
              alignItems: "center",
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            sx: {
              ".MuiPaper-root": {
                bgcolor: "#072B41",
              },
            },
          }}
        >
          <StyleMenuItem value="">All Contacts</StyleMenuItem>
          <StyleMenuItem>Subscribed</StyleMenuItem>
          <StyleMenuItem>Unsubscribed</StyleMenuItem>
          <StyleMenuItem>Excluded</StyleMenuItem>
        </Select>
        <Select
          name="status"
          displayEmpty
          value=""
          sx={{
            ".MuiSelect-select": {
              py: 1,

              display: "flex",
              alignItems: "center",
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            sx: {
              ".MuiPaper-root": {
                bgcolor: "#072B41",
              },
            },
          }}
        >
          <StyleMenuItem sx={{ display: "none" }} value="">
            Export list
          </StyleMenuItem>
          <StyleMenuItem>CSV File</StyleMenuItem>
          <StyleMenuItem>
            Tab Delimited Text File <br /> (Excel compatible)
          </StyleMenuItem>
        </Select> */}
      </Box>
      <Paper sx={{ width: "100%", mb: 2, bgcolor: "common.blue" }}>
        <EnhancedTableToolbar numSelected={selected.length} name={dataList.ContactName} subId={deleteId} updateMainAPI={updateMainAPI}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={contactData.length}
            />
            <TableBody>
              {visibleRows.map((row:any, index) => {
                const isItemSelected = isSelected(row.Email);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <ContactDetailsTableRow
                    data={row}
                    labelId={labelId}
                    updateMainAPI={updateMainAPI}
                    isItemSelected={isItemSelected}
                    handleClick={(event: any) => handleClick(event, row.Email,row.Id)}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            ".MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
            ".MuiSelect-select": {
              color: "#FFFFFF",
            },
          }}
          SelectProps={{
            MenuProps:{
              sx:{
                '.MuiPaper-root': {
                  backgroundColor: '#09283a',
                },
                ".MuiTablePagination-menuItem.Mui-selected":{
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                backgroundColor: "#15327c"
                },
                '.MuiTablePagination-menuItem': {
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                  backgroundColor: '#09283a',
                },
              }
            }
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={contactData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />{" "}
      </Paper>
    </Box>
  );
}
