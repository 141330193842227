import React,{useState} from 'react'
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from 'dayjs';
import {
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  DialogActions,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  createTheme,
  ThemeProvider,
  Link,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useFormik } from 'formik';
import Papa from "papaparse";

const darkTheme = createTheme({
    palette:{
      mode: 'dark',
    }
})
    
const ContactDetailPopUp = ({contact,setContact}:{contact:any,setContact:any}) => {
  
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [gender,setGender] = useState("male");
  const [dob,setDob] = useState<any>(null);
  const [interest,setInterest] = useState("");
  const [location,setLocation] = useState("");
  const [open, setOpen] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [menualAdd,setMenualAdd] = useState(false);
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down('sm'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      formik.resetForm();
      setOpen(false);
      setMenualAdd(false);
      setDob(null);
  };
  
  const handleDownload = () => {
    const fileURL = "../../../../Example.csv"; // The relative path to the CSV file in the public folder

    // Create an anchor element
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank"; // Open the link in a new tab
    link.download = "Example.csv"; // Set the suggested file name when downloading

    // Dispatch a click event on the anchor element to initiate the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDateChange = (date:any) =>{
    const dates = date.$D<10?('0'+date.$D):date.$D;
    const month = (date.$M+1)<10?('0'+(date.$M+1)):date.$M+1;
    const year = date.$y; 
    
    setDob(`${dates}/${month}/${year}`);  
  }

  const handleCSVData = (result: any) => {
    // Define the expected column names
    const expectedColumns = [
      "Number",
      "First Name",
      "Last Name",
      "Email",
      "Gender",
      "Birth Date",
      // "Interest",
      // "Location",
    ];
    console.log(result);
    const columnNames = result.meta.fields;

    // Check if all expected columns are present
    const missingColumns = expectedColumns.filter(
      (col) => !columnNames.includes(col)
    );
    if (missingColumns.length > 0) {
      toast(`Missing columns:${missingColumns}`);
      // Handle the error or show a notification to the user
      return;
    }

    // Additional validation if required (e.g., data formats, etc.)
    // ...
    const datePattern = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    // If everything is valid, you can access the CSV data in result.data
    console.log("CSV data:", result.data);

    // remove last row 
    const csvData = result.data.filter((row:any) => row.Number.trim() !== "");

    //validation of rows
    const error:any = {};
    error.field="";
    csvData.map((row:any)=>{
      // || row["Interest"]=="" || row["Location"]==""
      if(row["Birth Date"]=="" || row["Email"]=="" || row["First Name"]=="" || row["Last Name"]=="" || row["Gender"]=="" ){
        error.field =`Please Fill All Details Of Line ${row["Number"]} Contact Details`;
        return;
      }
      if(!datePattern.test(row["Birth Date"])){
        error.field =`Please Check Date Of Line ${row["Number"]} In This Formate: dd/mm/yyyy`;
        return;
      }
      // console.log(datePattern.test(row["Birth Date"]),"date");
      
    })
    if(error.field !==""){
      toast.error(error.field);
      return;
    }
    const modifiedCsvData = csvData.map((row: any) => {
    const modifiedRow = { ...row }; // Create a shallow copy of the original object

    if (modifiedRow["Birth Date"]) {
      // If the "Birth Date" property exists, rename it to "birthdate"
      modifiedRow.birthday = modifiedRow["Birth Date"];
      modifiedRow.firstName = modifiedRow["First Name"];
      modifiedRow.lastName = modifiedRow["Last Name"];
      modifiedRow.email = modifiedRow["Email"];
      modifiedRow.gender = modifiedRow["Gender"];
      // modifiedRow.location = modifiedRow["Location"];
      // modifiedRow.interests = modifiedRow["Interest"];
      delete modifiedRow["Birth Date"]; // Remove the old property
      delete modifiedRow["First Name"]; 
      delete modifiedRow["Last Name"]; 
      delete modifiedRow["Email"]; 
      delete modifiedRow["Gender"]; 
      // delete modifiedRow["Location"]; 
      // delete modifiedRow["Interest"]; 
      delete modifiedRow["Number"];
    }

    return modifiedRow;
  });

    console.log(modifiedCsvData);
    !contact?setContact(modifiedCsvData):setContact([...contact,...modifiedCsvData])
        handleClose()
    
  };

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];

    // Read the CSV file using PapaParse
    Papa.parse(file, {
      complete: handleCSVData,
      header: true,
    });
  };

  const handleMenualAdd = ()=>{
    setMenualAdd(true);
  }
  const validate = (values: any) => {
    const errors: any = {};

    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!values.email) {
      errors.email = "Please fill the required fields";
    } else if (!emailRegx.test(values.email)) {
      errors.email = "Please enter valid email id";
    }

    if (!values.first_name) {
      errors.first_name = "Please fill the required fields";
    }
    if (!values.last_name) {
      errors.last_name = "Please fill the required fields";
    }
    // if (!values.dob) {
    //   errors.dob = "Please fill the required fields";
    // }
    if (!values.gender) {
      errors.gender = "Please fill the required fields";
    }
    // if (!values.interest) {
    //   errors.interest = "Please fill the required fields";
    // }
    // if (!values.location) {
    //   errors.location = "Please fill the required fields";
    // }
    // console.log(errors);

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      gender:"male",
      // interest:"",
      // location:"",
    },
    validate,
    validateOnChange: false,
    onSubmit: (values) => {
        if(!dob){
            toast.error("Please enter birthday date");
            return;
        }
        const list = {
            "email":values.email,
            "firstName" : values.first_name, 
            "lastName": values.last_name,
            "gender": values.gender,
            "birthday": dob,
            // "interests": values.interest, 
            // "location": values.location
        }
        
        !contact?setContact([list]):setContact([...contact,list])
        handleClose()
    }
  })
  return (
    <>
      <Button variant="contained" size={isSmallScreen?"small":"medium"} onClick={handleClickOpen}>
        Add contact
      </Button>
      <Dialog
        open={open}
        sx={{
          ".MuiPaper-root": {
            bgcolor: "common.blue",
          },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
            <DialogTitle
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
            id="alert-dialog-title"
            >
            <Typography variant="h3">{"Add Contact Details"}</Typography>
            <IconButton onClick={handleClose}>
                <Close />
            </IconButton>
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{width:{xs:"260px",sm:"420px"}}}>
              <>
                {!menualAdd &&<><Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",mb:3,px:{xs:0,sm:2}}}>
                <Typography>Add Contact
                  <Typography variant="body2" color="gray">Upload a file to add contacts</Typography>
                  <Typography variant="body2" color="#009aea" sx={{ textDecoration: 'underline',cursor: 'pointer'}} onClick={handleDownload}>Download Example File</Typography>
                </Typography>
                <Button variant="contained" component="label" size='small'>
                    Upload
                    <input
                      type="file"
                      hidden
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                </Button>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",px:{xs:0,sm:2},alignItems:"center",mb:3}}>
                <Typography>Add Contacts Manually
                <Typography variant="body2" color="gray">Enter contact details</Typography>
                </Typography>
                <Button variant='contained' size='small' onClick={handleMenualAdd}>Add</Button>
                </Box></>}
                {menualAdd&&<Box>
                <TextField
                    size="small"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                    sx: { bgcolor: "white" },
                    }}
                    type={"text"}
                    value={formik.values.first_name}
                    name="first_name"
                    id="first_name"
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.errors.first_name = "";
                    }}
                    error={formik.errors.first_name? true: false}
                    helperText={formik.errors.first_name}
                    sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                        color: "black",
                    },
                    }}
                    placeholder={"First Name"}
                />
                <TextField
                    size="small"
                    fullWidth
                    InputProps={{
                    sx: { bgcolor: "white" },
                    }}
                    type={"text"}
                    name="last_name"
                    id="last_name"
                    value={formik.values.last_name}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.errors.last_name = "";
                    }}
                    error={formik.errors.last_name? true: false}
                    helperText={formik.errors.last_name}
                    sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                        color: "black",
                    },
                    }}
                    placeholder={"Last Name"}
                />
                <TextField
                    value={formik.values.email}
                    name="email"
                    id="email"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                        sx: { bgcolor: "white" },
                    }}
                    type={"email"}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.errors.email = "";
                    }}
                    error={formik.errors.email? true: false}
                    helperText={formik.errors.email}
                    sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                        color: "black",
                    },
                    }}
                    placeholder={"Email"}
                />
                <Box sx={{my:1}}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                        <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        value={formik.values.gender}
                        name="gender"
                        id="gender"
                        onChange={(e: any) => {
                            formik.handleChange(e);
                            formik.errors.gender = "";
                        }}
                        >
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                        label="Date picker"
                        onChange={handleDateChange}
                        />
                    </DemoContainer>
                    </LocalizationProvider>
                </ThemeProvider>
                {/* <TextField
                    value={formik.values.interest}
                    name="interest"
                    id="interest"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                        sx: { bgcolor: "white" },
                    }}
                    type={"text"}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.errors.interest = "";
                    }}
                    error={formik.errors.interest? true: false}
                    helperText={formik.errors.interest}
                    sx={{
                    mt: 2,
                    mb: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                        color: "black",
                    },
                    }}
                    placeholder={"Interest"}
                />
                <TextField
                    value={formik.values.location}
                    name="location"
                    id="location"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                        sx: { bgcolor: "white" },
                    }}
                    type={"text"}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        formik.errors.location = "";
                    }}
                    error={formik.errors.location? true: false}
                    helperText={formik.errors.location}
                    sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                        color: "black",
                    },
                    }}
                    placeholder={"Location"}
                /> */}
                </Box>}
              </>
            </DialogContentText>
            </DialogContent>
            {menualAdd && <DialogActions sx={{ p: 3 }}>
            <LoadingButton
                // loading={isSkeleton}
                // onClick={hanldeAddContact}
                type="submit"
                loadingPosition="start"
                // startIcon={isSkeleton && <SaveIcon />}
                variant="contained"
            >
                Add
            </LoadingButton>
            </DialogActions>}
        </form>    
      </Dialog>
    </>
  )
}

export default ContactDetailPopUp