import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Box,
  styled,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";
import { APIRoutes } from "../../../utils/constants";
import { useParams } from "react-router-dom";

const StyleMenuItem = styled(MenuItem)({
  fontSize: "14px",
});

function Popup({
  title,
  buttonName,
  discription,
  data,
  type,
  submitForm,
}: {
  title: string;
  buttonName: string;
  discription: string;
  data?: any;
  type?: string;
  submitForm?: any;
}) {
  console.log(data);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const client = useAxiosPrivate(axios);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitClose = async () => {
    try {
      setIsLoading(true);
      if (type == "Campaign") {
        const res = await client.delete(
          APIRoutes.DeleteCampaign.replace(":id", `${data.Id}`)
        );
        submitForm(res.data);
        handleClose();
        setIsLoading(false);
      } else if (type == "InnerCampaign") {
        const res = await client.delete(
          APIRoutes.DeleteInnerCampaign.replace(":id", `${data.Id}`)
        );
        submitForm(res.data);
        handleClose();
        setIsLoading(false);
      } else if (type == "Contact") {
        const res = await client.delete(
          APIRoutes.DeleteContact.replace(":id", `${data.Id}`)
        );
        submitForm(res.data);
        handleClose();
        setIsLoading(false);
      } else if (type == "InnerContact") {
        const res = await client.delete(
          APIRoutes.DeleteContactList.replace(":id", `${id}/${data.Id}`)
        );
        submitForm(res.data);
        handleClose();
        setIsLoading(false);
      } else if (type == "TemplateDelete") {
        const res = await client.delete(
          APIRoutes.UserTemplateDelete.replace(":id", `${data.Id}`)
        );
        submitForm(res.data);
        handleClose();
        setIsLoading(false);
      }
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <StyleMenuItem onClick={handleClickOpen}>
        <Typography variant="body2">{buttonName}</Typography>
      </StyleMenuItem>
      <Dialog
        open={open}
        sx={{
          ".MuiPaper-root": {
            bgcolor: "common.blue",
          },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "22px",
          }}
          id="alert-dialog-title"
        >
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "small",
              color: "white",
            }}
          >
            <Typography variant="body2">{discription}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "15px",
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            <Typography variant="body2">Cancel</Typography>
          </Button>
          {/* <Button variant="contained" onClick={handleClose} autoFocus>
            <Typography variant="body2">{buttonName}</Typography>
          </Button> */}
          <LoadingButton
            onClick={submitClose}
            loading={isLoading}
            type="button"
            loadingPosition="start"
            startIcon={isLoading && <SaveIcon />}
            variant="contained"
          >
            {buttonName}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Popup;
