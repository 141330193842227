import { Typography, Button, Tab, Tabs, styled, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Style from "./MyCampaigns.style";
import EnhancedTable from "./TableComponent";
import { useNavigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import CreateCampaign from "../../Common/Components/Campaign/CreateCampaign";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const StyledTab = styled(Tab)({
  color: "#FFFFFF",
  textTransform: "none",
  padding: 0,

  minHeight: "40px",
});
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MyCapaigns: FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [openCampaignDialog, setOpenCampaignDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const client = useAxiosPrivate(axios);
  const [getCampaign, setGetCampaign] = useState<any>();

  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.ListCampaign);
      setGetCampaign(res.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    handleGet();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenCampaignDialog(false);
  };

  const submitClose = () => {
    setOpenCampaignDialog(false);
    handleGet();
  };

  const updateMainAPI = () => {
    handleGet();
  };

  return (
    <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Typography variant="h2">My campaigns</Typography>
        <Button
          variant="contained"
          onClick={() => {
            setOpenCampaignDialog(true);
            // navigate(PageRoutes.MyCampaignDetail.replace(":id", "123"));
          }}
        >
          Create a campaign
        </Button>
      </Box>
      <Box sx={{ py: { xs: 2, md: 5 } }}>
        {loading ? (
          <CircularProgress
            size={36}
            sx={{
              color: "#003876",
            }}
          />
        ) : (
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              {getCampaign && (
                <EnhancedTable
                  data={getCampaign}
                  updateMainAPI={updateMainAPI}
                />
              )}
            </TabPanel>
          </Box>
        )}
      </Box>
      {openCampaignDialog && (
        <CreateCampaign
          description={""}
          title={"Create Campaign"}
          open={openCampaignDialog}
          buttonName={"Save"}
          handleClose={handleClose}
          submitClose={submitClose}
        />
      )}
    </Box>
  );
};
export default MyCapaigns;
