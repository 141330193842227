import { Typography, Button, Tab, Tabs, styled, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Style from "./MyInnerCampaignList.style";
import EnhancedTable from "./TableComponent";
import { useNavigate, useParams } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const StyledTab = styled(Tab)({
  color: "#FFFFFF",
  textTransform: "none",
  padding: 0,

  minHeight: "40px",
});
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MyInnerCampaignList: FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const client = useAxiosPrivate(axios);
  const [getCampaign, setGetCampaign] = useState<any>();
  const [schedule, setSchedule] = useState<any>();

  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.LIstInnerCampaign.replace(":id", `${params.id}`),
        {
          params: {
            status: value == 0 ? '' : value == 1 ? 'Draft' : value == 2 ? 'Sent' : value == 3 ? "Scheduled" : 'Archived'
          }
        });
      setGetCampaign(res.data.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleGetScheduleById = async () => {
    try {
      const res = await client.get(APIRoutes.ScheduleById.replace(":scheduleId", `${params.id}`));
      setSchedule(res.data.data);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    handleGet();
    handleGetScheduleById();
  }, [value]);

  const updateMainAPI = () => {
    handleGet();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const retrySchedule = async () => {
    const name = schedule.Name;
    const isMinMailLimit = schedule.NumberOfEmails > 0 ? true : false;
    const dataBody: any = {
      name,
      isMinMailLimit,
      numberOfMail: schedule.NumberOfEmails > 0 ? schedule.NumberOfEmails : 0,
      startDate: schedule.StartDate,
      endDate: schedule.EndDate,
      scheduleId: schedule.Id
    };
    const res = await client.post(APIRoutes.CreateSchedule, dataBody);
    updateMainAPI();
  }

  return (
    <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Typography variant="h2">My campaigns</Typography>
        {/* <Button
          variant="contained"
          onClick={() => {
            navigate(PageRoutes.MyCampaignDetail.replace(":id", "123"));
          }}
        >
          Create a campaign
        </Button> */}
      </Box>
      <Box sx={{ py: { xs: 2, md: 5 } }}>
        {loading ? (
          <CircularProgress
            size={36}
            sx={{
              color: "#003876",
            }}
          />
        ) : (
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 0.5, borderColor: "#525252" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  ".MuiTabs-scroller": {
                    overflow: "auto !important",
                    "::-webkit-scrollbar": { display: "none" },
                  },
                }}
                aria-label="basic tabs example"
              >
                <StyledTab label="All" {...a11yProps(0)} />
                {/* <StyledTab label="Starred" {...a11yProps(1)} /> */}
                <StyledTab label="Draft" {...a11yProps(1)} />
                <StyledTab label="Sent" {...a11yProps(2)} />
                <StyledTab label="Scheduled" {...a11yProps(3)} />
                {/* <StyledTab label="A/B test" {...a11yProps(5)} /> */}
                {/* <StyledTab label="Archived" {...a11yProps(4)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={value}>
              {getCampaign && <EnhancedTable data={getCampaign} updateMainAPI={updateMainAPI} schedule={schedule} retrySchedule={retrySchedule} />}
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
            Starred
          </TabPanel>
          <TabPanel value={value} index={2}>
            Draft
          </TabPanel>
          <TabPanel value={value} index={3}>
            Sent
          </TabPanel>
          <TabPanel value={value} index={4}>
            Scheduled
          </TabPanel> */}
            {/* <TabPanel value={value} index={5}>
            A/B test
          </TabPanel>
          <TabPanel value={value} index={6}>
            Archived
          </TabPanel> */}
          </Box>
        )}

      </Box>
    </Box>
  );
};
export default MyInnerCampaignList;
