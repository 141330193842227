import {
    Typography,
    Button,
    Tab,
    Tabs,
    styled,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    ThemeProvider,
    createTheme,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import { FC, useState } from "react";
  import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
  import { useNavigate, useSearchParams } from "react-router-dom"; 
  import PageRoutes, { APIRoutes } from "../../utils/constants";
  import Style from "../MyCampaignDetail/MyCampaigns.style";
  import axios from "../../utils/axios";
  import useAxiosPrivate from "../../hooks/useAxiosPrivate";
  import { toast } from "react-toastify";
  import { useFormik } from "formik";
  import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import ContactDetailPopUp from "./ContactDetailPopUp";
  import { LoadingButton } from "@mui/lab";
  import SaveIcon from "@mui/icons-material/Save";


  const darkTheme = createTheme({
    palette:{
      mode: 'dark',
    }
  })
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const StyledTab = styled(Tab)({
    color: "#FFFFFF",
    textTransform: "none",
    padding: 0,
  
    minHeight: "40px",
  });
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Typography variant="body2">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
const ContactDetail: FC = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isReplyToAddress, setIsReplyToAddress] = useState(false);
  const client = useAxiosPrivate(axios);
  const [name,setName] = useState("");
  const [contactData,setContactData] = useState<any>();
  const [contactId,setContactId] = useState<any>();
  const [isSkeleton, setIsSkeleton] = useState(false);
  

  const handleChange =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(panel);
  };
    console.log(contactData);
    
  const handleClose = () => {
    setExpanded(false);
    formik.resetForm();
    setContactId(null);
    setContactData(null);
  };

  const handleContactDetail = async()=>{
    try {
      setIsSkeleton(true);
      const res = await client.post(APIRoutes.ContactDetailAdd,{
        "contactId": Number(contactId),
        "contacts" : contactData
      });
      setIsSkeleton(false);
      navigate(PageRoutes.ContactDetails.replace(":id", contactId));
      toast.success("Successfully Added");
      // setExpanded(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSkeleton(false);
      }
  }
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please fill the required fields";
    }
    // console.log(errors);

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name:"",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setIsSkeleton(true);
        const res = await client.post(APIRoutes.ContactAdd,{
          "name":values.name
        });
        toast.success("Successfully Added");     
        setContactId(res.data.data.Id);
        setIsSkeleton(false);
        setExpanded("panel2");
        // setExpanded(false);
        } catch (error: any) {
          toast.error(error?.response?.data?.message);
          setIsSkeleton(false);
        }
    }
  })
  return (
      <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Typography variant="h2">Create a new Contact List</Typography>
        {/* <Button
          variant="contained"
          onClick={() => {
              
            }}
        >
          Create Contact List
        </Button> */}
      </Box>
      <Box sx={{ py: { xs: 2, md: 5 } }}>
        <Box sx={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={Style.Accordion}
              disabled={!!expanded && expanded !== "panel1"}
            >
              <AccordionSummary
                sx={{
                  ...Style.AccordionSummary,
                  pb: expanded === "panel1" ? 0 : "22px",
                }}
                expandIcon={
                  expanded !== "panel1" && (
                    <Button size="small" variant="contained">
                      Add name
                    </Button>
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box
                  sx={{
                    width: "25%",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CheckCircleOutlineIcon sx={{color: name?"green":""}}/>
                  <Typography>Name</Typography>
                </Box>
                <Typography
                  sx={{ display: expanded !== "panel1" ? "block" : "none" }}
                >
                  {!formik.values.name?"Give your contact list a unique name to reference later":formik.values.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography>
                    Give your contact list a unique name to reference later
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Untitled contact"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    name="name"
                    id="name"
                    value={formik.values.name}
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.name = "";
                    }}
                    error={formik.errors.name? true: false}
                    helperText={formik.errors.name}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "50%",
                      my: 2,
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <LoadingButton loading={isSkeleton}  loadingPosition="start" startIcon={isSkeleton && <SaveIcon />} variant="contained" size="small" type="submit">
                      Save
                    </LoadingButton>
                    <Button size="small" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </form>  
          <Accordion
            expanded={expanded === "panel2"}
            // onChange={handleChange("panel2")}
            sx={Style.Accordion}
            // disabled={!!expanded && expanded !== "panel2"}
            disabled={expanded!=="panel2"}
          >
            <AccordionSummary
              sx={{
                ...Style.AccordionSummary,
                pb: expanded === "panel2" ? 0 : "22px",
              }}
              expandIcon={
                expanded !== "panel2"
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Box
                sx={{
                  width: "25%",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CheckCircleOutlineIcon />
                <Typography>Add Contact Detail</Typography>
              </Box>
              <Typography
                sx={{ display: expanded !== "panel2" ? "block" : "none" }}
              >
                Contact Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  <Typography>
                    Please add contact details
                  </Typography>
                    <ContactDetailPopUp contact={contactData} setContact={setContactData}/>
                </Box>
                <Box sx={{m:2}}>
                  {contactData && <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>First Name</TableCell>
                        <TableCell>Last Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Gender</TableCell>
                        <TableCell>Birthdate</TableCell>
                        {/* <TableCell>Interest</TableCell>
                        <TableCell>Location</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactData?.map((ele:any,idx:any)=>(
                        <TableRow>
                          <TableCell>{idx+1}</TableCell>
                          <TableCell>{ele.firstName}</TableCell>
                          <TableCell>{ele.lastName}</TableCell>
                          <TableCell>{ele.email}</TableCell>
                          <TableCell>{ele.gender}</TableCell>
                          <TableCell>{ele.birthday}</TableCell>
                          {/* <TableCell>{ele.interests}</TableCell>
                          <TableCell>{ele.location}</TableCell> */}
                        </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>}
                  {/* <Typography>
                    First Name 
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Enter First Name"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    value={firstName}
                    onChange={(e)=>{
                      setFirstName(e.target.value) 
                    }}
                    error = {!firstName?true:false}
                    helperText = {!firstName?"Please Entry a first name.":""}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "60%",
                      mt: 1,
                      mb: 2,
                    }}
                  />
                  <Typography>
                    Last Name 
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Enter Last Name"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    value={lastName}
                    onChange={(e)=>{
                      setLastName(e.target.value)
                    }}
                    error = {!lastName?true:false}
                    helperText = {!lastName?"Please entry a last name.":""}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "60%",
                      mt: 1,
                      mb: 2,
                    }}
                  />
                  <Typography>
                    Email 
                  </Typography>
                  <TextField
                    type="email"
                    size="small"
                    placeholder="Enter email"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    value={email}
                    onChange={(e)=>{
                      setEmail(e.target.value)
                    }}
                    error = {!email?true:false}
                    helperText = {!email?"Please Entry an email.":""}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "60%",
                      mt: 1,
                      mb: 2,
                    }}
                  />
                  <Box sx={{ mb:1}}>
                    <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={gender}
                        onChange={(e)=>{
                          setGender(e.target.value)
                        }}
                      >
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="other" control={<Radio />} label="Other" />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{mb:2}}>
                    <Typography sx={{mb:1}}>
                      Birth Date 
                    </Typography>
                    <ThemeProvider theme={darkTheme}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            label="Date picker"
                            value={dob}
                            onChange={handleDateChange}
                            />
                        </DemoContainer>
                      </LocalizationProvider>
                    </ThemeProvider>
                  </Box>
                  <Typography>
                    Interests 
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Enter Interest"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    value={interest}
                    onChange={(e)=>{
                      setInterest(e.target.value)
                    }}
                    error = {!interest?true:false}
                    helperText = {!interest?"Please Entry a interest.":""}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "60%",
                      mt: 1,
                      mb: 2,
                    }}
                  />
                  <Typography>
                    Location 
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Enter Location"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    value={location}
                    onChange={(e)=>{
                      setLocation(e.target.value)
                    }}
                    error = {!location?true:false}
                    helperText = {!location?"Please Entry a name.":""}
                    sx={{
                      input: { color: 'black' },
                      minWidth: "60%",
                      mt: 1,
                      mb: 2,
                    }}
                  />*/}
                </Box> 
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <LoadingButton loading={isSkeleton} loadingPosition="start" startIcon={isSkeleton && <SaveIcon />} variant="contained" size="small" onClick={handleContactDetail}
                  disabled={!contactData}
                  >
                    Submit
                  </LoadingButton>
                  <Button size="small" onClick={handleClose} >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={Style.Accordion}
            disabled={!!expanded && expanded !== "panel3"}
          >
            <AccordionSummary
              sx={{
                ...Style.AccordionSummary,
                pb: expanded === "panel3" ? 0 : "22px",
              }}
              expandIcon={
                expanded !== "panel3" && (
                  <Button size="small" variant="contained">
                    Add sender
                  </Button>
                )
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Box
                sx={{
                  width: "25%",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CheckCircleOutlineIcon />
                <Typography>From</Typography>
              </Box>
              <Typography
                sx={{ display: expanded !== "panel3" ? "block" : "none" }}
              >
                Who is sending the campaign?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: 1,
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: 1, md: 0.49 },
                    }}
                  >
                    <Typography>FirstName</Typography>
                    <TextField
                      size="small"
                      placeholder="FirstName"
                      InputProps={{
                        sx: { bgcolor: "#F6F6F6" },
                      }}
                      error
                      helperText="Please add a sender name before saving."
                      sx={{
                        minWidth: "100%",
                        my: 2,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: { xs: 1, md: 0.49 },
                    }}
                  >
                    <Typography>Email address</Typography>
                    <TextField
                      size="small"
                      placeholder="FirstName"
                      InputProps={{
                        sx: { bgcolor: "#F6F6F6" },
                      }}
                      error
                      helperText="Please add a sender name before saving."
                      sx={{
                        minWidth: "100%",
                        my: 2,
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      width: "max-content",
                    }}
                    onClick={() => {
                      setIsReplyToAddress(!isReplyToAddress);
                    }}
                  >
                    Reply-to address
                  </Typography>
                  {isReplyToAddress && (
                    <Box>
                      <TextField
                        size="small"
                        InputProps={{
                          sx: { bgcolor: "#F6F6F6" },
                        }}
                        sx={{
                          minWidth: "100%",
                          my: 2,
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    mt: 1,
                  }}
                >
                  <Button variant="contained" size="small">
                    Save
                  </Button>
                  <Button size="small" onClick={() => handleClose()}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            // expanded={expanded === "panel4"}
            // onChange={handleChange("panel4")}
            sx={Style.Accordion}
            disabled={!!expanded && expanded !== "panel4"}
          >
            <AccordionSummary
              sx={{
                ...Style.AccordionSummary,
                pb: expanded === "panel4" ? 0 : "22px",
              }}
              expandIcon={
                <Button size="small" variant="contained">
                  Design email
                </Button>
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Box
                sx={{
                  width: "25%",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CheckCircleOutlineIcon />
                <Typography>Content</Typography>
              </Box>
              <Typography
                sx={{ display: expanded !== "panel4" ? "block" : "none" }}
              >
                What is the campaign content?
              </Typography>
            </AccordionSummary>
          </Accordion> */}
        </Box>
      </Box>
    </Box>
    )
}

export default ContactDetail