import { Box } from "@mui/system";
import { FC, useEffect, useState } from "react";
import Style from "./Sidebar.style";
import Logo from "../../../assets/img/AiryisLogo.svg";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  MenuList,
  Divider,
  TextField,
  InputAdornment,
  Typography,
  Badge,
  Avatar,
  useMediaQuery,
  IconButton,
  Drawer,
} from "@mui/material";
import React from "react";
import Profile from "../../../assets/img/1087784.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import PageRoutes from "../../../utils/constants";

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import {
  ExpandLess,
  ExpandMore,
  Search,
  ContactPhoneOutlined,
  DashboardOutlined,
  DocumentScannerOutlined,
  NotificationsOutlined,
  SettingsOutlined,
  Email,
  SettingsPowerSharp,
  CalendarMonth,
  Category,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import AutomationIcon from "../../../assets/icons/AutomationIcon";
import WebsiteIcon from "../../../assets/icons/Websiteicon";
import styled from "@emotion/styled";
import theme from "../../style/core/config/theme";
import useCommonDetails from "../../Hooks/useCommonDetails";
const Sidebar: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState<any>({
    contacts: false,
    automation: false,
    website: false,
    reports: false,
    setting: false,
  });
  const userDetails = useCommonDetails();
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const routeChange = (path: string) => {
    setSelectedItem(path);
    navigate(path);
    handleDrawerClose();
  };

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const StyledBadge = styled(Badge)(({ theme: any }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const menuClick = (name: any) => {
    const item = menuOpen;
    item[name] = !item[name];

    setMenuOpen({ ...menuOpen, ...item });
  };
  return (
    <>
      {isSmallScreen && (
        <Box
          id="sidebar"
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: 100,
            bgcolor: "transparent",
            padding: "5px 15px",
          }}
        >
          <IconButton
            color="inherit"
            onClick={() => {
              setDrawerOpen(true);
            }}
            sx={{ p: "12px", bgcolor: "#102432" }}
          >
            <MenuIcon />
          </IconButton>
          {/* You can add other AppBar contents here */}
        </Box>
      )}
      <Box id="sidebar" sx={Style.SidebarMain}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 100,
            bgcolor: "#072B41",
            cursor: "pointer",
          }}
          onClick={() => routeChange(PageRoutes.DashBoard)}
        >
          <img src={Logo} width="100%" />
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.DashBoard)}
              selected={selectedItem === PageRoutes.DashBoard}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <DashboardOutlined sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            {/* <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.Onboard)}
              selected={selectedItem === PageRoutes.Onboard}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <SettingsPowerSharp sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Onboarding</ListItemText>
            </MenuItem> */}
            {/* <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.EmailEditor)}
              selected={selectedItem === PageRoutes.EmailEditor}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <Email sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Email Editor</ListItemText>
            </MenuItem> */}
            <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.MyCampaigns)}
              selected={selectedItem === PageRoutes.MyCampaigns}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <AutomationIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>My Campaigns</ListItemText>
            </MenuItem>
            <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.Calender)}
              selected={selectedItem === PageRoutes.Calender}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <CalendarMonth sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Calender</ListItemText>
            </MenuItem>
            <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.ShopifyProducts)}
              selected={selectedItem === PageRoutes.ShopifyProducts}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <ShoppingCartIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Shopify Products</ListItemText>
            </MenuItem>

            <MenuItem
              sx={Style.sidebarMenuButton}
              onClick={() => routeChange(PageRoutes.IndustryCatogary)}
              selected={selectedItem === PageRoutes.IndustryCatogary}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <Category sx={{ color: "white" }} />
              </ListItemIcon> 
              <ListItemText>Email Category</ListItemText>
            </MenuItem>
            
            <MenuItem
              onClick={() => menuClick("contacts")}
              sx={Style.sidebarMenuButton}
            >
              <ListItemIcon sx={Style.sidebarMenuIcon}>
                <ContactPhoneOutlined sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText>Contacts</ListItemText>
              {menuOpen.contacts ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={menuOpen.contacts} timeout="auto" unmountOnExit>
              <MenuList disablePadding sx={Style.Submenu}>
                <MenuItem
                  onClick={() => routeChange(PageRoutes.Contact)}
                  selected={selectedItem === PageRoutes.Contact}
                  sx={Style.sidebarMenuButton}
                >
                  <ListItemText>My Contacts</ListItemText>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => routeChange(PageRoutes.EmailCatogary)}
                  selected={selectedItem === PageRoutes.EmailCatogary}
                  sx={Style.sidebarMenuButton}
                >
                  <ListItemText>Email</ListItemText>
                </MenuItem> */}
                {/* <MenuItem
                  onClick={() => routeChange(PageRoutes.IndustryCatogary)}
                  selected={selectedItem === PageRoutes.IndustryCatogary}
                  sx={Style.sidebarMenuButton}
                >
                  <ListItemText>Email</ListItemText>
                </MenuItem> */}

                {/* <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Fields</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Expoerts</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Nearby Contacts</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Tag Trends</ListItemText>
              </MenuItem>  */}
              </MenuList>
            </Collapse>
            {/* <MenuItem
            onClick={() => menuClick("automation")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <AutomationIcon sx={{ color: "transparent" }} />
            </ListItemIcon>
            <ListItemText>Automation</ListItemText>
            {menuOpen.automation ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.automation} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.MyAccount)}
                //   selected={selectedItem === PageRouteConstants.MyAccount}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Automation Messages</ListItemText>
              </MenuItem>

              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Transaction Email</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem>
            </MenuList>
          </Collapse> */}
            {/* <MenuItem
            onClick={() => menuClick("website")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <WebsiteIcon
                sx={{
                  color: "transparent",
                }}
              />
            </ListItemIcon>
            <ListItemText>Campaigns</ListItemText>
            {menuOpen.website ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.website} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                onClick={() => routeChange(PageRoutes.MyCampaigns)}
                selected={selectedItem === PageRoutes.MyCampaigns}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>My Campaigns</ListItemText>
              </MenuItem>

               <MenuItem
                // onClick={() => routeChange(PageRoutes.ManageUsers)}
                // selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Compare compaigns</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem> 
            </MenuList>
          </Collapse>*/}
          </Box>
          {/* <Box>
          <Typography variant="caption" sx={{ px: 3 }}>
            Settings
          </Typography>
          <MenuItem
            sx={Style.sidebarMenuButton}
            // onClick={() => routeChange(PageRoutes.DashBoard)}
            // selected={selectedItem === PageRoutes.DashBoard}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <NotificationsOutlined sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText>Notification</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => menuClick("setting")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <SettingsOutlined
                sx={{
                  color: "white",
                }}
              />
            </ListItemIcon>
            <ListItemText>Setting</ListItemText>
            {menuOpen.setting ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.setting} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.MyAccount)}
                //   selected={selectedItem === PageRouteConstants.MyAccount}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Automation Messages</ListItemText>
              </MenuItem>

              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Transaction Email</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem>
            </MenuList>
          </Collapse>
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, py: 2 }}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt="Remy Sharp" src={Profile} />
            </StyledBadge>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                {userDetails?.FirstName} {userDetails.FirstName}{" "}
                {userDetails.LastName}
              </Typography>
              <Typography variant="caption">Campaign name</Typography>
            </Box>
          </Box>
        </Box> */}
        </Box>
      </Box>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
      >
        <Box sx={{ ...Style.SidebarMain, display: "flex" }}>
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 100,
              bgcolor: "#072B41",
            }}
          >
            <img src={Logo} width="100%" />
          </Box>
          <Box
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box>
              <MenuItem
                sx={Style.sidebarMenuButton}
                onClick={() => routeChange(PageRoutes.DashBoard)}
                selected={selectedItem === PageRoutes.DashBoard}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <DashboardOutlined sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </MenuItem>
              <MenuItem
                sx={Style.sidebarMenuButton}
                onClick={() => routeChange(PageRoutes.Onboard)}
                selected={selectedItem === PageRoutes.Onboard}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <SettingsPowerSharp sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>Onboarding</ListItemText>
              </MenuItem>
              {/* <MenuItem
                sx={Style.sidebarMenuButton}
                onClick={() => routeChange(PageRoutes.EmailEditor)}
                selected={selectedItem === PageRoutes.EmailEditor}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <Email sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>Email Editor</ListItemText>
              </MenuItem> */}
              <MenuItem
                sx={Style.sidebarMenuButton}
                onClick={() => routeChange(PageRoutes.MyCampaigns)}
                selected={selectedItem === PageRoutes.MyCampaigns}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <AutomationIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>My Campaigns</ListItemText>
              </MenuItem>
              <MenuItem
                sx={Style.sidebarMenuButton}
                onClick={() => routeChange(PageRoutes.Calender)}
                selected={selectedItem === PageRoutes.Calender}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <CalendarMonth sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>Calender</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => menuClick("contacts")}
                sx={Style.sidebarMenuButton}
              >
                <ListItemIcon sx={Style.sidebarMenuIcon}>
                  <ContactPhoneOutlined sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText>Contacts</ListItemText>
                {menuOpen.contacts ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse in={menuOpen.contacts} timeout="auto" unmountOnExit>
                <MenuList disablePadding sx={Style.Submenu}>
                  <MenuItem
                    onClick={() => routeChange(PageRoutes.Contact)}
                    selected={selectedItem === PageRoutes.Contact}
                    sx={Style.sidebarMenuButton}
                  >
                    <ListItemText>My Contacts</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => routeChange(PageRoutes.EmailCatogary)}
                    selected={selectedItem === PageRoutes.EmailCatogary}
                    sx={Style.sidebarMenuButton}
                  >
                    <ListItemText>Email</ListItemText>
                  </MenuItem>

                  {/* <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Fields</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Expoerts</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Nearby Contacts</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Tag Trends</ListItemText>
              </MenuItem>  */}
                </MenuList>
              </Collapse>
              {/* <MenuItem
            onClick={() => menuClick("automation")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <AutomationIcon sx={{ color: "transparent" }} />
            </ListItemIcon>
            <ListItemText>Automation</ListItemText>
            {menuOpen.automation ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.automation} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.MyAccount)}
                //   selected={selectedItem === PageRouteConstants.MyAccount}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Automation Messages</ListItemText>
              </MenuItem>

              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Transaction Email</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem>
            </MenuList>
          </Collapse> */}
              {/* <MenuItem
            onClick={() => menuClick("website")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <WebsiteIcon
                sx={{
                  color: "transparent",
                }}
              />
            </ListItemIcon>
            <ListItemText>Campaigns</ListItemText>
            {menuOpen.website ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.website} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                onClick={() => routeChange(PageRoutes.MyCampaigns)}
                selected={selectedItem === PageRoutes.MyCampaigns}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>My Campaigns</ListItemText>
              </MenuItem>

               <MenuItem
                // onClick={() => routeChange(PageRoutes.ManageUsers)}
                // selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Compare compaigns</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem> 
            </MenuList>
          </Collapse>*/}
            </Box>
            {/* <Box>
          <Typography variant="caption" sx={{ px: 3 }}>
            Settings
          </Typography>
          <MenuItem
            sx={Style.sidebarMenuButton}
            // onClick={() => routeChange(PageRoutes.DashBoard)}
            // selected={selectedItem === PageRoutes.DashBoard}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <NotificationsOutlined sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText>Notification</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => menuClick("setting")}
            sx={Style.sidebarMenuButton}
          >
            <ListItemIcon sx={Style.sidebarMenuIcon}>
              <SettingsOutlined
                sx={{
                  color: "white",
                }}
              />
            </ListItemIcon>
            <ListItemText>Setting</ListItemText>
            {menuOpen.setting ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen.setting} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={Style.Submenu}>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.MyAccount)}
                //   selected={selectedItem === PageRouteConstants.MyAccount}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Automation Messages</ListItemText>
              </MenuItem>

              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                //   selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Transaction Email</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>All Automations</ListItemText>
              </MenuItem>
              <MenuItem
                //   onClick={() => routeChange(PageRouteConstants.Applications)}
                //   selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.sidebarMenuButton}
              >
                <ListItemText>Goal Overview</ListItemText>
              </MenuItem>
            </MenuList>
          </Collapse>
          <Divider />
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, py: 2 }}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar alt="Remy Sharp" src={Profile} />
            </StyledBadge>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                {userDetails?.FirstName} {userDetails.FirstName}{" "}
                {userDetails.LastName}
              </Typography>
              <Typography variant="caption">Campaign name</Typography>
            </Box>
          </Box>
        </Box> */}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default Sidebar;
