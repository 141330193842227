import {
  Typography,
  Button,
  MenuItem,
  Menu,
  Fade,
  Select,
  SelectChangeEvent,
  FormControl,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { FC, useState, SyntheticEvent, useEffect } from "react";
import Style from "./DashboardPage.style";
import "./dashboard-style.css";
import Graphs from "./dashboardComponent/Graphs";
import Cards from "./dashboardComponent/Cards";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CampaignIcon from "@mui/icons-material/Campaign";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import ColumnCharts from "./dashboardComponent/ColumnChart";
import Header from "./dashboardComponent/ColumnChart/Header";
import PieChart from "./dashboardComponent/PieChart";
import { wrap } from "module";
import ProgressCircle from "./dashboardComponent/ProgressBar";
import LineChart from "./dashboardComponent/LineChart";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { APIRoutes } from "../../utils/constants";

const Dashboard: FC = () => {
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [graphTime, setGraphTime] = React.useState<string | undefined>(
    "Last 30 min"
  );
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );
  const userDetails = useCommonDetails();
  const client = useAxiosPrivate(axios);
  const [dashboarddata, setDashboardData] = useState<any>(null);
  const [dashboardGraphData, setDashboardGraphData] = useState<[]>([]);
  const [dashboardGraphDataMenu, setDashboardGraphDataMenu] = useState<[]>([]);
  const handleMenuOpen1 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };
  const handleMenuOpen2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };
  const handleSelectorChange = (event: SelectChangeEvent) => {
    setGraphTime(event.target.value);
  };

  useEffect(() => {
    handleGet();
    handleGetGraph();
  }, []);

  const handleGet = async () => {
    try {
      // setLoading(true);
      const res = await client.get(APIRoutes.DashboardCount);
      setDashboardData(res.data.data);
      // setLoading(false);
    } catch (error: any) {
      // setLoading(false);
      // toast.error(error?.response?.data?.message);
    }
  };

  const handleGetGraph = async () => {
    try {
      // setLoading(true);
      const res = await client.get(APIRoutes.DashboardGraph);
      console.log(res.data, "res.data");
      let data: any = [];
      let keys: any = [];
      Object.entries(res.data.data).forEach((item) => {
        console.log(item);
        let key = item[0];
        let array: any = item[1];
        let obj = {
          month: key,
        };
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          obj[element.Name] = element.Count;
          keys.push(element.Name);
        }
        data.push(obj);
      });
      console.log(data, "datadata");
      setDashboardGraphData(data);
      setDashboardGraphDataMenu(keys);
      // setLoading(false);
    } catch (error: any) {
      // setLoading(false);
      // toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h1" sx={{ fontFamily: "Quicksand", mr: "8px" }}>
            {userDetails.FirstName + " " + userDetails.LastName}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "#70d8bd" }}>
            Welcome back
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Button
            variant="outlined"
            size={isSmallScreen ? "small" : "medium"}
            aria-controls={Boolean(anchorEl1) ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl1) ? "true" : undefined}
            onClick={handleMenuOpen1}
          >
            Quick Setup <ArrowDropDownIcon />
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl1}
            open={Boolean(anchorEl1)}
            onClose={handleMenuClose1}
            TransitionComponent={Fade}
          >
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose1}>
              SMTP Setup
            </MenuItem>
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose1}>
              Manage Sender Address
            </MenuItem>
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose1}>
              Domain Authentication
            </MenuItem>
          </Menu>
          <Button
            variant="contained"
            size={isSmallScreen ? "small" : "medium"}
            aria-controls={Boolean(anchorEl2) ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={Boolean(anchorEl2) ? "true" : undefined}
            onClick={handleMenuOpen2}
          >
            Create New
            <ArrowDropDownIcon />
          </Button>
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={handleMenuClose2}
            TransitionComponent={Fade}
          >
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose2}>
              Form
            </MenuItem>
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose2}>
              Contact List
            </MenuItem>
            <MenuItem sx={Style.optionsColor} onClick={handleMenuClose2}>
              Transaction Template
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          marginTop: "32px",
        }}
      >
        {/* <Box sx={{ display: "flex", flexGrow: 1, gap: 2, flexWrap: "wrap" }}>
          <Cards
            Icons={AttachEmailIcon}
            text="Emails Sent"
            values={dashboarddata?.emailSent ? dashboarddata?.emailSent : 0}
          />
          <Cards
            Icons={PermContactCalendarIcon}
            text="Contact List"
            values={
              dashboarddata?.contactCount ? dashboarddata?.contactCount : 0
            }
          />
        </Box> */}
        <Box sx={{ display: "flex", flexGrow: 1, gap: 2, flexWrap: "wrap" }}>
          <Cards
            Icons={PermContactCalendarIcon}
            text="Contact List"
            values={
              dashboarddata?.contactCount ? dashboarddata?.contactCount : 0
            }
          />
          <Cards
            Icons={CampaignIcon}
            text="Campaigns"
            values={
              dashboarddata?.campaignCount ? dashboarddata?.campaignCount : 0
            }
          />
          <Cards
            Icons={AdsClickIcon}
            text="Clicks"
            values={dashboarddata?.clickCount ? dashboarddata?.clickCount : 0}
          />
        </Box>
      </Box>

      <Grid container spacing={2} mt={0} pt={0}>
        <Grid item xs={12} lg={8}>
          <Box
            bgcolor="#102432"
            sx={{ borderRadius: "10px", height: "100%", width: "100%" }}
          >
            <Header title="Monthly Sales" subtitle="" />
            <Box height="300px" mt="-20px">
              <ColumnCharts
                dashboardGraphData={dashboardGraphData}
                dashboardGraphDataMenu={dashboardGraphDataMenu}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} pt={0}>
          <Box
            bgcolor="#102432"
            sx={{ borderRadius: "10px", height: "100%", width: "100%" }}
          >
            <Header title="Sales by Category" subtitle="" />
            <Box height="300px" mt="-20px">
              <PieChart />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} lg={3.5}>
          <Box
            bgcolor="#102432"
            sx={{ borderRadius: "10px", height: "100%", width: "100%" }}
          >
            <Header title="Total Sales" subtitle="" />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="15px"
              height="200px"
            >
              <ProgressCircle progress="0.75" size="130" />
              <Typography
                variant="h6"
                color="#70d8bd"
                fontFamily="QuickSand"
                textAlign="center"
                sx={{ mt: "15px" }}
              >
                $0 revenue generated
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8.5}>
          <Box
            bgcolor="#102432"
            sx={{ borderRadius: "10px", height: "100%", width: "100%" }}
          >
            <Header title="Revenue Generated" subtitle="$0.00" />
            <Box height="203px" mt="-20px">
              <LineChart />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Dashboard;
