import { SxProps } from "@mui/material";

const Style: { [key: string]: SxProps } = {
  SidebarStyle: {
    width: "278px",
    minHeight: "100vh",
  },
  Title: {
    fontSize: "14px",
    fontWeight: 600,
  },
  Submenu: { width: 1, pl: 4.5 },
  SidebarMain: {
    position: "fixed",
    left: 0,
    top: 0,
    bgcolor: "common.blue",

    overflow: "auto",
    zIndex: 2,
    bottom: 0,

    width: 280,
    minHeight: "calc( 100vh - 83.1px)",
    display: { xs: "none", md: "flex" },
    // display:"flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": { display: "none" },

    //   -webkit-scrollbar {
    //     display: none;
    // }
  },
  SidebarHead: {
    width: 1,
    mb: 3,
  },
  SidebarBottom: {
    px: 1,
    py: 4,
    marginBottom: 5,
    marginTop: "auto",
    width: 1,
  },
  CompanyDropDown: {
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
    border: "0.8px solid",
    bgcolor: "common.white",
    borderColor: "common.borderColor",
    mb: 1,
  },
  CompanyButton: {
    color: "text.primary",
    textTransform: "capitalize",
    width: "calc(100% - 36px)",
    justifyContent: "space-between",
  },
  CompanyDropDownMenuItem: {
    minWidth: 248,
    display: "flex",
    alignItems: "center",
  },
  OnBoardingButton: {
    width: 1,
    py: 1,
    px: 2,
    justifyContent: "flex-start",
    mb: 2,
  },
  sidebarMenuWrapper: {
    p: 0,
    width: "100%",
    maxWidth: 360,
    color: "common.white",
  },
  sidebarMenuIcon: {
    // minWidth: 36,
    color: "common.white",
  },
  sidebarMenuButton: {
    mt: 1,
    p: 1,
    borderRadius: 1,
    color: "white",
    transition: "all 0.3s",
    "&:hover": {
      bgcolor: "#003a70",
    },
    "&.Mui-selected": {
      bgcolor: "#003a70",
      "&:hover": {
        bgcolor: "#003a70",
      },
    },
    "& span": {
      fontSize: "14px !important",
    },
  },
};
export default Style;
