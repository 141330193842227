const Graph = () => {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.12047 0.908203H14.8805V21.0682H9.12047V0.908203ZM0.480469 12.4282H6.24047V21.0682H0.480469V12.4282ZM23.5205 7.62818H17.7605V21.0682H23.5205V7.62818Z"
          fill="white"
        />
      </svg>
    );

}

export default Graph