import React from "react";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import CommonStyle from "../../Common/style/Style";
import BusinessTeam from "../../assets/img/business-team.png";
import SpikeBottom from "../../assets/img/SpikeBottom.svg";
import WaitListComponent from "../../Common/Components/WaitListComponent";

const MachineLearning = () => {
  return (
    <Box>
      <Box>
        <Box
          sx={{
            position: "relative",
            // marginBottom: "7%",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "start",
              px: { xs: 2, lg: 9, md: 5 },
              justifyContent: "space-between",

              gap: 3,
            }}
          >
            <Box
              sx={{
                pt: 6,
                width: { sm: 1, md: 0.48 },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "white", fontWeight: "bold", mb: 3 }}
              >
                MACHINE LEARNING
              </Typography>
              <Typography variant="h1" sx={CommonStyle.Heading}>
                Your assistant is waiting to
                <Box
                  component={"br"}
                  sx={{ display: { xs: "none", md: "block" } }}
                ></Box>
                help. Airyis can 10x your time.
              </Typography>
              <Typography variant="subtitle2" sx={{ my: 5 }}>
                Every email, phone call, and sale is data. But if you can’t use
                that data to make better decisions, it goes to waste. Machine
                learning crunches the numbers so that you can make better
                decisions in your business.
              </Typography>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    lg: "block",
                  },
                }}
              >
                <img
                  src={SpikeBottom}
                  alt="SpikeBottom"
                  style={{ width: "100%" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                },
                width: { sm: 1, md: 0.48 },
              }}
            >
              <img src={BusinessTeam} style={{ width: "100%" }} alt="shape" />
            </Box>
          </Container>

          <Container
            maxWidth="xl"
            sx={{
              textAlign: "center",
              py: { xs: 5, md: 0 },
              bottom: "-10%",
              width: 1,
              position: "relative",
            }}
          >
            <Box
              className="overlay"
              sx={{
                background: "#021927",
                height: "110px",
                top: "-50%",
                display: {
                  xs: "none",
                  lg: "block",
                },
              }}
            />
            <Box
              sx={{
                py: 6,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Box sx={{ textAlign: { xs: "center", lg: "start" } }}>
                <Typography variant="h1" sx={{}}>
                  Be the first to use my services.
                </Typography>
                <Typography variant="subtitle2" sx={{}}>
                  Ditch the greed. Hire me.
                </Typography>
              </Box>
              <WaitListComponent />
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default MachineLearning;
