const BarGraph = () => {
    return (
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0805 0.947266H0.480469V3.82727H10.0805V0.947266ZM23.5204 6.70727H0.480469V9.58725H23.5204V6.70727ZM0.480469 12.4672H23.5204V15.3472H0.480469V12.4672ZM16.8004 18.2272H0.480469V21.1072H16.8004V18.2272Z"
          fill="white"
        />
      </svg>
    );

}

export default BarGraph