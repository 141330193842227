const Branch = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.24047 0.987343C6.24047 0.457151 6.67028 0.0273438 7.20047 0.0273438H16.8005C17.3307 0.0273438 17.7605 0.457151 17.7605 0.987343V2.90734C17.7605 3.43754 17.3307 3.86734 16.8005 3.86734H12.9605V6.74734H16.8005C17.3307 6.74734 17.7605 7.17716 17.7605 7.70734V9.62732C17.7605 10.1575 17.3307 10.5873 16.8005 10.5873H12.9605V13.4673H16.8005C18.3911 13.4673 19.6805 14.7568 19.6805 16.3473V19.2273H22.5605C23.0907 19.2273 23.5205 19.6571 23.5205 20.1873V22.1073C23.5205 22.6375 23.0907 23.0673 22.5605 23.0673H14.8805C14.3502 23.0673 13.9205 22.6375 13.9205 22.1073V20.1873C13.9205 19.6571 14.3502 19.2273 14.8805 19.2273H17.7605V16.3473C17.7605 15.8171 17.3307 15.3873 16.8005 15.3873H7.20047C6.67028 15.3873 6.24047 15.8171 6.24047 16.3473V19.2273H9.12047C9.65066 19.2273 10.0805 19.6571 10.0805 20.1873V22.1073C10.0805 22.6375 9.65066 23.0673 9.12047 23.0673H1.44047C0.910275 23.0673 0.480469 22.6375 0.480469 22.1073V20.1873C0.480469 19.6571 0.910275 19.2273 1.44047 19.2273H4.32047V16.3473C4.32047 14.7568 5.60989 13.4673 7.20047 13.4673H11.0405V10.5873H7.20047C6.67028 10.5873 6.24047 10.1575 6.24047 9.62732V7.70734C6.24047 7.17716 6.67028 6.74734 7.20047 6.74734H11.0405V3.86734H7.20047C6.67028 3.86734 6.24047 3.43754 6.24047 2.90734V0.987343Z"
          fill="white"
        />
      </svg>
    );

}
export default Branch