const FooterBlock = {
  backgroundSize: "100% 100%",
  backgroundColor: "#031827",
  color: "#FFFFFF",

  pt: 9,
};

const PaddingBlock = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

const FooterBody = {
  // backgroundColor: "#000F26",

  // backgroundColor: "#F8F8F8",
  // borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  // padding: "20px",
  // position: "fixed",
  // left: "0",
  // bottom: "0",
  // height: "60px",
  width: "100%",
};

const StyleDivider = {
  backgroundColor: "common.white",
};

export { FooterBlock, PaddingBlock, FooterBody, StyleDivider };
