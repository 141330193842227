import Logo from "../../../assets/img/AiryisLogo.svg";
import Search from "../../../assets/icons/search.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Collapse,
  Container,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  MenuItem,
  SwipeableDrawer,
  TextField,
  Menu,
  Typography,
} from "@mui/material";
import Profile from "../../../assets/img/1087784.jpg";
import { Box } from "@mui/system";
import { Close, CloseOutlined, PhotoCamera } from "@mui/icons-material";
import { getCurrentAuth, Logout } from "../../../hooks/useAxiosPrivate";
import PageRoutes from "../../../utils/constants";
import styled from "@emotion/styled";
import theme from "../../style/core/config/theme";
import Style from "./HeaderPrivateRoute.style";
import { toast } from "react-toastify";
import useCommonDetails from "../../Hooks/useCommonDetails";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import commonDetailSlice from "../../../store/reducers/commonReducer";
// import axios from "../../utils/axios";

const HeaderPublicRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openColleps, setOpeColleps] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userDetails = useCommonDetails();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    Logout();
    navigate(PageRoutes.Login);
    toast.success("Logout successfully");
    dispatch(commonDetailSlice.actions.removecommonDetails());
  };

  const StyledBadge = styled(Badge)(({ theme: any }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,

        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#031827",
        color: "#FFFFFF",
        zIndex: 10,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Collapse sx={{ display: "none" }} in={openColleps}>
            <Alert
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpeColleps(false);
                  }}
                >
                  {" "}
                  <CloseOutlined sx={{ color: "white" }} fontSize="inherit" />
                </IconButton>
              }
              sx={{
                bgcolor: "common.blue",
                ".MuiAlert-action": { m: 0 },
                "&.MuiPaper-root": { justifyContent: "center" },
              }}
            >
              <Box
                sx={{
                  background: "common.blue",
                  textAlign: "center",
                }}
              >
                <Typography variant="body2">
                  <strong>ANNOUNCEMENT:</strong> The waitlist is currently open!
                  Sign up to become an early tester and aid in ensuring the
                  effectiveness and utility of my services for human users.
                </Typography>
              </Box>
            </Alert>
          </Collapse>

          <Box>
            <Button
              id="menu-button"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                py: 2,
                textAlign: "start",
              }}
              aria-controls={open ? "header-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                {userDetails?.ProfilePicURL?<Avatar alt="Remy Sharp" src={userDetails?.ProfilePicURL} />:<PhotoCamera sx={{ color: "grey" }} />}
              </StyledBadge>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 500, whiteSpace: "nowrap" }}
                >
                  {userDetails.FirstName} {userDetails.LastName}
                </Typography>
                {/* <Typography sx={{ whiteSpace: "nowrap" }} variant="caption">
                  Campaign name
                </Typography> */}
              </Box>
            </Button>
            <Menu
              id="header-menu"
              aria-labelledby="menu-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: Style.MenuDropDown,
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  py: 2,
                  textAlign: "start",
                }}
                onClick={()=>{navigate(PageRoutes.DashBoard)}}
              >
                {userDetails?.ProfilePicURL?<Avatar alt="Remy Sharp" src={userDetails?.ProfilePicURL} />:<PhotoCamera sx={{ color: "grey" }} />}

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="caption" sx={{ fontWeight: 500 }}>
                    {userDetails.FirstName} {userDetails.LastName}
                  </Typography>
                  {/* <Typography variant="caption">Campaign name</Typography> */}
                </Box>
              </MenuItem>
            
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate(PageRoutes.Profile);
                  }}
                >
                  Your Profile
                </MenuItem>
            
            
                {/* <MenuItem onClick={handleClose}>Settings</MenuItem> */}
          
              <MenuItem onClick={handleLogout} sx={{ color: "error.main" }}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeaderPublicRoute;
