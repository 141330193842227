import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import PageRoutes from "../utils/constants";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../store/reducers/commonReducer";
export type authDetailsType = {
  access: any;
  refresh: any;
  id: string | number;
  user_sid: string;
  fullName: string;

  profilePicURL: string;
  email: string;
};

export const getCurrentAuth = () => {
  const localAuth: any = localStorage.getItem("auth");
  const auth: authDetailsType = localAuth
    ? JSON.parse(localStorage.getItem("auth") || "")
    : {};
  const authreturn = {
    access: auth.access?.token,
    refresh: auth.refresh?.token,
  };
  return authreturn;
};

export const Logout = () => {
  localStorage.removeItem("auth");
};

const useAxiosPrivate = (axiosInstance: AxiosInstance) => {
  const refresh = useRefreshToken();
  const dispatch = useDispatch();
  // var auth = useTypedSelector(getAuthState, shallowEqual);
  // const dispatch = useDispatch();
  const auth = getCurrentAuth();
  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      // @ts-ignore: Unreachable code error
      (config: AxiosRequestConfig) => {
        if (!config.headers!["Authorization"]) {
          config.headers!["Authorization"] = `bearer ${auth?.access}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          (error?.response?.status === 403 ||
            error?.response?.status === 401) &&
          !prevRequest?._retry
        ) {
          prevRequest._retry = true;
          const newaccess = await refresh();
          console.log(newaccess);
          if (!newaccess) {
            // dispatch(logout());
            dispatch(commonDetailSlice.actions.removecommonDetails());
            Logout();
          } else {
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `bearer ${newaccess}`;
            prevRequest.headers["Authorization"] = `bearer ${newaccess}`;
            return axiosInstance(prevRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosInstance;
};

export default useAxiosPrivate;
