import { SxProps } from "@mui/material";

const Style: { [key: string]: SxProps } = {
  HeadingBoxesPricing: {
    textAlign: "center",
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 1,

    border: "1px solid #FFFFFF",
    borderBottom: { xs: "1px solid FFFFFF", lg: "none" },
  },
  PricingCard: {
    width: { xs: "320px", sm: "345px" },
    mx: "auto",
    my: { xs: 1, md: 0 },
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "8px",
    height: "auto",
  },
};
export default Style;
