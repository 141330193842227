import { Menu } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Drawer,
  List,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import EditIcon from "@mui/icons-material/Edit";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import RestoreIcon from "@mui/icons-material/Restore";
import UploadFile from "../../Common/Components/Campaign/UploadFilePopup";
import LaptopIcon from "@mui/icons-material/Laptop";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { APIRoutes } from "../../utils/constants";
import { toast } from "react-toastify";
import he from "he";
import LinkPopup from "../../Common/Components/Campaign/LinkPopup";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const CustomEditor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useAxiosPrivate(axios);
  const params = useParams();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [htmlFileString, setHtmlFileString] = useState("");
  const [images, allImages] = useState([]);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const [uploadPopupData, setUploadPopupData] = useState(null);
  const [mobileType, setMobileType] = useState("desktop");
  const [getMobileString, setMobileString] = useState("");
  const [templateData, setTemplateData] = useState(null);
  const [openLinkPopup, setOpenLinkPopup] = useState(false);
  const [linkPopupData, setLinkPopupData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [htmlData, setHtmlData] = useState({
    originalHtml: "",
    currentHtml: "",
  });
  const navigate = useNavigate();
  async function fetchHtml() {
    if (searchParams.get("type") == "template") {
      const res = await client.get(
        APIRoutes.GetUserTemplateById.replace(":id", `${params.id}`)
      );
      setTemplateData(res.data.data);
      setHtmlFileString(res.data.data.Template);
      setHtmlData({
        ...htmlData,
        originalHtml: res.data.data.OriginalTemplate,
        currentHtml: res.data.data.Template
      });
    } else {
      const res = await client.get(
        APIRoutes.GetScheduleById.replace(":id", `${params.id}`)
      );
      setTemplateData(res.data.data);
      setHtmlFileString(res.data.data.Template);
      setHtmlData({
        ...htmlData,
        originalHtml: res.data.data.Template,
        currentHtml: res.data.data.Template
      });
    }
  }

  const updateTemplate = async () => {
    setIsLoading(true);
    if (searchParams.get("type") == "template") {
      let data = {
        template:
          document.getElementById("template-editor")?.firstElementChild
            ?.innerHTML,
      };
      const res = await client.put(
        APIRoutes.UpdateUserTemplate.replace(":id", `${params.id}`),
        data
      );
      if (res.data.data[0] == 1) {
        toast.success("Updated Successfully");
        navigate(-1);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(res.data.message);
      }
    } else {
      let data = {
        id: params.id,
        template:
          document.getElementById("template-editor")?.firstElementChild
            ?.innerHTML,
      };
      const res = await client.put(APIRoutes.UpdateCampaign, data);
      if (res.data.data[0] == 1) {
        setIsLoading(false);
        toast.success("Updated Successfully");
        navigate(-1);
      } else {
        setIsLoading(false);
        toast.error(res.data.message);
      }
    }
  };
  useEffect(() => {
    document.body.classList.add("sidebarhide");
    fetchHtml();
    return () => {
      document.body.classList.remove("sidebarhide");
    };
  }, []);

  const handleChange = (event) => {
    const sanitizedText = event.target.innerText;
    setHtmlFileString(sanitizedText);
  };

  const handleClose = () => {
    setOpenUploadPopup(false);
    setUploadPopupData(null);
  };

  const submitClose = (data) => {
    handleClose();
    console.log(data);
    if (data.item.type == "Image") {
      data.item.tag.src = data.imageUrl;
    } else {
      let html: any = document.getElementById("template-editor")?.innerHTML;
      html = html?.replace(data.item.src, data.imageUrl);
      document.getElementById("template-editor")!.innerHTML = html ? html : "";
    }
    setTimeout(() => {
      setHtmlData({
        ...htmlData,
        currentHtml: document.getElementById("template-editor")
          ?.firstElementChild?.innerHTML
          ? document.getElementById("template-editor")!.firstElementChild!
              .innerHTML
          : "",
      });
    }, 100);
    // setHtmlFileString(htmlFileString.replace(data.item.src, data.imageUrl));

    templateOnClick();
    getImages();
  };

  const templateOnClick = () => {
    setTimeout(() => {
      document.getElementById("template-click")!.onclick = (event) => {
        clickOnHtml(event);
      };
      let timeout;
      document.getElementById("template-click")!.onkeydown = (event) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          setHtmlData({
            ...htmlData,
            currentHtml: document.getElementById("template-editor")
              ?.firstElementChild?.innerHTML
              ? document.getElementById("template-editor")!.firstElementChild!
                  .innerHTML
              : "",
          });
        }, 500);
      };
    }, 100);
  };

  const handleLinkClose = () => {
    setOpenLinkPopup(false);
    setLinkPopupData(null);
  };

  const submitLinkClose = (data) => {
    console.log(data);
    data.target.href = data.newlink;
    if (data.element != "parentElement") {
      data.target.innerText = data.newname;
    }
    setOpenLinkPopup(false);
    setLinkPopupData(null);
    setHtmlData({
      ...htmlData,
      currentHtml: document.getElementById("template-editor")?.firstElementChild
        ?.innerHTML
        ? document.getElementById("template-editor")!.firstElementChild!
            .innerHTML
        : "",
    });
  };

  const clickOnHtml = (event) => {
    if (event.target.tagName.toLowerCase() === "a") {
      var href = event.target.href;
      let data: any = {
        name: event.target.innerText,
        link: href,
        target: event.target,
        element: "current",
      };
      setLinkPopupData(data);
      setOpenLinkPopup(true);
    } else if (event.target.parentElement.tagName.toLowerCase() === "a") {
      var href = event.target.parentElement.href;
      let data: any = {
        name: event.target.parentElement.innerText,
        link: href,
        target: event.target.parentElement,
        element: "parentElement",
      };
      setLinkPopupData(data);
      setOpenLinkPopup(true);
    }
  };

  const getImages = () => {
    let elements = document.querySelectorAll(
      "#template-editor img:not(.social-image,.logo__replace)"
    );
    let imgs: any = [];
    elements.forEach((item: any) => {
      let img = item.getAttribute("src");
      imgs.push({
        src: img,
        tag: item,
        type: "Image",
        width: item.width ? (item.width > 200 ? 200 : item.width) : 200,
        height: item.height ? (item.height > 200 ? 200 : item.height) : 200,
      });
    });
    let backgroundIMages = document.body.innerHTML
      .match(/background-image.+?\((.+?)\)/gi)
      ?.map(function (e) {
        return (
          (e.match(/background-image.+?\((.+?)\)/i) || [])[1] || ""
        ).replace(/&quot;|"/g, "");
      });
    backgroundIMages?.forEach((item: any) => {
      imgs.push({
        src: item.replaceAll("'", ""),
        tag: item.replaceAll("'", ""),
        type: "Background-Image",
        width: item.width ? (item.width > 200 ? 200 : item.width) : 200,
        height: item.height ? (item.height > 200 ? 200 : item.height) : 200,
      });
    });
    allImages(imgs);
  };

  useEffect(() => {
    templateOnClick();
    setTimeout(() => {
      getImages();
    }, 500);
  }, [htmlFileString]);

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: any) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Typography
        sx={{ marginLeft: "10px", marginTop: "10px", fontSize: "20px" }}
        variant="h5"
      >
        Images
      </Typography>
      <List
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {images.map((item: any, index) => (
          <Card
            sx={{
              marginBottom: "20px",
              border: "1px solid #009fda",
              position: "relative",
              overflow: "visible",
            }}
            onClick={() => {
              scrollImage(item);
            }}
            key={item.src}
          >
            <img
              style={{
                width: `${item?.width + "px"}`,
                height: `${item?.height + "px"}`,
                objectFit: "contain",
              }}
              alt=""
              src={item.src}
            />
            <Button
              sx={{
                position: "absolute",
                right: "-15px",
                top: "-15px",
                maxWidth: "30px",
                minWidth: "30px",
                backgroundColor: "#009fda",
                borderRadius: "9px",
                ":hover": {
                  bgcolor: "#009fda",
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                setOpenUploadPopup(true);
                setUploadPopupData(item);
              }}
              type="button"
            >
              <EditIcon sx={{ color: "#fff", width: "20px", height: "20px" }} />
            </Button>
          </Card>
        ))}
      </List>
    </Box>
  );

  const scrollImage = (item: any) => {
    let imageElement;
    if (item.type == "Image") {
      imageElement = document.querySelector(
        `#template-editor img[src="${item.src}"]`
      );
      if (imageElement) {
        setTimeout(() => {
          imageElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 200);
      } else {
      }
    } else {
      const targetBackgroundImage = `url("${item.src}")`; // Replace with the background image URL you want to find
      const elements = document.querySelectorAll("*");
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        const backgroundImage = getComputedStyle(element).backgroundImage;

        if (backgroundImage === targetBackgroundImage) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 200);
          return; // Stop after finding the first element with the background image
        }
      }
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ paddingTop: "10px" }}>{children} </Box>}
      </div>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: {
            xs: "75px",
            lg: "8px",
          },
          zIndex: "999",
        }}
      >
        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            minWidth: "30px",
            marginRight: "15px",
          }}
          type="button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </Button>
        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            marginRight: "10px",
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
              xl: "none",
            },
          }}
          onClick={toggleDrawer("left", true)}
        >
          <Menu sx={{ color: "#fff", marginRight: "10px" }} /> {"Build"}
        </Button>
        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            minWidth: "30px",
            marginRight: "15px",
          }}
          type="button"
          onClick={() => {
            document.execCommand("undo", false, "");
          }}
        >
          <UndoIcon />
        </Button>
        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            minWidth: "30px",
            marginRight: "15px",
          }}
          onClick={() => {
            document.execCommand("redo", false, "");
          }}
          type="button"
        >
          <RedoIcon />
        </Button>
        {mobileType == "desktop" && (
          // <Button
          //   sx={{
          //     color: "#fff",
          //     backgroundColor: "#009fda",
          //     marginRight: "15px",
          //     zIndex: "999",
          //     ":hover": {
          //       bgcolor: "#009fda",
          //     },
          //   }}
          //   onClick={() => {
          //     updateTemplate();
          //   }}
          //   type="button"
          // >
          //   Save
          // </Button>
          <LoadingButton
            loading={loading}
            type="button"
            loadingPosition="start"
            startIcon={loading && <SaveIcon />}
            variant="contained"
            onClick={() => {
              updateTemplate();
            }}
          >
            Save
          </LoadingButton>
        )}
        {mobileType == "desktop" && searchParams.get("type") == "template" && (
          <Button
            sx={{
              color: "#fff",
              zIndex: "999",
              minWidth: "30px",
              marginRight: "15px",
            }}
            onClick={() => {
              if (htmlData.originalHtml) {
                setHtmlFileString("");
                setTimeout(() => {
                  setHtmlFileString(htmlData.originalHtml);
                }, 0);
              }
            }}
            type="button"
          >
            <FormatClearIcon />{" "}
            <Typography sx={{ marginLeft: "5px" }} variant="body2">
              {" "}
              Reset
            </Typography>
          </Button>
        )}
        {mobileType == "desktop" && searchParams.get("type") == "template" && (
          <Button
            sx={{
              color: "#fff",
              zIndex: "999",
              minWidth: "30px",
              marginRight: "15px",
            }}
            onClick={() => {
              if (htmlData.currentHtml) {
                setHtmlFileString("");
                setTimeout(() => {
                  setHtmlFileString(htmlData.currentHtml);
                }, 0);
              }
            }}
            type="button"
          >
            <RestoreIcon />{" "}
            <Typography sx={{ marginLeft: "5px" }} variant="body2">
              {" "}
              Restore
            </Typography>
          </Button>
        )}

        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            minWidth: "30px",
            marginRight: "15px",
            display: {
              xs: "none",
              sm: "none",
              md: "unset",
              lg: "unset",
              xl: "unset",
            },
            borderBottom:
              mobileType == "desktop" ? "1px solid #009fda" : "none",
          }}
          onClick={() => {
            setMobileString("");
            setMobileType("desktop");
            templateOnClick();
          }}
          type="button"
        >
          <LaptopIcon />
        </Button>
        <Button
          sx={{
            color: "#fff",
            zIndex: "999",
            minWidth: "30px",
            marginRight: "15px",
            display: {
              xs: "none",
              sm: "none",
              md: "unset",
              lg: "unset",
              xl: "unset",
            },
            borderBottom: mobileType == "phone" ? "1px solid #009fda" : "none",
          }}
          onClick={() => {
            setMobileString(
              document.getElementById("template-editor")?.innerHTML
                ? document.getElementById("template-editor")!.innerHTML
                : ""
            );
            setMobileType("phone");
          }}
          type="button"
        >
          <SmartphoneIcon />
        </Button>
      </Box>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box
          sx={{
            width: "25%",
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "flex",
              xl: "flex",
            },
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "20%",
              paddingTop: "20px",
              paddingRight: "10px",
            }}
          >
            <Button
              sx={{
                color: "#009fda",
                borderRight: "1px solid #fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <GridViewIcon />
                <Typography
                  sx={{
                    fontSize: "12px",
                    letterSpacing: "1px",
                    marginTop: "5px",
                    color: "#FFf",
                  }}
                  variant="h6"
                >
                  Content
                </Typography>
              </Box>
            </Button>
          </Box>
          <Box
            sx={{ height: "calc(100vh - 75px)", overflowY: "scroll", flex: 1 }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingRight: "20px",
                paddingLeft: "5px",
              }}
            >
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={0}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    sx={{
                      color: "#009fda",
                      letterSpacing: "1px",
                      textTransform: "none",
                    }}
                    label="Images"
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={0} index={0}>
                {images?.length > 0 &&
                  images.map((item: any, index) => (
                    <Card
                      sx={{
                        marginBottom: "20px",
                        border: "1px solid #009fda",
                        position: "relative",
                        overflow: "visible",
                        backgroundColor: "transparent",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        scrollImage(item);
                      }}
                      key={item.src}
                    >
                      <img
                        style={{
                          width: `${item?.width + "px"}`,
                          height: `${item?.height + "px"}`,
                          objectFit: "contain",
                        }}
                        alt=""
                        src={item.src}
                      />
                      <Button
                        sx={{
                          position: "absolute",
                          right: "-15px",
                          top: "-15px",
                          maxWidth: "30px",
                          minWidth: "30px",
                          backgroundColor: "#009fda",
                          borderRadius: "9px",
                          ":hover": {
                            bgcolor: "#009fda",
                          },
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenUploadPopup(true);
                          setUploadPopupData(item);
                        }}
                        type="button"
                      >
                        <EditIcon
                          sx={{ color: "#fff", width: "20px", height: "20px" }}
                        />
                      </Button>
                    </Card>
                  ))}
              </CustomTabPanel>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "75%",
            },
            height: "calc(100vh - 75px)",
            overflowY: "scroll",
            marginTop: {
              xs: "50px",
              lg: "unset",
            },
          }}
          id="template-editor"
          className="template-editor"
        >
          {mobileType == "desktop" && (
            <div
              id="template-click"
              // onClick={(e) => clickOnHtml(e)}
              contentEditable
              dangerouslySetInnerHTML={{ __html: he.decode(htmlFileString) }}
            ></div>
          )}
          {/* {mobileType == "phone" && getMobileString && (
            <div id="wrapper">
              <div
                className="phone view_2"
                id="phone_1"
                style={{
                  width: "360px",
                  height: "640px",
                }}
              >
                <iframe
                  className="mobile_frame"
                  srcDoc={htmlFileString}
                ></iframe>
              </div>
            </div>
          )} */}
          {mobileType == "phone" && getMobileString && (
            <div id="wrapper">
              <div
                className="phone view_2"
                id="phone_1"
                style={{
                  width: "360px",
                  height: "640px",
                }}
              >
                <iframe
                  className="mobile_frame"
                  srcDoc={getMobileString}
                ></iframe>
                {/* <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div> */}
              </div>
            </div>
          )}
        </Box>
      </Box>
      {openUploadPopup && uploadPopupData && (
        <UploadFile
          title={"Upload File"}
          buttonName={"Save"}
          open={openUploadPopup}
          handleClose={handleClose}
          submitClose={submitClose}
          data={uploadPopupData}
        />
      )}
      {openLinkPopup && linkPopupData && (
        <LinkPopup
          title={"Change Link"}
          buttonName={"Save"}
          open={openLinkPopup}
          handleClose={handleLinkClose}
          submitClose={submitLinkClose}
          data={linkPopupData}
        />
      )}
    </>
  );
};
export default CustomEditor;
