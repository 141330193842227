import * as React from "react";
import { alpha, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Archive, Settings } from "@mui/icons-material";
import { Button, Chip, Rating } from "@mui/material";
import TableRowData from "./TableRow";
import ReplayIcon from '@mui/icons-material/Replay';
interface Data {
  // Name: string;
  Subject: string;
  Status: string;
  Open: number;
  Click: number
  // Subject: string;
  // open: string;
  // clicks: string;
  // deliveryDate: string;
  edit: string;
}

function createData(
  // Name: string,
  Subject: string,
  Status: string,
  Open: number,
  Click: number,
  // Subject: string,
  // open: string,
  // clicks: string,
  // deliveryDate: string,
  edit: string
): Data {
  return {
    // Name,
    Subject,
    Status,
    Open,
    Click,
    // Subject,
    // open,
    // clicks,
    // deliveryDate,
    edit,
  };
}
const rows: any = [
  // createData("Cupcake", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("Kashi", "305", "3.7", "67", "4.3", "ka", ""),

  // createData("C", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("DD", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("SS", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("DFF", "305", "3.7", "67", "4.3", "ka", ""),
];
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  // {
  //   id: "Name",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Name",
  // },
  {
    id: "Subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "email",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Emails",
  // },
  {
    id: "Open",
    numeric: false,
    disablePadding: false,
    label: "Open",
  },
  {
    id: "Click",
    numeric: false,
    disablePadding: false,
    label: "Click",
  },
  // {
  //   id: "deliveryDate",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Delivery date",
  // },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

interface EnhancedTableProps {
  numSelected: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;

  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            key={headCell.id}
          >
            <Typography variant="body2">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  retrySchedule: any;
  schedule: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, schedule, retrySchedule } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          color="inherit"
          variant="h6"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: schedule && schedule.isTechnicalIssue ? 'space-between' : 'flex-start'}}>
<Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Campaigns
        </Typography>
        {
          schedule && schedule.isTechnicalIssue && <Button onClick={() => retrySchedule()} type="button"><ReplayIcon /></Button>
        }
        
        </Box>
        
      )}
      {numSelected > 0 && (
        <Tooltip title="Archive">
          <IconButton>
            <Archive sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function EnhancedTable(props: any) {
  const { data, updateMainAPI, schedule, retrySchedule } = props;
  const [order, setOrder] = React.useState<Order>("asc");

  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [orderBy, setOrderBy] = React.useState<keyof Data>("Subject");

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage,data]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, bgcolor: "common.blue" }}>
        <EnhancedTableToolbar numSelected={selected.length} schedule={schedule} retrySchedule={retrySchedule}/>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {data && visibleRows.map((row: any, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRowData
                    data={row}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    handleClick={(event: any) => handleClick(event, row.name)}
                    updateMainAPI={updateMainAPI}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            ".MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
            ".MuiSelect-select": {
              color: "#FFFFFF",
            },
          }}
          SelectProps={{
            MenuProps:{
              sx:{
                '.MuiPaper-root': {
                  backgroundColor: '#09283a',
                },
                ".MuiTablePagination-menuItem.Mui-selected":{
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                backgroundColor: "#15327c"
                },
                '.MuiTablePagination-menuItem': {
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                  backgroundColor: '#09283a',
                },
              }
            }
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />{" "}
      </Paper>
    </Box>
  );
}
