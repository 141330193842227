import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, PhotoCamera } from "@mui/icons-material";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCommonDetails from "../../../Common/Hooks/useCommonDetails";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import Style from "../../MyCampaigns/MyCampaigns.style";
import SaveIcon from "@mui/icons-material/Save";
import { Close } from "@mui/icons-material";
import ChangePassowordPopup from "./ChangePasswordPopUp";
import axios from "../../../utils/axios";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../../store/reducers/commonReducer";

const UpdateProfile = () => {
  
  const userDetails = useCommonDetails();
  const [open, setOpen] = useState(false);
  console.log(userDetails);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [selectImage, setSelectImage] = useState<any>(null);
  const [firstName,setFirstName] = useState(userDetails?.FirstName);
  const [lastName,setLastName] = useState(userDetails?.LastName);
  const [isLoading,setIsLoading] = useState(false);
  const client = useAxiosPrivate(axios);
  const dispatch = useDispatch();

  const handleFileChange = async(event) => {
    if(!event.target.files[0])
    {toast("Please Select Image"); return}
    try {
      const formData = new FormData();
      
      formData.append("files", event.target.files[0]);
      setIsLoading(true);
      const res2 = await client.post(APIRoutes.UploadCampaignsImage,formData);
      setSelectImage(res2.data.Location);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  }

  async function getEntries(nameData: any) {
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(nameData));
  }
  const handleUpdate = async()=>{
    try {
      const nameData =  {
        "first_name": firstName,
        "last_name": lastName,
        "profile_url": selectImage ? selectImage : ""
      };
      const res = await client.post(APIRoutes.ProfileNameUpdate,nameData);
      toast.success("Successfully Updated");
      getEntries(res.data.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.MyCampaignsHeader}>
          <Typography variant="h2">Update Profile</Typography>
        </Box>
      </Box>
      <Box sx={{ py: 5 }}>
        <Typography variant="subtitle1">About you</Typography>
      </Box>
      <Box sx={{ display:"flex",alignItems:"center",pb:5 }}>
        <IconButton
          sx={{
            width: "64px",
            height: "64px",
            backgroundColor: selectImage || userDetails?.ProfilePicURL ? "transparent": "#D9D9D9",
            p: 0,
            '&:hover': {
              opacity:"0.7",
            },
          }}
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input
          accept="image/*"
          hidden
          id="raised-button-file"
          onChange={handleFileChange}
          type="file"
          />
          {isLoading ? (
            <CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />
          )  : selectImage? (
            <img
              width={"100%"}
              height="100%"
              style={{ objectFit: "cover", borderRadius: "50%" }}
              src={selectImage}
              alt=""
            />
          ) : userDetails?.ProfilePicURL? (
            <img
              width={"100%"}
              height="100%"
              style={{ objectFit: "cover", borderRadius: "50%" }}
              src={userDetails?.ProfilePicURL}
              alt=""
            />
          ):(
            <PhotoCamera sx={{ color: "grey" }} />
          )}
        </IconButton>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              name="first_name"
              id="first_name"
              label="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              error={!firstName? true : false}
              helperText={!firstName?"please enter first name":""}
              type={"text"}
              placeholder={"First Name"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              label="Last Name"
              name="last_name"
              value={lastName}
              id="last_name"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              error={!lastName ? true : false}
              helperText={!lastName?"please enter last name":""}
              type={"text"}
              placeholder={"Last Name"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              autoComplete="off"
              size="small"
              sx={{ color: "#FFFFFF" }}
              disabled
              defaultValue={userDetails?.Email}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              disabled
              size="small"
              sx={{ color: "#FFFFFF" }}
              value={"xxxxxxxxxx"}
              variant="outlined"
              type={"password"}
            />
            <ChangePassowordPopup />
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" sx={{ my: 5 }}
          onClick={handleUpdate}>
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default UpdateProfile;
