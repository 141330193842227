import React,{useEffect} from "react";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Archive, Settings } from "@mui/icons-material";
import { Chip, Rating } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";

interface Data {
  Name: string;
  NumberOfDaysCampaign: number;
  openCount: number;
  clickCount: number;
  // clicks: string;
  // deliveryDate: string;
  edit: string;
}

function createData(
  Name: string,
  NumberOfDaysCampaign: number,
  openCount: number,
  clickCount: number,
  edit: string
): Data {
  return {
    Name,
    NumberOfDaysCampaign,
    openCount,
    clickCount,
    edit,
  };
}
const rows: any = [
  // createData("Cupcake", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("Kashi", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("C", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("DD", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("SS", "305", "3.7", "67", "4.3", "ka", ""),
  // createData("DFF", "305", "3.7", "67", "4.3", "ka", ""),
];
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  // {
  //   id: "status",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Status",
  // },
  {
    id: "NumberOfDaysCampaign",
    numeric: false,
    disablePadding: false,
    label: "Days",
  },
  {
    id: "openCount",
    numeric: false,
    disablePadding: false,
    label: "Opens",
  },
  {
    id: "clickCount",
    numeric: false,
    disablePadding: false,
    label: "Clicks",
  },
  // {
  //   id: "deliveryDate",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Delivery date",
  // },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: " ",
  },
];

interface EnhancedTableProps {
  numSelected: number;

  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;

  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            key={headCell.id}
          >
            <Typography variant="body2">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          color="inherit"
          variant="h6"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", color: "#FFFFFF" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          List
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Archive">
          <IconButton>
            <Archive sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function EnhancedTable(props) {
  const {data,isCatogary} = props;
//   const { data, updateMainAPI } = props
  const [order, setOrder] = React.useState<Order>("asc");
  const {id} = useParams();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [orderBy, setOrderBy] = React.useState<keyof Data>("Name");
  const navigate = useNavigate();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

//   const visibleRows = React.useMemo(
//     () =>
//       stableSort(data, getComparator(order, orderBy)).slice(
//         page * rowsPerPage,
//         page * rowsPerPage + rowsPerPage
//       ),
//     [order, orderBy, page, rowsPerPage, data]
//   );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2, bgcolor: "common.blue" }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableBody>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                </TableRow>
                
                {data.map((ele) =>(
                  <TableRow>
                    <TableCell>{ele.Id}</TableCell>
                    <TableCell>
                        {isCatogary?(<Typography variant="body2" onClick={()=>{navigate(PageRoutes.EmailTemplate.replace(":subId",ele.Id))}} sx={{textDecoration: "underline",cursor:"pointer"}}>
                            {ele.Name}
                        </Typography>):
                        (<Typography variant="body2" onClick={()=>{navigate(PageRoutes.IndustryCatogary.replace(":id",ele.Id))}} sx={{textDecoration: "underline",cursor:"pointer"}}>
                            {ele.Name}
                        </Typography>)
                        }
                    </TableCell>
                  </TableRow>  
                ))}

              {/* {data &&
                visibleRows.map((row: any, index: any) => {
                  const isItemSelected = isSelected(row.Name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRowData
                      key={row.UserMarketingCampaignSID}
                      data={row}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      handleClick={(event: any) => handleClick(event, row.Name)}
                      updateMainAPI={updateMainAPI}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={12} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            ".MuiSvgIcon-root": {
              color: "#FFFFFF",
            },
            ".MuiSelect-select": {
              color: "#FFFFFF",
            }
          }}
          SelectProps={{
            MenuProps:{
              sx:{
                '.MuiPaper-root': {
                  backgroundColor: '#09283a',
                },
                ".MuiTablePagination-menuItem.Mui-selected":{
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                backgroundColor: "#15327c"
                },
                '.MuiTablePagination-menuItem': {
                  ':hover': {
                    backgroundColor: '#656469',
                  },
                  backgroundColor: '#09283a',
                },
              }
            }
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />{" "}
      </Paper>
    </Box>
  );
}
