import { Box, TextField, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageRoutes from "../../../utils/constants";
import he from "he";
const WaitListComponent = ({ isHomePage }: any) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleSubmit = () => {
    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!email) {
      setError("Please fill the required fields");
      return;
    } else if (!emailRegx.test(email)) {
      setError("Please enter valid email id");
      return;
    }
    const encodedEmail = he.encode(email, { encodeEverything: false });
    navigate(`${PageRoutes.Login}?q=false`, { state: { email } });
  };
  return (
    <Box
      sx={{
        my: 3,
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 2,
      }}
    >
      <Box>
        <TextField
          size="small"
          type={"email"}
          helperText={error}
          error={error ? true : false}
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value);
            setError("");
          }}
          inputProps={{
            sx: {
              bgcolor: "#FFFFFF",
              borderRadius: "4px",
            },
          }}
          sx={{
            // border: "2px solid #CED3E0",
            minWidth: { xs: "auto", md: "300px" },
            width: isHomePage ? 1 : "auto",
            input: {
              color: "black",
            },
          }}
          placeholder="Email Address"
        />
        <Typography variant="body2" sx={{ my: 0.5 }}>
          Be one of the first to use my services.
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{
            color: "#FFFFFF",
          }}
          onClick={handleSubmit}
        >
          JOIN WAITLIST
        </Button>
      </Box>
    </Box>
  );
};
export default WaitListComponent;
