import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { FC } from "react";
import CommonStyle from "../../Common/style/Style";
import Style from "./Pricing.style";
import EnterPrice from "../../assets/img/leaderEnterPrice.svg";
import MidMarket from "../../assets/img/MidMarket.svg";
import SmallBusiness from "../../assets/img/SmallBusiness.svg";
import SmallBusinessBest from "../../assets/img/SmallBusinessBest.svg";
import EnterpriseBest from "../../assets/img/EnterpriseBest.svg";
import AsiaPacific from "../../assets/img/AsiaPacific.svg";
import Winter from "../../assets/img/Winter.svg";
import Applist from "../../assets/img/apps-group-768x366.png.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ArrowForward } from "@mui/icons-material";
import WaitListComponent from "../../Common/Components/WaitListComponent";
const Pricing: FC = () => {
  return (
    <Container maxWidth="xl">
      <Box sx={{ py: { xs: 3, md: 6 }, textAlign: "center" }}>
        <Typography variant="h1" sx={{ fontWeight: 700 }}>
          Grow Your Business with AIRYIS
        </Typography>
      </Box>
      <Box sx={{ maxWidth: "1080px", m: "auto" }}>
        <Box>
          <Grid container spacing={{ xs: 2, lg: 0 }} rowGap={4}>
            <Grid item xs={6} lg={3}>
              <Box sx={Style.HeadingBoxesPricing}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {" "}
                  Marketing
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: { xs: "normal", lg: "nowrap" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                >
                  Email & Marketing Automation
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box sx={Style.HeadingBoxesPricing}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {" "}
                  Sales
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: { xs: "normal", lg: "nowrap" },

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                >
                  CRM & Sales Engagement
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box sx={Style.HeadingBoxesPricing}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {" "}
                  Bundles
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: { xs: "normal", lg: "nowrap" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                >
                  Marketing Automation + Sales CRM
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6} lg={3}>
              <Box sx={Style.HeadingBoxesPricing}>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {" "}
                  Transactional Email
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: { xs: "normal", lg: "nowrap" },
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                >
                  API + SMTP for Your Site or App
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ py: 2, my: 8 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                width: 1,

                display: "grid",

                gridTemplateColumns: "repeat(auto-fit, minmax(345px, 1fr))",
                gridGap: "20px",
              }}
            >
              <Box sx={{ ...Style.PricingCard }}>
                <Box sx={{ textAlign: "center", py: 2.25 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Plus
                  </Typography>
                </Box>
                <Divider sx={{ borderColor: "#FFFFFF" }} />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="body2">
                    For small teams crafting excellent experiences across the
                    customer lifecycle
                  </Typography>
                  <Box
                    sx={{
                      mt: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: 1,
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography>$</Typography>
                      <Typography sx={{ fontSize: "52px", lineHeight: "42px" }}>
                        49
                      </Typography>
                    </Box>

                    <Typography variant="caption">
                      per month, paid yearly (USD)
                    </Typography>
                    <Typography variant="caption">3 Users</Typography>

                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ fontWeight: 700 }}
                    >
                      Get Started
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: "start" }}>
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 700, visibility: "hidden" }}
                    >
                      Everything in Plus +
                    </Typography>
                    <Box sx={{ ...CommonStyle.CardDetailsList, py: 2 }}>
                      <Typography variant="body2">
                        Email Marketing & Marketing Automation
                      </Typography>
                      <Typography variant="body2">
                        Inline, Pop up and Modal Forms
                      </Typography>
                      <Typography variant="body2">Landing Pages</Typography>
                      <Typography variant="body2">
                        Site & Event Tracking
                      </Typography>
                      <Typography variant="body2">API & Webhooks</Typography>
                      <Typography variant="body2">
                        WooCommerce, Shopify & BigCommerce Integrations
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ...Style.PricingCard, position: "relative" }}>
                <Box
                  sx={{
                    textAlign: "center",
                    py: 0.5,
                    left: 0,
                    right: 0,
                    top: -20,
                    bgcolor: "#021927",
                    position: "absolute",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 700, textTransform: "uppercase" }}
                  >
                    {" "}
                    Most Popular
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", py: 2.25 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Professional
                  </Typography>
                </Box>
                <Divider sx={{ borderColor: "#FFFFFF" }} />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="body2">
                    For teams that want to experiment and optimize their
                    marketing efforts
                  </Typography>
                  <Box
                    sx={{
                      mt: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: 1,
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography>$</Typography>
                      <Typography sx={{ fontSize: "52px", lineHeight: "42px" }}>
                        149
                      </Typography>
                    </Box>

                    <Typography variant="caption">
                      per month, paid yearly (USD)
                    </Typography>
                    <Typography variant="caption">5 Users</Typography>

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{ fontWeight: 700 }}
                    >
                      Get Started
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: "start" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                      Everything in Plus +
                    </Typography>
                    <Box sx={{ ...CommonStyle.CardDetailsList, py: 2 }}>
                      <Typography variant="body2">
                        Predictive Sending
                      </Typography>
                      <Typography variant="body2">Split Automations</Typography>
                      <Typography variant="body2">
                        Salesforce Integration
                      </Typography>
                      <Typography variant="body2">
                        Microsoft Dynamics 365 Integration
                      </Typography>
                      <Typography variant="body2">Site Messages</Typography>
                      <Typography variant="body2">
                        Attribution Reporting
                      </Typography>
                      <Typography variant="body2">Accounts</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={Style.PricingCard}>
                <Box sx={{ textAlign: "center", py: 2.25 }}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    Enterprise
                  </Typography>
                </Box>
                <Divider sx={{ borderColor: "#FFFFFF" }} />
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="body2">
                    For growing teams in larger orgs looking to personalize
                    their marketing at scale
                  </Typography>
                  <Box
                    sx={{
                      mt: 6,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: 1,
                      gap: 1,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                      <Typography>
                        Let’s customize the right <br /> plan for you
                      </Typography>
                    </Box>

                    <Typography sx={{ visibility: "hidden" }} variant="caption">
                      per month, paid yearly (USD)
                    </Typography>
                    <Typography variant="caption">3 Users</Typography>

                    <Button
                      fullWidth
                      variant="outlined"
                      sx={{ fontWeight: 700 }}
                    >
                      Get Started
                    </Button>
                  </Box>
                  <Box sx={{ mt: 3, textAlign: "start" }}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                      Everything in Plus +
                    </Typography>
                    <Box sx={{ ...CommonStyle.CardDetailsList, py: 2 }}>
                      <Typography variant="body2">Custom Reporting</Typography>
                      <Typography variant="body2">Custom Objects</Typography>
                      <Typography variant="body2">Single Sign-On</Typography>
                      <Typography variant="body2">Uptime SLA</Typography>
                      <Typography variant="body2">HIPAA Support</Typography>
                      <Typography variant="body2">
                        Custom Mailserver Domain
                      </Typography>
                      <Typography variant="body2">
                        Unlimited Email Testing
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ textAlign: "center", my: 3 }}>
            <Button sx={{ mx: "auto", py: 2 }} variant="outlined">
              Only need the basics? Check out our plans made for individuals and
              small teams just starting out <ArrowForward />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            py: 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h1" sx={{ fontWeight: 700 }}>
            Services and tools to help every growing business
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
            No matter what plan you choose, our services are designed to help
            you get the most out of your investment — so you can focus on
            running your business.
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 700, my: 2 }}>
            Below services included in all plan tiers:
          </Typography>
          <Grid
            container
            rowGap={{ xs: 2, md: 3 }}
            sx={{ my: 3, textAlign: { xs: "start", md: "center" } }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1"> Accelerated Onboarding</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1"> Contact Migration</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">
                {" "}
                Customer Enablement Workshops
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1"> Video Tutorials</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1"> Chat and Ticket Support</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="body1">
                {" "}
                Knowledge Base & Help Guides
              </Typography>
            </Grid>
          </Grid>
          <Button variant="contained" sx={{ fontWeight: 600 }}>
            Learn about all AIRYIS Services
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            py: 5,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: 1, md: 0.5 } }}>
            <Typography variant="h2" sx={{ fontWeight: 600, my: 1 }}>
              Team up with your favorite apps
            </Typography>
            <Divider sx={{ borderColor: "white" }} />
            <Typography variant="body1" sx={{ my: 1 }}>
              AIRYIS plays well with your favorite software. With 870+
              integrations in our app marketplace like Shopify, WordPress, and
              Zapier available, you’ll have all the marketing tools you need to
              succeed.
            </Typography>
            <Button
              variant="text"
              sx={{ color: "white", fontSize: "16px", px: 0 }}
            >
              View all apps{">"}
            </Button>
          </Box>
          <Box>
            <img src={Applist} width="100%" />
          </Box>
        </Box>
        <Box>
          <Typography
            variant="h1"
            sx={{ py: { xs: 3, md: 5 }, textAlign: "center" }}
          >
            Frequently asked questions
          </Typography>
          <Box sx={{ py: 3, px: { xs: 1, md: 3 } }}>
            <Accordion sx={{ bgcolor: "rgba(255, 255, 255, 0.05)", my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>What is a contact?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A contact is a single person you have recorded in your
                  account. Think of 1 email address being 1 contact.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ bgcolor: "rgba(255, 255, 255, 0.05)", my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  Do you restrict features with a free trial?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography></Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ bgcolor: "rgba(255, 255, 255, 0.05)", my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Do you have a setup cost?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography></Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ bgcolor: "rgba(255, 255, 255, 0.05)", my: 1 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Anything special for non-profits?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography></Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="h1"
            sx={{ py: { xs: 3, md: 5 }, textAlign: "center" }}
          >
            Top-performing G2 leader in every category
          </Typography>
          <Box
            sx={{
              width: 1,
              m: "auto",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,

                "::-webkit-scrollbar": {
                  display: "none",
                },

                overflow: "auto",
              }}
            >
              <img src={EnterPrice} />
              <img src={EnterpriseBest} />
              <img src={MidMarket} />
              <img src={SmallBusiness} />
              <img src={SmallBusinessBest} />
              <img src={Winter} />

              <img src={AsiaPacific} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ textAlign: "center", py: { xs: 5, md: 9 } }}>
          <Box
            sx={{
              py: 6,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box sx={{ textAlign: { xs: "center", lg: "start" } }}>
              <Typography variant="h1">
                Be the first to use my services.
              </Typography>
              <Typography variant="subtitle2">
                Ditch the greed. Hire me.
              </Typography>
            </Box>
            <WaitListComponent />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
export default Pricing;
