import { Settings } from "@mui/icons-material";
import {
  TableCell,
  Checkbox,
  Box,
  Rating,
  Typography,
  Chip,
  IconButton,
  TableRow,
  Avatar,
  Menu,
  MenuItem,
  styled,
  Button,
  useMediaQuery,
} from "@mui/material";
import { FC, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Style from "../../../../Common/Components/HeaderPrivateRoute/HeaderPrivateRoute.style";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageRoutes from "../../../../utils/constants";
import Popup from "../../../../Common/Components/PopupMenu/Popup";
import ContactListEdit from "../../ContactListEdit";

interface TableProps {
  data:any;
  labelId: any;
  isItemSelected: any;
  handleClick: any;
  updateMainAPI: any;
}

const StyleMenuItem = styled(MenuItem)({
  fontSize: "14px",
});
const TableRowData: FC<TableProps> = (props) => {
  const { data,labelId,updateMainAPI, isItemSelected, handleClick }: any = props;
  const [openColleps, setOpeColleps] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRename,setOpenRename] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const formateDate = (strDate:any) =>{
    const date = new Date(strDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} at ${hours}:${minutes} ${amOrPm}`;
  }
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down('sm'));
  const newCreatedAt = formateDate(data.createdAt);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseRename = ()=>{
    setOpenRename(false);
  }
  const handleRename = ()=>{
    setOpenRename(true);
  }

  const handleSubmit = (value:any)=>{
    console.log(value);
    updateMainAPI();
  }
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={data.ContactName}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      <TableCell onClick={(event) => handleClick(event)} padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          size={isSmallScreen?"small":"medium"}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" sx={{ px: 0 }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
              // flexWrap:"wrap",
            }}
          >
            <Typography
              sx={{ textDecoration: "underline" }}
              variant="body2"
              component={"a"}
              onClick={() =>
                navigate(PageRoutes.ContactDetails.replace(":id", data.Id))
              }
            >
              {data.ContactName}
            </Typography>
            <Typography variant='body1' sx={{fontSize:{xs:"12px",sm:"14px"}}}>
              Created on{" "}
              <span style={{ fontWeight:"600"}}>
                {newCreatedAt}
              </span>
            </Typography>
            <Typography variant="body2">ID: {data.Id}</Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell align={isSmallScreen?"center":"left"}>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2">1</Typography>
          <Typography variant="body2" sx={{fontSize:{xs:"13px",sm:"14px"}}} >Subscribers</Typography>
        </Box>
      </TableCell>

      <TableCell align={isSmallScreen?"left":"right"}>
        {/* <Button variant="contained" sx={{ mx: {xs:0,sm:2} }} size={isSmallScreen?"small":"medium"}>
          Verify
        </Button> */}
        <IconButton onClick={handleClickMenu}>
          <Settings sx={{ color: "#FFFFFF" }}/>
        </IconButton>
        <Menu
          id="header-menu"
          aria-labelledby="menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: Style.MenuDropDown,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          
          <StyleMenuItem onClick={handleRename}>Rename</StyleMenuItem>
          <StyleMenuItem onClick={()=>navigate(PageRoutes.ContactDetails.replace(":id",data.Id))}>Edit </StyleMenuItem>
          {/* <StyleMenuItem onClick={handleClose}>Duplicate</StyleMenuItem> */}
          <Popup
            title="Delete contact"
            data={data}
            buttonName="Delete"
            discription="Are you want to delete this contact? this process can't be undo."
            type="Contact"
            submitForm={handleSubmit}
          />
          
        </Menu>
      </TableCell>
        {openRename&&<ContactListEdit name={data.ContactName} id={data.Id} handleSubmit={handleSubmit} open={openRename} handleClose={handleCloseRename}/>}
    </TableRow>
  );
};
export default TableRowData;
