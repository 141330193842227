import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BG1 from "../../assets/img/BG1.png";
import BG2 from "../../assets/img/BG2.png";
import DividerImg from "../../assets/img/DividerImage.svg";
import WaitListComponent from "../../Common/Components/WaitListComponent";
import CommonStyle from "../../Common/style/Style";
import PageRoutes from "../../utils/constants";

const CustomerExperience: FC = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: `url(${BG1})`,
          backgroundSize: "100% 100%",
          m: "auto",
          position: "relative",
        }}
        maxWidth="xl"
      >
        <Container
          maxWidth="xl"
          disableGutters={true}
          sx={{ height: "600px", px: { xs: 2, md: 9 } }}
        >
          <Typography sx={{ fontWeight: "bold", pt: 9, mb: 3 }}>
            CUSTOMER EXPERIENCE AUTOMATION (CXA)
          </Typography>
          <Typography variant="h1" sx={CommonStyle.Heading}>
            Get your time and <br />
            money back with your <br />
            own Ai assistant
          </Typography>
          <WaitListComponent />
        </Container>
        <Box className="overlay-5"></Box>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${BG2})`,
          backgroundSize: "100% 100%",
          bgcolor: "#031827",
          m: "auto",
          position: "relative",
        }}
        maxWidth="xl"
      >
        <Box
          className="overlay-6"
          sx={{
            height: "50px",
            background: "linear-gradient(transparent, #020d15d6)",
          }}
        ></Box>
        <Container
          maxWidth="xl"
          disableGutters={true}
          sx={{ minHeight: "410px", height: "100%", px: { xs: 2, md: 9 } }}
        >
          <Typography variant="h1" sx={{ pt: 5, textAlign: "center" }}>
            Customer experience is everything
          </Typography>
          <Box sx={{ mt: 16, display: "flex", gap: 2 }}>
            <Grid container spacing={10}>
              <Grid item md={4} lg={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Typography variant="body2">
                    A great product is no longer enough to keep customers coming
                    back
                  </Typography>
                  <Typography sx={{ color: "#58F2FD", mt: 2 }} variant="body2">
                    The experience must be great too
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} lg={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Typography variant="body2">
                    A great product is no longer enough to keep customers coming
                    back
                  </Typography>
                  <Typography sx={{ color: "#58F2FD", mt: 2 }} variant="body2">
                    The experience must be great too
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} lg={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Typography variant="body2">
                    A great product is no longer enough to keep customers coming
                    back
                  </Typography>
                  <Typography sx={{ color: "#58F2FD", mt: 2 }} variant="body2">
                    The experience must be great too
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} lg={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Typography variant="body2">
                    A great product is no longer enough to keep customers coming
                    back
                  </Typography>
                  <Typography sx={{ color: "#58F2FD", mt: 2 }} variant="body2">
                    The experience must be great too
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container sx={{ textAlign: "center", mt: 6 }}>
        <img src={DividerImg} alt="divider" />
        <Typography variant="h1" sx={{ my: 5 }}>
          Don’t just automate your email, activate your entire customer
          experience
        </Typography>
        <Button
          onClick={() => navigate(`${PageRoutes.Login}?q=false`)}
          variant="contained"
          sx={{
            padding: "12px",
            fontWeight: "bold",
          }}
        >
          JOIN WAITLIST TODAY
        </Button>
      </Container>
    </Box>
  );
};
export default CustomerExperience;
