import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { APIRoutes } from "../../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
const UpdateCampaign: any = ({
  title,
  description,
  buttonName,
  open,
  handleUpdateClose,
  updateForm,
  data,
}: {
  title: string;
  description: string;
  buttonName: string;
  open: boolean;
  handleUpdateClose: any;
  updateForm: any;
  data: any;
}) => {
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please fill the required fields";
    }
    return errors;
  };

  const intialValue: {
    [key: string]: string;
  } = {
    name: data?.Name ? data?.Name : "",
  };

  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setIsSkeleton(true);
        const name = values.name;
        const dataBody: any = {
          name,
          id: data.Id.toString(),
        };
        const res = await client.put(APIRoutes.RenameCampaign, dataBody);
        updateForm(res.data);
        formik.resetForm();
        setIsSkeleton(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
        formik.resetForm();
        setIsSkeleton(false);
      }
    },
  });

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          bgcolor: "common.blue",
          minWidth: "350px",
        },
      }}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "22px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  marginBottom: "15px",
                }}
              >
                <TextField
                  size="small"
                  fullWidth
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.errors.name = "";
                  }}
                  error={formik.errors.name ? true : false}
                  helperText={formik.errors.name}
                  sx={{
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  type={"text"}
                  InputProps={{ sx: { bgcolor: "white" } }}
                  placeholder={"Name"}
                />
              </Box>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="outlined"
                  onClick={handleUpdateClose}
                >
                  <Typography variant="body2">Cancel</Typography>
                </Button>
                <LoadingButton
                  loading={isSkeleton}
                  type="submit"
                  loadingPosition="start"
                  startIcon={isSkeleton && <SaveIcon />}
                  variant="contained"
                >
                  {buttonName}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default UpdateCampaign;
