import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import NoFond from "../../assets/img/404Page.png";
const NOPageFound = () => {
  return (
    <Container>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography>No Page Found</Typography>
      </Box>
    </Container>
  );
};
export default NOPageFound;
