import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import HeaderPublicRoute from "../../Common/Components/HeaderPrivateRoute/HeaderPrivateRoute";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import { getCurrentAuth } from "../../hooks/useAxiosPrivate";
import AiryisLogo from "../../assets/img/AiryisLogo.svg";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";

const AnimationContainer = styled("div")({
  overflow: "hidden",
  whiteSpace: "nowrap",
});

const WaitList = () => {
  const user = useCommonDetails();

  return (
    <Container maxWidth="xl" sx={{ height: "100vh" }}>
      <HeaderPublicRoute />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          m: "auto",
          flexDirection: "column",
          height: "calc(100vh - 100px)",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid #DADADA",
            bgcolor: "common.blue",
            padding: 2,
            display: "flex",
            borderRadius: "12px",
            width: { xs: "300px", md: "400px" },
            flexDirection: "column",
            alignItems: "center",

            gap: 1,
          }}
        >
          {" "}
          <Box sx={{ width: "300px" }}>
            <img src={AiryisLogo} width="100%" />
          </Box>
          <Typography variant="h1" sx={{ fontWeight: "800", height: "50px" }}>
            Airyis is launching soon
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "800" }}>
            You are in the waitlist!
          </Typography>
          <Button variant="contained" sx={{ fontWeight: "800" }}>
            You’re #{user.Id} in line
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default WaitList;
