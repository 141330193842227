import React from 'react'
import { Typography, Box, useTheme } from "@mui/material";

const Header = ({title,subtitle}:{title:string,subtitle:string}) => {
  return (
    <Box px="35px" pt="25px">
    <Typography
      variant="h6"
    //   color="#70d8bd"
      color="white"
    //   sx={{mb:"5px"}}
    >
      {title}
    </Typography>
    <Typography variant="h5" color="#70d8bd">
        {subtitle}
      </Typography>
  </Box>
  )
}

export default Header