import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Switch,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { APIRoutes } from "../../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useState, Fragment } from "react";
import Lottie from "react-lottie";
import campaignAnimationData from "../../../../assets/json/campaign.json";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
const label = { inputProps: { "aria-label": "Minimum Number Of Emails" } };
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const CreateCampaign: any = ({
  title,
  description,
  buttonName,
  open,
  handleClose,
  submitClose,
}: {
  title: string;
  description: string;
  buttonName: string;
  open: boolean;
  handleClose: any;
  submitClose: any;
}) => {
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please fill the required fields";
    }
    if (!values.startDate) {
      errors.startDate = "Please fill the required fields";
    }
    if (!values.endDate) {
      errors.endDate = "Please fill the required fields";
    }
    // if (!values.numberOfDays) {
    //   errors.numberOfDays = "Please fill the required fields";
    // }
    // if (values.numberOfDays && values.numberOfDays > 30) {
    //   errors.numberOfDays = "You can max create 30 days campaign";
    // }
    // if (values.isMinMailLimit && !values.numberOfDays) {
    //   errors.numberOfDays = "Please fill the required fields";
    // }
    if (values.startDate && values.endDate && values.numberOfDays) {
      const date1 = dayjs(values.startDate);
      const date2 = dayjs(values.endDate);

      let days = date2.diff(date1, "day");
      if (Number(values.numberOfDays) > days + 1) {
        errors.numberOfDays =
          "Number of limit days is gerater than selected date";
      } else {
        delete errors.numberOfDays;
      }
    }
    return errors;
  };

  const intialValue = {
    name: "",
    startDate: "",
    endDate: "",
    // isMinMailLimit: false,
    // numberOfDays: "",
  };

  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setIsSkeleton(true);
        const name = values.name;
        // const isMinMailLimit = values.isMinMailLimit;
        // const numberOfDays = values.numberOfDays;
        const dataBody: any = {
          name,
          // isMinMailLimit,
          // numberOfMail: numberOfDays ? numberOfDays : 0,
          startDate: moment(values.startDate.toString()).format("MM/DD/YYYY"),
          endDate: moment(values.endDate.toString()).format("MM/DD/YYYY"),
        };
        const res = await client.post(APIRoutes.CreateSchedule, dataBody);
        submitClose(res.data);
        formik.resetForm();
        setIsSkeleton(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
        formik.resetForm();
        setIsSkeleton(false);
      }
    },
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: campaignAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const minDate = new Date();

  return (
    <>
      {isSkeleton && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            top: "23%",
            left: "27%",
            zIndex: "9999999999999",
            backdropFilter: "blur(3px)",
          }}
        >
          <Lottie options={defaultOptions} height={300} width={300} />
          <Typography>
              Please give me moment.'m doing work a human would've charged you $1000 to do.
          </Typography>
        </div>
      )}
      <Dialog
        open={open}
        sx={{
          ".MuiPaper-root": {
            bgcolor: "common.blue",
            minWidth: "350px",
          },
        }}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "22px",
          }}
          id="alert-dialog-title"
        >
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1.5,
                    marginBottom: "15px",
                  }}
                >
                  <TextField
                    size="small"
                    fullWidth
                    name="name"
                    id="name"
                    value={formik.values.name}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.name = "";
                    }}
                    error={formik.errors.name ? true : false}
                    helperText={formik.errors.name}
                    sx={{
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    type={"text"}
                    InputProps={{ sx: { bgcolor: "white" } }}
                    placeholder={"Name"}
                  />
                </Box>
                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    flexDirection: "row",
                    gap: 1.5,
                    marginBottom: "15px",
                  }}
                >
                  <ThemeProvider theme={darkTheme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "200px",
                        }}
                        label="Start Date"
                        minDate={dayjs(Date.now())}
                        // value={formik.values.startDate}
                        onChange={(newValue) => {
                          formik.setFieldValue("startDate", newValue);
                          formik.setFieldValue("endDate", null);
                          formik.errors.startDate = "";
                        }}
                        value={
                          formik.values.startDate
                            ? dayjs(formik.values.startDate)
                            : null
                        }
                        slots={{
                          textField: (textFieldProps) => (
                            <TextField
                              {...textFieldProps}
                              error={formik.errors.startDate ? true : false}
                              helperText={formik.errors.startDate}
                            />
                          ),
                        }}
                      />

                      <DatePicker
                        sx={{
                          width: "200px",
                        }}
                        label="End Date"
                        minDate={
                          formik.values.startDate
                            ? dayjs(formik.values.startDate)
                            : null
                        }
                        maxDate={
                          formik.values.startDate
                            ? dayjs(formik.values.startDate).add(30, "day")
                            : null
                        }
                        onChange={(newValue) => {
                          formik.setFieldValue("endDate", newValue);
                          formik.errors.endDate = "";
                        }}
                        value={
                          formik.values.endDate
                            ? dayjs(formik.values.endDate)
                            : null
                        }
                        disabled={formik.values.startDate ? false : true}
                        slots={{
                          textField: (textFieldProps) => (
                            <TextField
                              {...textFieldProps}
                              disabled={formik.values.startDate ? false : true}
                              error={formik.errors.endDate ? true : false}
                              helperText={formik.errors.endDate}
                            />
                          ),
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                </Box>
                {/* <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 1.5,
                    marginBottom: "15px",
                  }}
                >
                  <Typography variant="body1">
                    Minimum Number Of Emails
                  </Typography>
                  <Switch
                    {...label}
                    value={formik.values.isMinMailLimit}
                    onChange={(e) => {
                      formik.setFieldValue("isMinMailLimit", e.target.checked);
                      if (!e.target.checked) {
                        formik.values.numberOfDays = "";
                      }
                    }}
                  />
                </Box>
                {formik.values.isMinMailLimit && (
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                      marginBottom: "15px",
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      name="numberOfDays"
                      id="numberOfDays"
                      value={formik.values.numberOfDays}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.numberOfDays = "";
                      }}
                      error={formik.errors.numberOfDays ? true : false}
                      helperText={formik.errors.numberOfDays}
                      sx={{
                        ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                        input: {
                          color: "black",
                        },
                      }}
                      type={"number"}
                      InputProps={{ sx: { bgcolor: "white" } }}
                      placeholder={"Number of Email"}
                    />
                  </Box>
                )} */}

                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{ marginRight: "10px" }}
                    variant="outlined"
                    onClick={handleClose}
                  >
                    <Typography variant="body2">Cancel</Typography>
                  </Button>
                  <LoadingButton
                    loading={isSkeleton}
                    type="submit"
                    loadingPosition="start"
                    startIcon={isSkeleton && <SaveIcon />}
                    variant="contained"
                  >
                    {buttonName}
                  </LoadingButton>
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default CreateCampaign;
