import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import PageRoutes, { APIRoutes, que } from "../../utils/constants";
import theme from "../../Common/style/core/config/theme";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import InfoInput from "../../Common/Components/InfoInput";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Logo from "../../assets/img/AiryisLogo.svg";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },

  sx: {
    ".MuiPaper-root": {
      fontSize: "14px",
      bgcolor: "#072B41",
    },
  },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const Onboard = () => {
  const [quetion, setQuetion] = useState(que[0]);
  const [data, setData] = useState(que);
  const [ansSubmited, setAnswerSubmited] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeData = (e: any, i: any) => {
    setError(false);
    setQuetion({ ...quetion, ans: e.target.value });
  };

  // const handleClose = ()=>{
  //   navigate(PageRoutes.DashBoard);
  // }
  const client = useAxiosPrivate(axios);
  const handleSubmit = async (id: any) => {
    if (!quetion.ans) {
      setError(true);
      return;
    }
    const submitData = data;
    submitData[id - 1] = quetion;
    setIsLoading(true);
    try {
      const res = await client.post(APIRoutes.PostTypeform, {
        Configuration: submitData,
      });
      toast.success(res.data.data.message);
      dispatch(commonDetailSlice.actions.updateONboardFlag());
      setAnswerSubmited(true);
      setIsLoading(false);
      navigate(PageRoutes.StoreSetting);
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleSubmitAnswer = (id: any) => {
    if (quetion.ans == "") {
      setError(true);
      return;
    }

    const updateData = data;
    updateData[id - 1] = quetion;

    setData(updateData);
    setQuetion(updateData[id]);
  };

  const handleBackword = (id: any) => {
    setError(false);
    setQuetion(data[id - 2]);
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setQuetion({
      ...quetion,
      ans: typeof value === "string" ? value.split(",") : value,
    });
  };

  useEffect(() => {
    document.body.classList.add("sidebarhide");
    return () => {
      document.body.classList.remove("sidebarhide");
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          maxWidth: { xs: "200px", md: "500px" },
          display: "flex",
          textAlign: "center",
          m: "auto",
          justifyContent: "center",
          height: "100px",
        }}
      >
        <img src={Logo} width={"100%"} alt="shape" />
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "start",
          px: { xs: 2, lg: 9, md: 5, objectFit: "" },
          py: 5,

          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: 1,
            flexGrow: 1,
            background: "#ffffff0d",
            padding: "30px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ mb: 5 }}>
            {ansSubmited ? (
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgb(0, 0, 0,0.4)",
                  p: 4,
                  borderRadius: "12px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    // color: "#DADADA",
                    color: "#FFFFFF",
                    fontSize: "24px",
                    textAlign: "center",
                  }}
                >
                  Thank you for your input,I am preparing your account now.
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h2">{quetion?.group}</Typography>
                  <Typography variant="h2">
                    {quetion?.id}/{que?.length}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 1,
                    width: 1,
                    py: 2,
                  }}
                >
                  <Box sx={{ width: { xs: 1, md: 0.6 } }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        flexDirection: { xs: "column", sm: "row-reverse" },
                      }}
                    >
                      <Typography sx={{ ml: { xs: 0, sm: 1 } }}>
                        <InfoInput infoText={quetion.infoText} />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: "white",
                          textTransform: "capitalize",
                        }}
                      >
                        {quetion.quetion}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      {quetion.type == "textfeild" ? (
                        <TextField
                          id="standard-multiline-static"
                          placeholder="Answer"
                          onChange={(e) => handleChangeData(e, quetion.id)}
                          // variant="standard"
                          onKeyDown={(e: any) => {
                            if (e.key == "Enter") {
                              que.length == quetion.id && !ansSubmited
                                ? handleSubmit(quetion.id)
                                : handleSubmitAnswer(quetion.id);
                            }
                          }}
                          error={error}
                          helperText={error ? "Field is required" : ""}
                          value={quetion.ans}
                          sx={{
                            width: 1,

                            // background: "white",
                            input: {
                              color: "white",
                            },
                          }}
                        />
                      ) : quetion.type == "textarea" ? (
                        <TextField
                          id="standard-multiline-static"
                          placeholder="Answer"
                          onChange={(e) => handleChangeData(e, quetion.id)}
                          // variant="standard"
                          multiline
                          rows={5}
                          error={error}
                          helperText={error ? "Field is required" : ""}
                          value={quetion.ans}
                          sx={{
                            width: 1,

                            // background: "white",
                            input: {
                              color: "white",
                            },
                          }}
                        />
                      ) : quetion?.type == "select-multiple" ? (
                        <FormControl sx={{ width: 300 }}>
                          <Select
                            id="demo-multiple-chip"
                            multiple
                            value={quetion?.ans || []}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value: any) => (
                                  <Chip
                                    sx={{
                                      textTransform: "capitalize",
                                      color: "#FFFFFF",
                                      bgcolor: "#072B41",
                                    }}
                                    key={value}
                                    label={value}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {quetion?.option.map((name: any) => (
                              <MenuItem
                                sx={{ textTransform: "capitalize" }}
                                key={name}
                                value={name}
                                style={getStyles(name, quetion?.ans, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : quetion?.type == "select-single-id" ? (
                        <FormControl sx={{ width: 300 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={quetion.ans}
                            defaultValue={""}
                            displayEmpty
                            onChange={(e) => handleChangeData(e, quetion.id)}
                            autoWidth
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {quetion?.option.map((ele: any) => (
                              <MenuItem value={ele.id}>{ele.name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : quetion?.type == "select-single" ? (
                        <FormControl sx={{ width: 300 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={quetion.ans}
                            defaultValue={""}
                            displayEmpty
                            onChange={(e) => handleChangeData(e, quetion.id)}
                            autoWidth
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {quetion?.option.map((name: any) => (
                              <MenuItem value={name}>{name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : quetion?.type == "tone" ? (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={quetion.ans}
                            onChange={(e) => handleChangeData(e, quetion.id)}
                            color="red"
                          >
                            {quetion.option.map((el: any, i: number) => (
                              <Tooltip title={el?.tooltip} placement="top">
                                <FormControlLabel
                                  value={el?.itme}
                                  key={i + 1}
                                  control={<Radio />}
                                  label={el?.itme}
                                />
                              </Tooltip>
                            ))}
                          </RadioGroup>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={quetion.ans}
                            onChange={(e) => handleChangeData(e, quetion.id)}
                            color="red"
                          >
                            {quetion.option.map((el: any, i: number) => (
                              <FormControlLabel
                                value={el}
                                key={i + 1}
                                control={<Radio />}
                                label={el}
                              />
                            ))}
                          </RadioGroup>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => handleBackword(quetion.id)}
              sx={{
                display: quetion.id == 1 || ansSubmited ? "none" : "flex",
                ml: 2,
              }}
              variant="contained"
              autoFocus
            >
              Previous
            </Button>
            <Button
              sx={{
                display: quetion.id == que.length ? "none" : "flex",
                ml: 2,
              }}
              onClick={() => handleSubmitAnswer(quetion.id)}
              variant="contained"
              autoFocus
            >
              Next
            </Button>
            {/* <Button
              sx={{
                display:
                  quetion.id == que.length && !ansSubmited ? "flex" : "none",
                ml: 2,
              }}
              onClick={() => handleSubmit(quetion.id)}
              variant="contained"
              autoFocus
            >
              Submit
            </Button> */}
            <LoadingButton
              sx={{
                display:
                  quetion.id == que.length && !ansSubmited ? "flex" : "none",
                ml: 2,
              }}
              onClick={() => handleSubmit(quetion.id)}
              loading={loading}
              type="submit"
              loadingPosition="start"
              startIcon={loading && <SaveIcon />}
              variant="contained"
            >
              Submit
            </LoadingButton>
            {/* {ansSubmited && (
              <Button
                sx={{ ml: 2 }}
                onClick={handleClose}
                variant="contained"
                autoFocus
              >
                Close
              </Button>
            )} */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Onboard;
