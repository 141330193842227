const LoginButton = {
  width: 1,
  color: "common.white",
  background: "linear-gradient(90deg, #664CCD 0%, #8F5DE5 100%)",
  boxShadow: "0px 10px 40px rgba(255, 255, 255, 0.12)",
  borderRadius: "8px",
};
const DrawerBlock = {
  p: 2,
  bgcolor: "#031827",
  height: 1,
};
const HeaderTab = {
  cursor: "pointer",
  fontWeight: "700",
  fontFamily: "SF UI Text",
  fontSize: "14px",
  lineHeight: "22px",
  borderBottom: "1px solid transparent",

  ":after": {
    display: "block",
    content: `""`,
    borderBottom: "solid 3px #0094EC",
    transform: "scaleX(0)",
    transition: " transform 250ms ease-in-out",
  },

  ":hover:after": { transform: " scaleX(1)" },
};

export { DrawerBlock, LoginButton, HeaderTab };
