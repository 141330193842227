import { Typography, Button, Tooltip, Grid, Badge, CircularProgress, useMediaQuery, DialogActions, DialogContentText, DialogContent, IconButton, DialogTitle, Dialog, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import Style from "../../MyCampaigns/MyCampaigns.style";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import EnhancedTable from "../TableComponent";
import ContactDetailsTable from "./ContactDetailsTable";
import { toast, useToast } from "react-toastify";
import { APIRoutes } from "../../../utils/constants";
import axios from "../../../utils/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import notFoundimg from "../../../assets/img/not_found.png";
import ContactDetailPopUp from "../../ContactDetail/ContactDetailPopUp";
import { Close } from "@mui/icons-material";
const ContantDetails: FC = () => {
  
  const client = useAxiosPrivate(axios);
  const [getDetails, setGetDetails] = useState<any>();
  const {id} = useParams();
  const [getId,setGetId] = useState<any>(id);
  const [contactData,setContactData] = useState<any>();
  const [contactlistData,setContactlistData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [contact,setContact] = useState<any>();
  const [open,setOpen] = useState(false);
  const [isAdd,setIsAdd] = useState(false);

  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.ContactDetailGet.replace(":id",getId));
      const res2 = await client.get(APIRoutes.ContactGet);
      setContactData(res.data);
      setContactlistData(res2.data.data.find((id:any)=>{return id.Id==getId}));
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  
  const handleAddContact =async ()=>{
      try {
      const res = await client.post(APIRoutes.ContactDetailAdd,{
        "contactId": Number(getId),
        "contacts" : contact
      });
      setOpen(false);
      handleGet();
      // window.location.reload();
      setContact(null);
      toast.success("Successfully Added");
      // setExpanded(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
  }
  useEffect(() => {
    handleGet();
  }, []);

  useEffect(()=>{
    if(!contact){
      return;
    }
    handleAddContact();
  })
  
  const updateMainAPI = () =>{
    handleGet();
  }

  const formateDate = (strDate:any) =>{
    const date = new Date(strDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} at ${hours}:${minutes} ${amOrPm}`;
  }
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down('sm'));
  const updated = formateDate(contactlistData?.updatedAt);

  return (
    <Box>
      <Box sx={{ ...Style.MyCampaignsHeader, alignItems: "flex-start" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {loading?( <CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />):(
          contactlistData&&<Typography variant="h2">{contactlistData.ContactName}{"'s contact"}</Typography>
        )}
          {/* <Typography variant="body2">
            You have not sent campaign to this list
          </Typography>
          <Typography variant="body2">
            Updated on <strong>{updated}</strong>
            <a href="" style={{ color: "white" }}>
              {" "}
              View activity logs
            </a>
          </Typography>
          <Typography
            variant="body2"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            tzacrfgwy@lists.mailjet.com{" "}
            <Tooltip title="Xyz" placement="top">
              <HelpCenterIcon />
            </Tooltip>{" "}
          </Typography> */}
        </Box>
        <ContactDetailPopUp contact={contact} setContact={setContact}/>
      </Box>
      <Box sx={{ py: 5 }}>
        <Box>
          <Grid container>
            <Grid
              sx={{ border: "1px solid #DADADA" }}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">{contactData && contactData.length}</Typography>
                <Typography variant="body2">Total</Typography>
              </Box>
            </Grid>
            <Grid
              sx={{ border: "1px solid #DADADA" }}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">{contactData && contactData.length}</Typography>
                <Typography variant="body2">Subscribed</Typography>
              </Box>
            </Grid>
            {/* <Grid
              sx={{ border: "1px solid #DADADA" }}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">1</Typography>
                <Typography variant="body2">Unsubscribed</Typography>
              </Box>
            </Grid>
            <Grid
              sx={{ border: "1px solid #DADADA" }}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  py: 3,
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2">1</Typography>
                <Typography variant="body2">Excluded</Typography>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Box>
      {loading?( <CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />):(contactData&& 
          (contactData.length ? 
            (<ContactDetailsTable data={contactData} dataList={contactlistData} updateMainAPI={updateMainAPI}/>):( 
              <Box color="secondary"  sx={{ py:'40px',display: 'flex', justifyContent: 'center', alignItems: 'center',flex: 1,}}>
                <Typography variant="h1">No Data Found </Typography>
              </Box>
            )
          )
        )}  
      </Box>
    </Box>
  );
};
export default ContantDetails;
