const Style = {
  MenuDropDown: {
    overflow: "visible",
    filter: "drop-shadow(0px 1px 3px rgba(0,0,0,0.32))",
    bgcolor: "#072B41",
    minWidth: "200px",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "#072B41",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  menuSelect: {
    ".MuiSelect-select": {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    },
  },
};
export default Style;
