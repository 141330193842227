const BarGraphDown = () => {
    return (
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.046875 1.31937C0.046875 0.789186 0.476681 0.359375 1.00688 0.359375H5.80688C6.33707 0.359375 6.76688 0.789186 6.76688 1.31937V16.6794C6.76688 17.2096 6.33707 17.6394 5.80688 17.6394H1.00687C0.476681 17.6394 0.046875 17.2096 0.046875 16.6794V1.31937ZM16.3669 1.31937C16.3669 0.789186 16.7967 0.359375 17.3269 0.359375H22.1269C22.6571 0.359375 23.0869 0.789186 23.0869 1.31937V12.8394C23.0869 13.3696 22.6571 13.7994 22.1269 13.7994H17.3269C16.7967 13.7994 16.3669 13.3696 16.3669 12.8394V1.31937ZM9.6469 0.359375C9.11668 0.359375 8.68688 0.789186 8.68688 1.31937V8.99936C8.68688 9.52956 9.11668 9.95936 9.6469 9.95936H13.4869C14.0171 9.95936 14.4469 9.52956 14.4469 8.99936V1.31937C14.4469 0.789186 14.0171 0.359375 13.4869 0.359375H9.6469Z"
          fill="white"
        />
      </svg>
    );

}
export default BarGraphDown