import { Box, CircularProgress } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import CampaignViewPopup from "../../Common/Components/Campaign/CampaignViewPopup";
import { useNavigate } from "react-router";

const localizer = momentLocalizer(moment);
const Calender = () => {
  const [calenderData, setCalenderData] = useState([]);
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const client = useAxiosPrivate(axios);
  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.GetCalenderData);
      setCalenderData(res.data.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    handleGet();
  }, []);

  const handleCloseScheduleViewPopup = () => {
    setOpenViewPopup(false);
    setData(null);
  };

  const submitViewPopup = (data) => {
    setOpenViewPopup(false);
    setData(null);
    navigate(PageRoutes.MyCampaignsInnerList.replace(":id", `${data.UserMarketingCampaignId}`))
  }

  return (
    <Box>
      {loading ? (
        <CircularProgress
          size={36}
          sx={{
            color: "#003876",
          }}
        />
      ) : (
        <Calendar
          localizer={localizer}
          onSelectEvent={(event: any) => {
            console.log(event, "event");
            setData(event);
            setOpenViewPopup(true);
          }}
          events={calenderData.map((item: any) => {
            return {
              start: new Date(item.UTCtime),
              end: moment(new Date(item.UTCtime)).add(1, "minute").toDate(),
              title: item?.Subject ? item.Subject : "",
              Id: item.Id,
              Status: item?.Status ? item?.Status : "",
              Subject: item?.Subject ? item?.Subject : "",
              From: item?.From ? item?.From : "",
              Content: item?.Content ? item?.Content : "",
              UserMarketingCampaignId: item?.UserMarketingCampaignId ? item?.UserMarketingCampaignId : ""
            };
          })}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      )}
      {/* {calenderData.length > 0 && (
        <Calendar
          localizer={localizer} 
          onSelectEvent={(event: any) => {
            console.log(event, "event");
            setData(event);
            setOpenViewPopup(true);
          }}
          events={calenderData.map((item: any) => {
            return {
              start: new Date(item.UTCtime),
              end: moment(new Date(item.UTCtime)).add(1, "minute").toDate(),
              title: item?.Name ? item.Name : "",
              Id: item.Id,
              Status: item?.Status ? item?.Status : "",
              Subject: item?.Subject ? item?.Subject : "",
              From: item?.From ? item?.From : "",
              Content: item?.Content ? item?.Content : "",
            };
          })}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
        />
      )} */}
      {openViewPopup && data && (
        <CampaignViewPopup
          description={""}
          title={"View Campaign Details"}
          open={openViewPopup}
          buttonName={"Close"}
          handleCloseScheduleViewPopup={handleCloseScheduleViewPopup}
          submitViewPopup={submitViewPopup}
          data={data}
        />
      )}
    </Box>
  );
};

export default Calender;
