import {
  Typography,
  Button,
  Tab,
  Tabs,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState, useEffect } from "react";
import Style from "./MyCampaigns.style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate, useParams } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { useFormik } from "formik";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import SchedulePopup from "../../Common/Components/Campaign/SchedulePopup";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const StyledTab = styled(Tab)({
  color: "#FFFFFF",
  textTransform: "none",
  padding: 0,

  minHeight: "40px",
});
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyCampaignDetail: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isReplyToAddress, setIsReplyToAddress] = useState(false);
  const [campaign, setCampaign] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubjectLoading, setIsSubjectLoading] = useState(false);
  const [isContactLoading, setIsContactLoading] = useState(false);
  const client = useAxiosPrivate(axios);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [contactlist, setGetContact] = useState<any>([]);

  useEffect(() => {
    handleGet();
    handleContactGet();
  }, []);

  const handleGet = async () => {
    try {
      setIsLoading(true);
      const res = await client.get(
        APIRoutes.GetCampaign.replace(":id", `${params.id}`)
      );
      setCampaign(res.data.data);
      formikName.setValues({
        name: res.data.data.Name,
      });
      formikFrom.setValues({
        name: res.data.data.From,
        email: "",
      });
      formikSubject.setValues({
        subject: res.data.data.Subject,
      });
      if (res.data.data.UserContactId) {
        console.log(res.data.data.UserContactId, "UserContactId");
        formikContact.setValues({
          contactId: res.data.data.UserContactId,
        });
      }

      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleContactGet = async () => {
    try {
      const res = await client.get(APIRoutes.ContactGet);
      setGetContact(res.data.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(panel);
    };

  const handleClose = () => {
    setExpanded(false);
  };

  const validateName = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please fill the required fields";
    }
    // console.log(errors);

    return errors;
  };

  const validateSubject = (values: any) => {
    const errors: any = {};
    if (!values.subject) {
      errors.subject = "Please fill the required fields";
    }
    // console.log(errors);

    return errors;
  };

  const validateFrom = (values: any) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Please fill the required fields";
    }

    if (!values.email) {
      errors.email = "Please fill the required fields";
    }
    // console.log(errors);

    return errors;
  };

  const validateContact = (values: any) => {
    const errors: any = {};
    if (!values.contactId) {
      errors.contactId = "Please fill the required fields";
    }
    // console.log(errors);

    return errors;
  };

  const formikName = useFormik({
    initialValues: {
      name: "",
    },
    validate: validateName,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const res = await client.put(APIRoutes.UpdateCampaign, {
          name: values.name,
          id: params.id,
        });
        toast.success("Successfully Added");
        handleClose();
        // setContactId(res.data.data.Id);
        // setExpanded("panel2");
        // setExpanded(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const formikSubject = useFormik({
    initialValues: {
      subject: "",
    },
    validate: validateSubject,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setIsSubjectLoading(true);
        const res = await client.put(APIRoutes.UpdateCampaign, {
          subject: values.subject,
          id: params.id,
        });
        toast.success("Successfully Added");
        handleClose();
        setCampaign({
          ...campaign,
          Subject: values.subject
        });
        setIsSubjectLoading(false);
        // setContactId(res.data.data.Id);
        // setExpanded("panel2");
        // setExpanded(false);
      } catch (error: any) {
        setIsSubjectLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const formikFrom = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validate: validateFrom,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        const res = await client.put(APIRoutes.UpdateCampaign, {
          from: values.name,
          id: params.id,
        });
        toast.success("Successfully Added");
        handleClose();
        // setContactId(res.data.data.Id);
        // setExpanded("panel2");
        // setExpanded(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const formikContact = useFormik({
    initialValues: {
      contactId: "",
    },
    validate: validateContact,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        setIsContactLoading(true);
        const res = await client.put(APIRoutes.UpdateCampaign, {
          contactId: values.contactId.toString(),
          id: params.id,
        });
        toast.success("Successfully Added");
        handleClose();
        setIsContactLoading(false);
        setCampaign({
          ...campaign,
          UserContactId: values.contactId
        });
        // setContactId(res.data.data.Id);
        // setExpanded("panel2");
        // setExpanded(false);
      } catch (error: any) {
        setIsContactLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const handleCloseSchedulePopup = () => {
    setOpenSchedulePopup(false);
  };

  const submitCloseSchedulePopup = () => {
    handleCloseSchedulePopup();
    navigate(PageRoutes.MyCampaigns);
  };

  const schedulenow = async () => {
    try {
      setIsLoading(true);
      const res = await client.put(APIRoutes.UpdateCampaign, {
        id: params.id,
        UTCtime: new Date().toISOString(),
        status: "Scheduled",
      });
      setIsLoading(false);
      navigate(PageRoutes.MyCampaigns);
    } catch (error: any) {
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box sx={Style.MyCampaignsHeader}>
        <Typography variant="h2">Campaign Details</Typography>
        {/* <Button
          variant="contained"
          onClick={() => {
            navigate(PageRoutes.MyCampaignDetail.replace(":id", "123"));
          }}
        >
          Create a campaign
        </Button> */}
      </Box>
      <Box sx={{ py: { xs: 2, md: 5 } }}>
        <Box sx={{ width: "100%" }}>
          {/* <form onSubmit={formikName.handleSubmit}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={Style.Accordion}
              disabled={!!expanded && expanded !== "panel1"}
            >
              <AccordionSummary
                sx={{
                  ...Style.AccordionSummary,
                  pb: expanded === "panel1" ? 0 : "22px",
                }}
                expandIcon={
                  expanded !== "panel1" && (
                    <Button size="small" variant="contained">
                      Edit name
                    </Button>
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Box
                  sx={{
                    width: "25%",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CheckCircleOutlineIcon />
                  <Typography>FirstName</Typography>
                </Box>
                <Typography
                  sx={{ display: expanded !== "panel1" ? "block" : "none" }}
                >
                  Give your campaign a unique name to reference later
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography>
                    Give your campaign a unique name to reference later
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="Untitled campaign"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    name="name"
                    id="name"
                    value={formikName.values.name}
                    onChange={(e: any) => {
                      formikName.handleChange(e);
                      formikName.errors.name = "";
                    }}
                    error={formikName.errors.name ? true : false}
                    helperText={formikName.errors.name}
                    sx={{
                      minWidth: "50%",
                      my: 2,
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    <Button type="submit" variant="contained" size="small">
                      Save
                    </Button>
                    <Button size="small" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </form> */}
          <form onSubmit={formikSubject.handleSubmit}>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={Style.Accordion}
              disabled={!!expanded && expanded !== "panel2"}
            >
              <AccordionSummary
                sx={{
                  ...Style.AccordionSummary,
                  pb: expanded === "panel2" ? 0 : "22px",
                }}
                expandIcon={
                  expanded !== "panel2" && (
                    <Button size="small" variant="contained">
                      Add subject
                    </Button>
                  )
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Box
                  sx={{
                    width: "25%",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CheckCircleOutlineIcon sx={{
                    color: campaign?.Subject ? 'green' : '#fff'
                  }}/>
                  <Typography>Subject</Typography>
                </Box>
                <Typography
                  sx={{ display: expanded !== "panel2" ? "block" : "none" }}
                >
                  What is the subject line of the campaign?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Typography>
                    What is the subject line of the campaign?
                  </Typography>
                  <TextField
                    size="small"
                    placeholder="No subject"
                    InputProps={{
                      sx: { bgcolor: "#F6F6F6" },
                    }}
                    name="subject"
                    id="subject"
                    value={formikSubject.values.subject}
                    onChange={(e: any) => {
                      formikSubject.handleChange(e);
                      formikSubject.errors.subject = "";
                    }}
                    error={formikSubject.errors.subject ? true : false}
                    helperText={formikSubject.errors.subject}
                    sx={{
                      minWidth: "50%",
                      my: 2,
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                      pointerEvents:
                        campaign &&
                        campaign?.Status != "Draft" &&
                        campaign?.Status != "Scheduled"
                          ? "none"
                          : "unset",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                    }}
                  >
                    {/* <Button type="submit" variant="contained" size="small">
                      Save
                    </Button> */}
                    {campaign &&
                      (campaign?.Status == "Draft" ||
                        campaign?.Status == "Scheduled") && (
                        <LoadingButton
                          loading={isSubjectLoading}
                          type="submit"
                          loadingPosition="start"
                          startIcon={isSubjectLoading && <SaveIcon />}
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      )}

                    <Button size="small" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </form>
          {/* <form onSubmit={formikFrom.handleSubmit}>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={Style.Accordion}
              disabled={!!expanded && expanded !== "panel3"}
            >
              <AccordionSummary
                sx={{
                  ...Style.AccordionSummary,
                  pb: expanded === "panel3" ? 0 : "22px",
                }}
                expandIcon={
                  expanded !== "panel3" && (
                    <Button size="small" variant="contained">
                      Add sender
                    </Button>
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Box
                  sx={{
                    width: "25%",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CheckCircleOutlineIcon />
                  <Typography>From</Typography>
                </Box>
                <Typography
                  sx={{ display: expanded !== "panel3" ? "block" : "none" }}
                >
                  Who is sending the campaign?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: 1,
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: 1, md: 0.49 },
                      }}
                    >
                      <Typography>FirstName</Typography>
                      <TextField
                        size="small"
                        placeholder="FirstName"
                        InputProps={{
                          sx: { bgcolor: "#F6F6F6" },
                        }}
                        name="name"
                        id="name"
                        value={formikFrom.values.name}
                        onChange={(e: any) => {
                          formikFrom.handleChange(e);
                          formikFrom.errors.name = "";
                        }}
                        error={formikFrom.errors.name ? true : false}
                        helperText={formikFrom.errors.name}
                        sx={{
                          minWidth: "50%",
                          my: 2,
                          ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                          input: {
                            color: "black",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: { xs: 1, md: 0.49 },
                      }}
                    >
                      <Typography>Email address</Typography>
                      <TextField
                        size="small"
                        placeholder="Email"
                        InputProps={{
                          sx: { bgcolor: "#F6F6F6" },
                        }}
                        name="email"
                        id="email"
                        value={formikFrom.values.email}
                        onChange={(e: any) => {
                          formikFrom.handleChange(e);
                          formikFrom.errors.email = "";
                        }}
                        error={formikFrom.errors.email ? true : false}
                        helperText={formikFrom.errors.email}
                        sx={{
                          minWidth: "50%",
                          my: 2,
                          ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                          input: {
                            color: "black",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        width: "max-content",
                      }}
                      onClick={() => {
                        setIsReplyToAddress(!isReplyToAddress);
                      }}
                    >
                      Reply-to address
                    </Typography>
                    {isReplyToAddress && (
                      <Box>
                        <TextField
                          size="small"
                          InputProps={{
                            sx: { bgcolor: "#F6F6F6" },
                          }}
                          sx={{
                            minWidth: "100%",
                            my: 2,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      mt: 1,
                    }}
                  >
                    <Button type="submit" variant="contained" size="small">
                      Save
                    </Button>
                    <Button size="small" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </form> */}
          <Accordion
            // expanded={expanded === "panel4"}
            // onChange={handleChange("panel4")}
            sx={Style.Accordion}
            disabled={(!!expanded && expanded !== "panel4")}
          >
            <AccordionSummary
              sx={{
                ...Style.AccordionSummary,
                pb: expanded === "panel4" ? 0 : "22px",
                pointerEvents: 'none'
              }}
              expandIcon={
                <Button
                sx={{
                  pointerEvents: 'auto'
                }}
                  disabled={
                    campaign &&
                    campaign?.Status != "Draft" &&
                    campaign?.Status != "Scheduled"
                  }
                  type="button"
                  onClick={() => {
                    navigate(
                      PageRoutes.EmailEditor.replace(
                        ":id",
                        params.id ? params.id : ""
                      ) + `?type=schedule`
                    );
                  }}
                  size="small"
                  variant="contained"
                >
                  Design email
                </Button>
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Box
                sx={{
                  width: "25%",
                  flexShrink: 0,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CheckCircleOutlineIcon sx={{
                    color: campaign?.Template ? 'green' : '#fff'
                  }}/>
                <Typography>Content</Typography>
              </Box>
              <Typography
                sx={{ display: expanded !== "panel4" ? "block" : "none" }}
              >
                What is the campaign content?
              </Typography>
            </AccordionSummary>
          </Accordion>
          <form onSubmit={formikContact.handleSubmit}>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={Style.Accordion}
              disabled={!!expanded && expanded !== "panel5"}
            >
              <AccordionSummary
                sx={{
                  ...Style.AccordionSummary,
                  pb: expanded === "panel5" ? 0 : "22px",
                }}
                expandIcon={
                  expanded !== "panel5" && (
                    <Button size="small" variant="contained">
                      Select recipients
                    </Button>
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Box
                  sx={{
                    width: "25%",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <CheckCircleOutlineIcon sx={{
                    color: campaign?.UserContactId ? 'green' : '#fff'
                  }}/>
                  <Typography>Contact List</Typography>
                </Box>
                <Typography
                  sx={{ display: expanded !== "panel5" ? "block" : "none" }}
                >
                  Who will this campaign be sent to?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: 1,
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: 1, md: 0.49 },
                      }}
                    >
                      <Typography sx={{ marginBottom: "10px" }}>
                        Select Contact
                      </Typography>
                      <Select
                        value={formikContact.values.contactId}
                        id="contactId"
                        name="contactId"
                        size="medium"
                        displayEmpty
                        onChange={(e: any) => {
                          formikContact.handleChange(e);
                          formikContact.errors.contactId = "";
                        }}
                        sx={{
                          ".MuiSelect-select": {
                            py: 1,
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            width: 300,
                            pointerEvents:
                              campaign &&
                              campaign?.Status != "Draft" &&
                              campaign?.Status != "Scheduled"
                                ? "none"
                                : "unset",
                          },
                        }}
                        error={formikContact.errors.contactId ? true : false}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          sx: {
                            ".MuiPaper-root": {
                              bgcolor: "#072B41",
                            },
                          },
                        }}
                      >
                        {contactlist.length > 0 &&
                          contactlist.map((el) => (
                            <MenuItem
                              sx={{
                                fontSize: "14px",
                              }}
                              value={el.Id}
                            >
                              {el.ContactName}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    {/* <Button type="submit" variant="contained" size="small">
                      Save
                    </Button> */}
                    {campaign &&
                      (campaign?.Status == "Draft" ||
                        campaign?.Status == "Scheduled") && (
                        <LoadingButton
                          loading={isContactLoading}
                          type="submit"
                          loadingPosition="start"
                          startIcon={isContactLoading && <SaveIcon />}
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      )}

                    <Button size="small" onClick={() => handleClose()}>
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </form>
        </Box>
      </Box>
      {campaign && campaign?.Status == "Draft" && (
        <Box
          sx={{
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={(campaign && (!campaign.UserContactId || !campaign.Subject))}
            type="button"
            sx={{ marginRight: "10px" }}
            variant="outlined"
            onClick={() => {
              setOpenSchedulePopup(true);
            }}
          >
            <Typography variant="body2">Schedule</Typography>
          </Button>
          <LoadingButton
            disabled={(campaign && (!campaign.UserContactId || !campaign.Subject))}
            loading={isLoading}
            type="button"
            loadingPosition="start"
            startIcon={isLoading && <SaveIcon />}
            variant="contained"
            onClick={schedulenow}
          >
            Send Now
          </LoadingButton>
        </Box>
      )}
      {openSchedulePopup && (
        <SchedulePopup
          description={""}
          title={"When would you like to send this campaign?"}
          open={openSchedulePopup}
          buttonName={"Schedule"}
          handleCloseSchedulePopup={handleCloseSchedulePopup}
          submitCloseSchedulePopup={submitCloseSchedulePopup}
          id={params.id}
          campaign={campaign}
        />
      )}
    </Box>
  );
};
export default MyCampaignDetail;
