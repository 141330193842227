import { Settings } from "@mui/icons-material";
import {
  TableCell,
  Checkbox,
  Box,
  Rating,
  Typography,
  Chip,
  IconButton,
  TableRow,
  Avatar,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { FC, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Style from "../../../../Common/Components/HeaderPrivateRoute/HeaderPrivateRoute.style";
import Popup from "../../../../Common/Components/PopupMenu/Popup";
import { useNavigate } from "react-router";
import PageRoutes from "../../../../utils/constants";

interface TableProps {
  data: any;
  labelId: any;
  isItemSelected: any;
  handleClick: any;
  updateMainAPI: any;
}
const StyleMenuItem = styled(MenuItem)({
  fontSize: "14px",
});
const TableRowData: FC<TableProps> = (props) => {
  const { data, labelId, isItemSelected, handleClick, updateMainAPI }: any = props;
  const [openColleps, setOpeColleps] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitForm = (value: any) => {
    console.log(value);
    updateMainAPI();
  };

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={data.Name}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      {/* <TableCell onClick={(event) => handleClick(event)} padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell> */}
      {/* <TableCell component="th" id={labelId} scope="row" sx={{ px: 1 }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Rating
              emptyIcon={
                <StarBorderIcon fontSize="inherit" sx={{ color: "#FFFFFF" }} />
              }
              max={1}
            />
          </Box>
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{ textDecoration: "underline" }}
              variant="body2"
              component={"a"}
            >
              {data?.Name ? data.Name : "Untitled campaign"}
            </Typography>
            <Typography variant="body2" component={"a"}>
              Untitled campaign
            </Typography>
          </Box>
        </Box>
      </TableCell> */}
      <TableCell align="left">
        <Typography
              sx={{ textDecoration: "underline" }}
              variant="body2"
              component={"a"}
              onClick={() =>
                navigate(
                  PageRoutes.MyCampaignDetail.replace(":id", `${data.Id}`)
                )
              }
            >
              {data?.Subject ? data?.Subject: ''}
            </Typography>
        </TableCell>

      <TableCell align="left">
        {" "}
        <Chip sx={{ bgcolor: "#CCCCCC", height: "24px" }} label={data.Status} />
      </TableCell>
      <TableCell align="left">{data?.UserMarketingCampaignAnalytic?.Click ? data.UserMarketingCampaignAnalytic.Click : 0}</TableCell>
      <TableCell align="left">{data?.UserMarketingCampaignAnalytic?.Open ? data.UserMarketingCampaignAnalytic.Open : 0}</TableCell>
      {/* <TableCell align="left"> -</TableCell>
      <TableCell align="left"> -</TableCell> */}
      <TableCell align="right">
        {(data?.Status == "Draft" || data?.Status == "Scheduled") && <IconButton onClick={handleClickMenu}>
          <Settings sx={{ color: "#FFFFFF" }} />
        </IconButton>}
        <Menu
          id="header-menu"
          aria-labelledby="menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: Style.MenuDropDown,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <StyleMenuItem onClick={() => {
              navigate(
                PageRoutes.MyCampaignDetail.replace(":id", `${data.Id}`)
              )
          }}>Edit</StyleMenuItem>
          {/* <StyleMenuItem onClick={handleClose}>Priview</StyleMenuItem>
          <StyleMenuItem onClick={handleClose}>Duplicate</StyleMenuItem> */}

          <Popup
            title="Delete"
            data={data}
            buttonName="Delete"
            discription="Are you want to delete this? this process can't be undo."
            type="InnerCampaign"
            submitForm={submitForm}
          />
          {/* <Popup
            title="Archive campaign"
            buttonName="Archive"
            discription="Archived campaigns appear in the Archived Folder and are hidden from other lists. You can restore an archived campaign to its original status at any time."
          />

          <StyleMenuItem onClick={handleClose}>View activity log</StyleMenuItem> */}
        </Menu>
      </TableCell>
    </TableRow>
  );
};
export default TableRowData;
