export const mockBarData = [
    {
      country: "AD",
      "hot dog": 137,
      "hot dogColor": "hsl(229, 70%, 50%)",
      burger: 96,
      burgerColor: "hsl(296, 70%, 50%)",
      kebab: 72,
      kebabColor: "hsl(97, 70%, 50%)",
      donut: 140,
      donutColor: "hsl(340, 70%, 50%)",
    },
    {
      country: "AE",
      "hot dog": 55,
      "hot dogColor": "hsl(307, 70%, 50%)",
      burger: 28,
      burgerColor: "hsl(111, 70%, 50%)",
      kebab: 58,
      kebabColor: "hsl(273, 70%, 50%)",
      donut: 29,
      donutColor: "hsl(275, 70%, 50%)",
    },
    {
      country: "AF",
      "hot dog": 109,
      "hot dogColor": "hsl(72, 70%, 50%)",
      burger: 23,
      burgerColor: "hsl(96, 70%, 50%)",
      kebab: 34,
      kebabColor: "hsl(106, 70%, 50%)",
      donut: 152,
      donutColor: "hsl(256, 70%, 50%)",
    },
    {
      country: "AG",
      "hot dog": 133,
      "hot dogColor": "hsl(257, 70%, 50%)",
      burger: 52,
      burgerColor: "hsl(326, 70%, 50%)",
      kebab: 43,
      kebabColor: "hsl(110, 70%, 50%)",
      donut: 83,
      donutColor: "hsl(9, 70%, 50%)",
    },
    {
      country: "AI",
      "hot dog": 81,
      "hot dogColor": "hsl(190, 70%, 50%)",
      burger: 80,
      burgerColor: "hsl(325, 70%, 50%)",
      kebab: 112,
      kebabColor: "hsl(54, 70%, 50%)",
      donut: 35,
      donutColor: "hsl(285, 70%, 50%)",
    },
    {
      country: "AL",
      "hot dog": 66,
      "hot dogColor": "hsl(208, 70%, 50%)",
      burger: 111,
      burgerColor: "hsl(334, 70%, 50%)",
      kebab: 167,
      kebabColor: "hsl(182, 70%, 50%)",
      donut: 18,
      donutColor: "hsl(76, 70%, 50%)",
    },
    {
      country: "AM",
      "hot dog": 80,
      "hot dogColor": "hsl(87, 70%, 50%)",
      burger: 47,
      burgerColor: "hsl(141, 70%, 50%)",
      kebab: 158,
      kebabColor: "hsl(224, 70%, 50%)",
      donut: 49,
      donutColor: "hsl(274, 70%, 50%)",
    },
  ];
  
  export const mockPieData = [
    {
      id: "hack",
      label: "hack",
      value: 239,
      color: "hsl(104, 70%, 50%)",
    },
    {
      id: "make",
      label: "make",
      value: 170,
      color: "hsl(162, 70%, 50%)",
    },
    {
      id: "go",
      label: "go",
      value: 322,
      color: "hsl(291, 70%, 50%)",
    },
    {
      id: "lisp",
      label: "lisp",
      value: 503,
      color: "hsl(229, 70%, 50%)",
    },
    {
      id: "scala",
      label: "scala",
      value: 584,
      color: "hsl(344, 70%, 50%)",
    },
  ];

  export const mockLineData = [
    {
      id: "japan",
      color: "#4cceac",
      data: [
        {
          x: "plane",
          y: 101,
        },
        {
          x: "helicopter",
          y: 75,
        },
        {
          x: "boat",
          y: 36,
        },
        {
          x: "train",
          y: 216,
        },
        {
          x: "subway",
          y: 35,
        },
        {
          x: "bus",
          y: 236,
        },
        {
          x: "car",
          y: 88,
        },
        {
          x: "moto",
          y: 232,
        },
        {
          x: "bicycle",
          y: 281,
        },
        {
          x: "horse",
          y: 1,
        },
        {
          x: "skateboard",
          y: 35,
        },
        {
          x: "others",
          y: 14,
        },
      ],
    },
    {
      id: "france",
      color: "#3e4396",
      data: [
        {
          x: "plane",
          y: 212,
        },
        {
          x: "helicopter",
          y: 190,
        },
        {
          x: "boat",
          y: 270,
        },
        {
          x: "train",
          y: 9,
        },
        {
          x: "subway",
          y: 75,
        },
        {
          x: "bus",
          y: 175,
        },
        {
          x: "car",
          y: 33,
        },
        {
          x: "moto",
          y: 189,
        },
        {
          x: "bicycle",
          y: 97,
        },
        {
          x: "horse",
          y: 87,
        },
        {
          x: "skateboard",
          y: 299,
        },
        {
          x: "others",
          y: 251,
        },
      ],
    },
    {
      id: "us",
      color: "#f1b9b7",
      data: [
        {
          x: "plane",
          y: 191,
        },
        {
          x: "helicopter",
          y: 136,
        },
        {
          x: "boat",
          y: 91,
        },
        {
          x: "train",
          y: 190,
        },
        {
          x: "subway",
          y: 211,
        },
        {
          x: "bus",
          y: 152,
        },
        {
          x: "car",
          y: 189,
        },
        {
          x: "moto",
          y: 152,
        },
        {
          x: "bicycle",
          y: 8,
        },
        {
          x: "horse",
          y: 197,
        },
        {
          x: "skateboard",
          y: 107,
        },
        {
          x: "others",
          y: 170,
        },
      ],
    },
  ];