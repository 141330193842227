import { useEffect, useState, useRef } from "react";
import {
  Typography,
  TextField,
  Button,
  Divider,
  CircularProgress,
  IconButton,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { APIRoutes, TimeZoneData } from "../../../utils/constants";
import Style from "../../MyCampaigns/MyCampaigns.style";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import { PhotoCamera } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
const StoreSetting = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    sx: {
      ".MuiPaper-root": {
        fontSize: "14px",
        bgcolor: "#072B41",
      },
    },
  };

  const [primaryColor, setPrimaryColor] = useState("#000000");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [id, setId] = useState<any>();
  const [config, setConfig] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fb, setFb] = useState("");
  const [insta, setInsta] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
  // const [twitch, setTwitch] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [tumblr, setTumblr] = useState("");
  const [telegram, setTelegram] = useState("");
  const [selectImage, setSelectImage] = useState<any>(null);
  const inputFileRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const client = useAxiosPrivate(axios);
  const [socialLinks, setSocialLinks] = useState({});
  const [newSocialLink, setNewSocialLink] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newSocialMedia, setNewSocialMedia] = useState("");
  const [saveloading, setSaveLoading] = useState(false);
  const handleAddInput = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogAdd = () => {
    if (newSocialMedia && newSocialLink) {
      setSocialLinks({
        ...socialLinks,
        [newSocialMedia]: newSocialLink, // Initialize link as empty string
      });
      setNewSocialMedia("");
      setNewSocialLink("");
      setDialogOpen(false);
    }
  };

  const handleInputChange = (socialMedia, value) => {
    setSocialLinks({
      ...socialLinks,
      [socialMedia]: value,
    });
  };
  const handleGetContactData = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.PostformData);
      setId(res.data.data?.Id);
      setConfig(res.data.data.Configuration);
      if (res.data.data.UserSetting && res.data.data.UserSetting.length > 0) {
        setPrimaryColor(res.data.data.UserSetting[0].primaryColor);
        setSecondaryColor(res.data.data.UserSetting[0].secondaryColor);
        setFb(
          res.data.data.UserSetting[0].Facebook
            ? res.data.data.UserSetting[0].Facebook
            : ""
        );
        setInsta(
          res.data.data.UserSetting[0].Instagram
            ? res.data.data.UserSetting[0].Instagram
            : ""
        );
        setTwitter(
          res.data.data.UserSetting[0].Twitter
            ? res.data.data.UserSetting[0].Twitter
            : ""
        );
        setYoutube(
          res.data.data.UserSetting[0].Youtube
            ? res.data.data.UserSetting[0].Youtube
            : ""
        );
        setSelectImage(res.data.data.UserSetting[0].logo);
        setTiktok(
          res.data.data.UserSetting[0].Tiktok
            ? res.data.data.UserSetting[0].Tiktok
            : ""
        );
        setLinkedin(
          res.data.data.UserSetting[0].LinkedIn
            ? res.data.data.UserSetting[0].LinkedIn
            : ""
        );
        setPinterest(
          res.data.data.UserSetting[0].Pinterest
            ? res.data.data.UserSetting[0].Pinterest
            : ""
        );
        // setTwitch(res.data.data.UserSetting[0].Twitch ? res.data.data.UserSetting[0].Twitch : '');
        setSnapchat(
          res.data.data.UserSetting[0].Snapchat
            ? res.data.data.UserSetting[0].Snapchat
            : ""
        );
        setTumblr(
          res.data.data.UserSetting[0].Tumblr
            ? res.data.data.UserSetting[0].Tumblr
            : ""
        );
        setTelegram(
          res.data.data.UserSetting[0].Telegram
            ? res.data.data.UserSetting[0].Telegram
            : ""
        );
        // const otherSettings = { ...res.data.data.UserSetting[0] };
        // delete otherSettings.logo;
        // delete otherSettings.primaryColor;
        // delete otherSettings.secondaryColor;
        // delete otherSettings.Facebook;
        // delete otherSettings.Instagram;
        // delete otherSettings.Twitter;
        // delete otherSettings.Youtube;
        // setSocialLinks(otherSettings);
      }
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetContactData();
  }, []);

  const hanldeAddList = async () => {
    // if (fb == "" && insta == "" && youtube == "" && twitter == "" && tiktok == "" && linkedin == "" && pinterest == "" && snapchat == "" && tumblr == "" && telegram == "") {
    //   toast.error("Please add any one of your social media accounts.");
    //   return;
    // }
    setSaveLoading(true);
    const filteredSocialLinks = Object.fromEntries(
      Object.entries(socialLinks).filter(
        ([key, value]) => value !== null && value !== ""
      )
    );
    console.log(filteredSocialLinks, "filter");
    const list = {
      logo: selectImage ?? "",
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      Facebook: fb,
      Instagram: insta,
      Twitter: twitter,
      Youtube: youtube,
      Tiktok: tiktok,
      LinkedIn: linkedin,
      Pinterest: pinterest,
      // Twitch: twitch,
      Snapchat: snapchat,
      Tumblr: tumblr,
      Telegram: telegram,
      // ...filteredSocialLinks,
    };
    console.log(list);
    try {
      const res = await client.put(
        APIRoutes.PostformUpdate.replace(":id", id),
        {
          Configuration: config,
          UserSetting: [list],
        }
      );

      toast.success("Successfully Saved");
      setSaveLoading(false);
    } catch (error: any) {
      setSaveLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleFileChange = async (event) => {
    if (!event.target.files[0]) {
      toast("Please Select Image");
      return;
    }
    try {
      const formData = new FormData();

      formData.append("files", event.target.files[0]);
      setIsLoading(true);
      const res2 = await client.post(APIRoutes.UploadCampaignsImage, formData);
      setSelectImage(res2.data.Location);
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.MyCampaignsHeader}>
          <Typography variant="h2">Email Settings</Typography>
          {loading && (
            <CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />
          )}
        </Box>
      </Box>

      <Divider sx={{ borderColor: "#cccccc", pt: 4 }} />
      <Box>
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Email Logo
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Please select your logo
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <Button
              onClick={() => inputFileRef.current.click()}
              variant="contained"
            >
              <input
                accept="image/*"
                hidden
                ref={inputFileRef}
                id="raised-button-file"
                onChange={handleFileChange}
                type="file"
              />
              Upload
            </Button> */}
            <IconButton
              sx={{
                width: "64px",
                height: "64px",
                borderRadius: '0px',
                backgroundColor: selectImage ? "transparent" : "#D9D9D9",
                p: 0,
                "&:hover": {
                  opacity: "0.7",
                },
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                accept="image/*"
                hidden
                id="raised-button-file"
                onChange={handleFileChange}
                type="file"
              />
              {isLoading ? (
                <CircularProgress
                  size={36}
                  sx={{
                    color: "#003876",
                  }}
                />
              ) : selectImage ? (
                <img
                  width={"100%"}
                  height="100%"
                  style={{ objectFit: "cover" }}
                  src={selectImage}
                  alt=""
                />
              ) : (
                <PhotoCamera sx={{ color: "grey" }} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              Brand Color
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Primary Color :
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Secondary Color :
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <input
                type="color"
                style={{ width: "70px", border: "none" }}
                value={primaryColor}
                onChange={(e) => {
                  setPrimaryColor(e.target.value);
                }}
              />
              <br />
              <br />
              <input
                type="color"
                style={{ width: "70px", border: "none" }}
                value={secondaryColor}
                onChange={(e) => {
                  setSecondaryColor(e.target.value);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Social Media List (Select all that apply)
            </Typography>
            {/* <Select
              size="small"
              displayEmpty
              multiple
              value={social}
              onChange={handleSelectChange}
              sx={{
                ".MuiSelect-select": {
                  py: 1,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                sx: {
                  ".MuiPaper-root": {
                    fontSize: "14px",
                    bgcolor: "#072B41",
                  },
                },
              }}
            >
              <MenuItem sx={{fontSize: "14px",}}value="Facebook">Facebook</MenuItem>
              <MenuItem sx={{fontSize: "14px",}}value="Instagram">Instagram</MenuItem>
              <MenuItem sx={{fontSize: "14px",}}value="Twitter">Twitter</MenuItem>
              <MenuItem sx={{fontSize: "14px",}}value="Printest">Printest</MenuItem>
              <MenuItem sx={{fontSize: "14px",}}value="Youtube">Youtube</MenuItem>
            </Select> */}
            {/* <FormControl>
                <Select
                fullWidth
                id="demo-multiple-chip"
                multiple
                value={social || []}
                onChange={handleSelectChange}
                input={<OutlinedInput/>}
                renderValue={(selected) => (
                    <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                    }}
                    >
                    {selected.map((value: any) => (
                        <Chip
                        sx={{
                            color: "#FFFFFF",
                            bgcolor: "#072B41",
                        }}
                        key={value}
                        label={value}
                        />
                    ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                >
                {socailList.map((name: any) => (
                    <MenuItem
                    key={name}
                    value={name}
                    >
                    {name}
                    </MenuItem>
                ))}
                </Select>
                {/* <FormHelperText
                sx={{ color: "error.main" }}
                id="component-error-text"
                >
                {error && "Please Select Option"}
                </FormHelperText> 
            </FormControl> */}
            <Grid container spacing={2}>
              {/* Row 1: 3 TextFields */}
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={fb}
                  onChange={(e: any) => {
                    setFb(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Facebook Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={twitter}
                  onChange={(e: any) => {
                    setTwitter(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Twitter Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={insta}
                  onChange={(e: any) => {
                    setInsta(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Instagram Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={youtube}
                  onChange={(e: any) => {
                    setYoutube(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Youtube Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={tiktok}
                  onChange={(e) => {
                    setTiktok(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"TikTok Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={linkedin}
                  onChange={(e) => {
                    setLinkedin(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"LinkedIn Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={pinterest}
                  onChange={(e) => {
                    setPinterest(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Pinterest Link"}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={twitch}
                  onChange={(e) => {
                    setTwitch(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Twitch Link"}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={snapchat}
                  onChange={(e) => {
                    setSnapchat(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Snapchat Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={tumblr}
                  onChange={(e) => {
                    setTumblr(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Tumblr Link"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  name="last_name"
                  id="last_name"
                  value={telegram}
                  onChange={(e) => {
                    setTelegram(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Telegram Link"}
                />
              </Grid>
            </Grid>
            {Object.entries(socialLinks).map(([socialMedia, link], index) => (
              <div key={index}>
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type="text"
                  value={link}
                  onChange={(e) =>
                    handleInputChange(socialMedia, e.target.value)
                  }
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={`${socialMedia}.com`}
                />
              </div>
            ))}

            {/* <Button variant="outlined" onClick={handleAddInput}>
              Add Social Media Link
            </Button> */}

            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              sx={{
                ".MuiPaper-root": {
                  bgcolor: "common.blue",
                  minWidth: "350px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "22px",
                }}
              >
                <Typography variant="h3">Add Social Media</Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  required
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={newSocialMedia}
                  onChange={(e) => setNewSocialMedia(e.target.value)}
                  sx={{
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  type={"text"}
                  InputProps={{ sx: { bgcolor: "white" } }}
                  placeholder="Social Media Name"
                />
                <TextField
                  required
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type="text"
                  value={newSocialLink}
                  onChange={(e) => {
                    setNewSocialLink(e.target.value);
                  }}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={`Social Media Link `}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleDialogClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleDialogAdd}
                  color="primary"
                >
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        <Divider sx={{ borderColor: "#cccccc" }} />
        <Box
          sx={{
            py: 4,
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          {" "}
          <Box>
            {/* <Button variant="contained" onClick={hanldeAddList}>
              Save
            </Button> */}
            <LoadingButton
              loading={saveloading}
              type="button"
              loadingPosition="start"
              startIcon={saveloading && <SaveIcon />}
              variant="contained"
              onClick={hanldeAddList}
            >
              Save
            </LoadingButton>
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default StoreSetting;
