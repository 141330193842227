import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { APIRoutes } from "../../../utils/constants";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import axios from "../../../utils/axios";
import OnboardBox from "../../../Common/Components/OnbordBox";
import { error, log } from "console";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

type questionTypes = {
    id: number;
    ans: string;
    quetion: string;
    group:string;
    type:string;
    option:[];
}


const UpdateOnboard = () => {
  const [que,setQue] = useState<questionTypes[]>([]);
  const [question, setQuestion] = useState<any>();
  const [data, setData] = useState<questionTypes[]>([]);
  const [error, setError] = useState(false);
  const [preferenceId,setPreferenceId] = useState<any>(0);
  const client = useAxiosPrivate(axios);
  const [loading, setLoading] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(false);
  
    const handleGetData =async ()=>{
        try {
            setLoading(true);
            const res = await client.get(APIRoutes.PostformData);
            setQue(res.data.data?.Configuration);
            setPreferenceId(res.data.data?.Id);
            setData(res.data.data?.Configuration);
            setQuestion(res.data.data?.Configuration[0])
            setLoading(false);
            toast.success(res.data.data.message);            
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setLoading(false);
        }
    }
        
  useEffect(()=>{
    handleGetData();
  },[])
  
  const handleUpdate = async()=>{
    const updateData = data;
    setData(updateData);
    if(error){
        toast.error("Please fill all the answers!");
        return 
    }
    try {
        setIsSkeleton(true);
        const res = await client.put(APIRoutes.PostformUpdate.replace(":id",preferenceId), {
          Configuration : data
        });
        toast.success("Successfully Updated");
        setIsSkeleton(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setIsSkeleton(false);
      }
}
  return (
    <Box>
      <Box sx={{px:{xs:"0",sm:"34px",md:"72px"},display:"flex",gap:"15px",flexDirection:"column"}}>
        {loading? (<CircularProgress
              size={36}
              sx={{
                color: "#003876",
              }}
            />):(
            que && que.map((ele,indx)=>(<OnboardBox id={ele.id} ans={ele.ans} group={ele.group} que={ele.quetion} queType={ele.type} option={ele.option} data={data} setData={setData} setError={setError} key={indx}/>))
        )}
            <Box>
                {que && que.length > 0 && <LoadingButton loading={isSkeleton} loadingPosition="start" startIcon={isSkeleton && <SaveIcon />} variant="contained" onClick={handleUpdate}>Update Question</LoadingButton>}
            </Box>
        </Box>
    </Box>
  )
}

export default UpdateOnboard