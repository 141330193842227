import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import Style from "../Login/Login.style";
import AiryisLogo from "../../assets/img/AiryisLogo.svg";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "../../utils/axios";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
const ForgetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validate = (values: any) => {
    const errors: any = {};

    const passwordRegx = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`=+ -])(?=.{8,})[^\s]+$/
    );

    if (!values.password) {
      errors.password = "Please fill the required fields";
    } else if (!passwordRegx.test(values.password)) {
      errors.password =
        "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
    }

    return errors;
  };
  const formik = useFormik({
    initialValues: { password: "", confirm_password: "" },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }
      try {
        setIsSkeleton(true);
        const res: any = await client.post(APIRoutes.ResetPassword, {
          token: searchParams.get("token"),
          password: values.password,
        });
        if (res) {
          toast.success("Password reset successfully");
          navigate(PageRoutes.Login);
          setIsSkeleton(false);
        }
        setIsSkeleton(false);
      } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.message);

        setIsSuccess(false);
        setIsSkeleton(false);
      }
    },
  });
  return (
    <Box sx={{ minHeight: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={Style.LoginBox}>
            <Box sx={{ ...Style.LoginLeft, width: 1 }}>
              <Box>
                <img src={AiryisLogo} width="100%" />
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <TextField
                    size="small"
                    fullWidth
                    InputProps={{
                      sx: { bgcolor: "white" },
                    }}
                    type={"password"}
                    value={formik.values.password}
                    name="password"
                    id="password"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.password = "";
                    }}
                    error={formik.errors.password ? true : false}
                    helperText={formik.errors.password}
                    sx={{
                      my: 1,
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    placeholder={"Password"}
                  />
                  <TextField
                    value={formik.values.confirm_password}
                    size="small"
                    fullWidth
                    name="confirm_password"
                    id="confirm_password"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      formik.errors.confirm_password = "";
                      setConfirmPasswordError("");
                    }}
                    onBlur={(e: any) => {
                      if (formik.values.password) {
                        if (formik.values.password != e.target.value) {
                          setConfirmPasswordError(
                            "Confirm password do not match with entered password"
                          );
                        } else {
                          setConfirmPasswordError("");
                        }
                      }
                    }}
                    error={
                      formik.errors.confirm_password || confirmPasswordError
                        ? true
                        : false
                    }
                    helperText={
                      formik.errors.confirm_password || confirmPasswordError
                    }
                    InputProps={{
                      sx: { bgcolor: "white" },
                    }}
                    type={"text"}
                    sx={{
                      my: 1,
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    placeholder={"Confirm Password"}
                  />
                  <LoadingButton
                    loading={isSkeleton}
                    type="submit"
                    loadingPosition="start"
                    startIcon={isSkeleton && <SaveIcon />}
                    variant="contained"
                  >
                    Reset
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ForgetPassword;
