import { SxProps } from "@mui/material";
import BgImage from "../../assets/img/SIGNUP.png";
const Style: { [key: string]: SxProps } = {
  MyCampaignsHeader: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
};
export default Style;
