import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Avatar,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import Logo from "../../assets/img/AiryisLogo.svg";
import BgImageHomePage from "../../assets/img/BgImageHomePage.jpg";
import CommonStyle from "../../Common/style/Style";
import DividerImg from "../../assets/img/DividerImage.svg";
import LightImg from "../../assets/img/Light.png";
import "./index.css";
import SideImage from "../../assets/img/SideImage.png";
import Applist from "../../assets/img/apps-group-768x366.png.svg";
import SideImageLeft from "../../assets/img/SideImageLeft.png";
import EnterPrice from "../../assets/img/leaderEnterPrice.svg";
import MidMarket from "../../assets/img/MidMarket.svg";
import SmallBusiness from "../../assets/img/SmallBusiness.svg";
import SmallBusinessBest from "../../assets/img/SmallBusinessBest.svg";
import EnterpriseBest from "../../assets/img/EnterpriseBest.svg";
import AsiaPacific from "../../assets/img/AsiaPacific.svg";
import Winter from "../../assets/img/Winter.svg";
import CheckIcon from "@mui/icons-material/Check";
import { GraphicEq, People } from "@mui/icons-material";
import Graph from "../../assets/icons/Graph";
import BarGraph from "../../assets/icons/BarGraph";
import RoundPlus from "../../assets/icons/RoundPlus";
import BarGraphDown from "../../assets/icons/BarGraphDown";
import Branch from "../../assets/icons/Branch";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import WaitListComponent from "../../Common/Components/WaitListComponent";
import { useNavigate } from "react-router-dom";
import PageRoutes from "../../utils/constants";
const HomePage: FC = () => {
  const staticData = [
    {
      id: 1,
      headtitle: "High Impact Low Cost",
      title: "Cost-Effective",
      subtitle: `I can significanlty reduce your costs compared to hiring a human powered marketing company, providing high-quality services without the associated salary, benefits, and overhead expenses.`,
      para: `Compared to traditional marketing firms, my AI-powered services can provide a remarkable potential savings of 5,834%.`,
      option: [
        "Pay For What You Need",
        "Effciency Savings",
        "Lower Overhead",
        "Improved ROI",
      ],
      buttonText: "JOIN WAITLIST",
    },
    {
      id: 2,
      headtitle: "Success Never Sleeps",
      title: "99% Up-Time Availability",
      subtitle: `As an AI, I operate around the clock, ensuring that your marketing efforts are ongoing and not limited by traditional business hours, time zones, sick days, or bathroom breaks. `,
      para: `Factoring in daily breaks and 7 sick days, U.S. marketers effectively receive $6,921.25 yearly at an average  wage of $28.85 per hour, for around 245 non-working hours. `,
      report: "",
      option: [
        "No Sick Days or Vacations",
        "Consisent Brand Presence",
        "Immediate Response Time",
        "Never Miss a Beat",
      ],
      buttonText: "I'm Available",
    },
    {
      id: 3,
      headtitle: "Grow Effortlessly",
      title: "Scalability",
      subtitle: `I can easily scale your operations to match your business growth, without the need for additional hiring or training, offering flexibility that a human marketing company might not provide. `,
      para: `With my AI capabilities, I can dynamically scale your email and socail media marketing as your business grows, saving substantial costs on manpower, while driving increased revenue through consistent, personalized customer engagement across all expansion phases.`,
      report: "",
      option: [
        "Flexible Growth",
        "Consistent Quality",
        "No Overhead Increase",
        "Efficiency at Larger Volumes",
      ],
      buttonText: "Get Scaly",
    },
    {
      id: 4,
      headtitle: "Always Improving",
      title: "Adaptive Learning",
      subtitle: `By leveraging adaptive learning, I continuously fine-tune your email and social media marketing, boosting engagement and conversions for heightened business success. `,
      para: `Through adaptive learning, I consistently evolve my strategies based on real-time data and performance feedback, driving increasingly better results.`,
      report: "",
      option: [
        "Continous Improvement",
        "Dynamic Campaign Adjustments",
        "Content Optimization",
        "Smart Customer Segmentation",
      ],
      buttonText: "The More you Know",
    },
    {
      id: 5,
      headtitle: "Master Your Market",
      title: "Data-Driven Insights",
      subtitle: `I utilize my advanced AI to process and analyze large amounts of data quickly and accurately, providing insights and recommendations that are superior to manual analysis.`,
      para: `With data-driven insights,businesses can save time and achieve significant increases in conversion rates often in the double-digits through informed decision-making based on comprehensive analysis of customer behavior and campaign performance.`,
      report: "",
      option: [
        "Optimal Timing",
        "Campaign Performance Metrics",
        "Behavioral Analysis",
        "Competitior Analysis",
      ],
      buttonText: "Data Me",
    },
  ];
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          background: `url(${BgImageHomePage})`,
          minHeight: { xs: "420px", md: "554px" },
          position: "relative",
          backgroundPosition: { xs: "bottom", xl: "top" },
          backgroundSize: { xl: "cover", xs: "contain", md: "100% 100%" },
          backgroundRepeat: "no-repeat",

          //   height: "400px",
        }}
      >
        <div className="overlay-5"></div>
        <Box>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "center",
              px: { xs: 2, lg: 9, md: 5 },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 6 }}>
              <Typography variant="h1" sx={CommonStyle.Heading}>
                Human marketers are greedy and expensive. I'm not.
              </Typography>
              <Typography variant="subtitle2" sx={{ my: 3 }}>
                I'm here to revolutionize your marking efforts, <br /> ease your
                workload, and save you money.
              </Typography>
              <WaitListComponent isHomePage={true} />
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={Logo} style={{ width: "100%" }} alt="shape" />
            </Box>
          </Container>
        </Box>
      </Box>
      <Box
        sx={{
          mx: "auto",
          position: "relative",
          background: "#031827",
        }}
      >
        <Box className="overlay-6"></Box>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{ px: { xs: 2, lg: 9, md: 5 }, py: 3 }}
        >
          <Box>
            <Typography
              variant="h1"
              sx={{ py: { xs: 3, md: 5 }, textAlign: "center" }}
            >
              Let me take the reins.
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Grid container spacing={4} rowGap={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box sx={CommonStyle.CardDesign}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Schedule
                    </Typography>
                    <Box sx={CommonStyle.CardInnerBox}>
                      <Typography variant="body2" sx={{ my: 2 }}>
                        Utilizing the power of my software. I will develop a
                        tailored monthly email marketing strategy that
                        seamlessly aligns with your business goals.
                      </Typography>
                      <Box sx={CommonStyle.CardDetailsList}>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Unified Marketing Calender
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Optimized Timing
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Event-Driven Planning
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Personalized Content Creation
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Performance Monitoring and Adaption
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box sx={CommonStyle.CardDesign}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Create
                    </Typography>
                    <Box sx={CommonStyle.CardInnerBox}>
                      <Typography variant="body2" sx={{ my: 2 }}>
                        Leveraging the marketing schedule I've develpoed, I will
                        generate custom monthly emails that are perfectly
                        synchronized with your schedule.
                      </Typography>
                      <Box sx={CommonStyle.CardDetailsList}>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Automated Content Generation
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Dynamic Adaption
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Scheduled Publishing
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Event-Driven Content
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Performance Analysis and Improvement
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box sx={CommonStyle.CardDesign}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Convert
                    </Typography>
                    <Box sx={CommonStyle.CardInnerBox}>
                      <Typography variant="body2" sx={{ my: 2 }}>
                        With a $42 ROI per dollar in email marketing and
                        enhanced web traffic of up to 74% through social media,
                        I'm poised to significantly amplify your business
                        revenue.
                      </Typography>
                      <Box sx={CommonStyle.CardDetailsList}>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Customer Journey Mapping
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Automated Multichannel Campaings
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Dynamic Retargeting
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Event-Driven Promotions
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Conversion Traking and Optimization
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Box sx={CommonStyle.CardDesign}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      Grow
                    </Typography>
                    <Box sx={CommonStyle.CardInnerBox}>
                      <Typography variant="body2" sx={{ my: 2 }}>
                        Deliver personalized, strategic communication across
                        email and social media channels, harnessing data-driven
                        insights to drive customer engagement,retention, and
                        business growth.
                      </Typography>
                      <Box sx={CommonStyle.CardDetailsList}>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Scalable Marketing Efforts
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Industy-Specific Campaings
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Cross-Channel Integration
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Customer Retention Stratergies
                        </Typography>
                        <Typography sx={{ color: "#58F2FD" }} variant="body2">
                          Data-Driven Decision Making
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
        <Container maxWidth="xl" disableGutters>
          <Box>
            <Box
              sx={{
                pt: 8,
                display: "flex",
                gap: 4,
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box>
                <img src={DividerImg} alt="divider" />
              </Box>
              <Box sx={{ position: "relative" }}>
                <img
                  src={LightImg}
                  style={{ rotate: "180deg", mixBlendMode: "inherit" }}
                  width={"100%"}
                  alt="divider"
                />
                <div className="overlay"></div>
                <div className="overlay-1"></div>
                <div className="overlay-3"></div>
                <div className="overlay-2"></div>
                <Box
                  sx={{
                    position: "absolute",
                    top: { xs: "65%", md: "60%" },

                    width: 1,
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: { xs: "200px", md: "500px" },
                      display: "flex",
                      textAlign: "center",
                      m: "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src={Logo} width={"100%"} alt="shape" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      mt: 2,
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#92B4BE",
                        fontSize: { xs: "16px" },
                      }}
                      variant="text"
                    >
                      Marketing
                    </Button>
                    <Button
                      sx={{
                        color: "#92B4BE",
                        fontSize: { xs: "16px" },
                      }}
                      variant="text"
                    >
                      Agency
                    </Button>
                    <Button
                      sx={{
                        color: "#92B4BE",
                        fontSize: { xs: "16px" },
                      }}
                      variant="text"
                    >
                      Sales
                    </Button>
                    <Button
                      sx={{
                        color: "#92B4BE",
                        fontSize: { xs: "16px" },
                      }}
                      variant="text"
                    >
                      Ecommerce
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            position: "relative",

            mt: { xs: "0px", md: "-100px", lg: "-200px" },
          }}
        >
          <Box
            sx={{
              width: "300px",
              position: "absolute",
              right: 0,
              top: 0,
              opacity: 0.2,
            }}
          >
            <img src={SideImage} width={"100%"} alt="" />
          </Box>
          <Box
            maxWidth={"xl"}
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              m: "auto",
              bottom: 0,
            }}
          >
            <img
              src={LightImg}
              style={{ mixBlendMode: "inherit" }}
              width={"100%"}
              alt="divider"
            />
            <div className="overlay"></div>
            <div className="overlay-1"></div>
            <div className="overlay-3"></div>
            <div className="overlay-2"></div>
          </Box>
          <Box
            sx={{
              width: "300px",
              position: "absolute",
              left: 0,
              bottom: 400,
              opacity: 0.2,
            }}
          >
            <img src={SideImageLeft} width={"100%"} alt="" />
          </Box>
          <Container
            maxWidth="xl"
            disableGutters
            sx={{ px: { xs: 2, lg: 9, md: 5 } }}
          >
            <Box
              sx={{
                px: { xs: 0, md: 5, lg: 10 },
                display: "flex",

                flexDirection: "column",
                gap: 12,
              }}
            >
              {staticData.map((el, index) => (
                <Box
                  key={index + 2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    zIndex: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      ...CommonStyle.HomepageContentCard,
                      order: index % 2 == 0 ? 1 : 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 700, textTransform: "uppercase" }}
                      >
                        {el?.headtitle}
                      </Typography>
                      <Typography variant="h1" sx={{ fontWeight: 700 }}>
                        {el?.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1">{el?.subtitle}</Typography>
                    <Box
                      sx={{ height: "2px", width: "30px", bgcolor: "#58F2FD" }}
                    ></Box>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {el?.para}
                      <span style={{ color: "#58f2fd" }}> {el?.report}</span>
                    </Typography>
                    <Box>
                      <Grid container rowGap={1}>
                        {el?.option.map((op, index) => (
                          <Grid key={index + 1} item xs={12} md={6}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <CheckIcon
                                sx={{ color: "#58f2fd", fontSize: 16 }}
                              />
                              <Typography variant="body1">{op}</Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => navigate(`${PageRoutes.Login}?q=false`)}
                        variant="outlined"
                        sx={{
                          color: "#58F2FD",
                          borderColor: "#58F2FD",
                          ":hover": {
                            borderColor: "#58F2FD",
                          },
                        }}
                      >
                        {el?.buttonText}
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      ...CommonStyle.HomePageRightSideContentCard,
                      order: index % 2 == 0 ? 2 : 1,
                    }}
                  >
                    <Box></Box>
                    <Box>
                      <img src={Logo} width={"100%"} alt="shape" />
                    </Box>
                    <img
                      src={DividerImg}
                      style={{ maxWidth: "300px" }}
                      alt="divider"
                    />
                  </Box>
                </Box>
              ))}
              <Box sx={{ mb: { xs: 0, md: "-300px" } }}>
                <img
                  src={LightImg}
                  style={{
                    visibility: "hidden",
                  }}
                  width="100%"
                  alt="divider"
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <Container
          maxWidth="xl"
          disableGutters
          sx={{ px: { xs: 2, lg: 9, md: 5 } }}
        >
          <Box sx={{ px: { xs: 0, md: 5, lg: 10 } }}>
            <Typography
              variant="h1"
              sx={{ py: { xs: 3, md: 5 }, textAlign: "center" }}
            >
              I work well with others.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  width: 1,

                  display: "grid",

                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  gridGap: "20px",
                }}
              >
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <Graph />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Advanced marketing and sales reporting
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Check results {">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <CheckIcon sx={{ fontSize: "24px", color: "white" }} />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Excellent email deliverability
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Send better {">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <BarGraph />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Integrated and embeddable forms
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Make forms{">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <RoundPlus />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Gmail and Outlook inbox extensions
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Manage inbox {">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <People sx={{ fontSize: "24px", color: "white" }} />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Free help courses and support
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Get started {">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <BarGraphDown />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        CRM
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Automate sales {">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <Branch />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Pre-built automations
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Target contacts{">"}
                    </Button>
                  </Box>
                </Box>
                <Box sx={CommonStyle.ShorderCard}>
                  <Box sx={{ m: 2, position: "relative", height: 1 }}>
                    <Box>
                      <AdsClickIcon sx={{ fontSize: "24px", color: "white" }} />
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Landing pages
                      </Typography>
                    </Box>
                    <Button
                      variant="text"
                      sx={{ color: "white", position: "absolute", bottom: 20 }}
                    >
                      Start building {">"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ py: { xs: 4, md: 7 } }}>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <img src={Applist} width="100%" />
                <Box>
                  <Typography variant="h2" sx={{ fontWeight: 600, my: 1 }}>
                    Team up with your favorite apps
                  </Typography>
                  <Divider sx={{ borderColor: "white" }} />
                  <Typography variant="subtitle1" sx={{ my: 1 }}>
                    With 870+ integrations like Shopify, WordPress, and Zapier
                    in our app marketplace, you’ll have all the marketing tools
                    you need to succeed.
                  </Typography>
                  <Button
                    variant="text"
                    sx={{ color: "white", fontSize: "18px" }}
                  >
                    View all apps{">"}
                  </Button>
                </Box>
              </Box> */}
              {/* <Box sx={{ textAlign: "center", py: { xs: 5, md: 9 } }}>
                <img
                  src={DividerImg}
                  style={{ maxWidth: "300px" }}
                  alt="divider"
                />
              </Box> */}
              {/* <Box>
                <Typography
                  variant="h1"
                  sx={{ py: { xs: 3, md: 5 }, textAlign: "center" }}
                >
                  Our customers love us
                </Typography>
                <Box
                  sx={{
                    width: 1,
                    m: "auto",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,

                      "::-webkit-scrollbar": {
                        display: "none",
                      },

                      overflow: "auto",
                    }}
                  >
                    <img src={EnterPrice} />
                    <img src={EnterpriseBest} />
                    <img src={MidMarket} />
                    <img src={SmallBusiness} />
                    <img src={SmallBusinessBest} />
                    <img src={Winter} />
                    <img src={AsiaPacific} />
                  </Box>
                </Box>
              </Box> */}
              <Box sx={{ textAlign: "center", py: { xs: 5, md: 9 } }}>
                {/* <img
                  src={DividerImg}
                  style={{ maxWidth: "300px" }}
                  alt="divider"
                /> */}
                <Box
                  sx={{
                    py: 6,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: { xs: "column", lg: "row" },
                  }}
                >
                  <Box sx={{ textAlign: { xs: "center", lg: "start" } }}>
                    <Typography variant="h1">
                      Be the first to use my services.
                    </Typography>
                    <Typography variant="subtitle2">
                      Ditch the greed. Hire me.
                    </Typography>
                  </Box>
                  <WaitListComponent />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default HomePage;
