import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";
import { APIRoutes } from "../../../../utils/constants";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
const SchedulePopup: any = ({
  title,
  buttonName,
  open,
  handleCloseSchedulePopup,
  submitCloseSchedulePopup,
  id,
  campaign,
}: {
  title: string;
  buttonName: string;
  open: boolean;
  handleCloseSchedulePopup: any;
  submitCloseSchedulePopup: any;
  id: string;
  campaign: any;
}) => {
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const validate = (values: any) => {
    const errors: any = {};
    if (!values.utctime) {
      errors.utctime = "Please fill the required fields";
    }
    return errors;
  };

  const intialValue = {
    utctime: campaign && campaign.UTCtime ? new Date(campaign.UTCtime) : "",
  };

  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      try {
        console.log(values);
        setIsSkeleton(true);
        const utctime = values.utctime;
        const res = await client.put(APIRoutes.UpdateCampaign, {
          id: id,
          UTCtime: new Date(utctime).toISOString(),
          status: "Scheduled",
        });
        submitCloseSchedulePopup(res.data);
        formik.resetForm();
        toast.success("Your Campaign Schedule Successfully");
        setIsSkeleton(false);
      } catch (error: any) {
        toast.error(error.response.data.message);
        formik.resetForm();
        setIsSkeleton(false);
      }
    },
  });

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          bgcolor: "common.blue",
          minWidth: "350px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "22px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            fontSize: "small",
            color: "white",
            marginBottom: "10px",
          }}
        >
          <Typography variant="body2">
            Select when you want to send your campaign.
          </Typography>
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <Box>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                  marginBottom: "15px",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        timezone="UTC"
                        ampm={false}
                        value={
                          formik.values.utctime
                            ? dayjs(formik.values.utctime)
                            : null
                        }
                        label="Schedule Time"
                        onChange={(e) => {
                          console.log(e);
                          formik.setValues({
                            utctime: e ? e.toString() : "",
                          });
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </ThemeProvider>
              </Box>
              <Box
                sx={{
                  width: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="outlined"
                  onClick={handleCloseSchedulePopup}
                >
                  <Typography variant="body2">Cancel</Typography>
                </Button>
                <LoadingButton
                  loading={isSkeleton}
                  type="submit"
                  loadingPosition="start"
                  startIcon={isSkeleton && <SaveIcon />}
                  variant="contained"
                >
                  {buttonName}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default SchedulePopup;
