import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PopupMenu from "../PopUpMenu";
import axios from "../../../utils/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes } from "../../../utils/constants";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../../Common/Components/PopupMenu/Popup";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import he from "he";
const EmailTemplate = (props) => {
  const { data } = props;
  const params = useParams();
  const [templateData, setTemplateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const templateName = [
    "Email Template 1",
    "Email Template 2",
    "Email Template 3",
    "Email Template 4",
    "Email Template 5",
    "Email Template 6",
    "Email Template 7",
    "Email Template 8",
    "Email Template 9",
    "Email Template 10",
    "Email Template 11",
    "Email Template 12",
  ];
  const client = useAxiosPrivate(axios);
  const navigate = useNavigate();
  const handleGet = async () => {
    try {
      setLoading(true);
      const res = await client.get(APIRoutes.UserTemplateList + params.subId);
      setTemplateData(res.data.data);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  const submitForm = () => {
    handleGet();
  };
  useEffect(() => {
    handleGet();
  }, []);
  const addToFavourite = async (data) => {
    try {
      setLoading(true);
      const dataBody: any = {};
      const res = await client.put(
        APIRoutes.DefualtTemplateById.replace(":id", `${data.Id}`),
        dataBody
      );
      setLoading(false);
      handleGet();
    } catch (error: any) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  return (
    <Box>
      <Typography variant="h3">Email Template</Typography>
      {loading ? (
        <CircularProgress
          size={36}
          sx={{
            color: "#003876",
            marginTop: "30px",
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", xl: "90%" },
            margin: "0 auto",
            mt: 5,
          }}
        >
          <Grid container spacing={2}>
            {templateData?.map((item: any) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={item} p={0}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Card sx={{ maxWidth: 360, flex: 1, bgcolor: "#102432" }}>
                    {/* <CardMedia
                      sx={{ height: 160 }}
                      image="https://s3-alpha.figma.com/hub/file/731193152/5e812df6-d6d8-442a-b3da-d868868faf97-cover.png"
                      title=""
                    /> */}
                    <div id="wrapper">
                      <div
                        // className="phone view_2"
                        id="phone_1"
                        style={{
                          width: "330px",
                          height: "300px",
                        }}
                      >
                        <iframe
                          onLoad={(e) => {
                            var iframeDocument =
                              e.currentTarget.contentDocument ||
                              e.currentTarget.contentWindow!.document;
                            var body = iframeDocument.querySelector("body");
                            body!.style["zoom"] = "0.25";
                            body!.style.overflow = "hidden";
                          }}
                          className="template_frame"
                          srcDoc={he.decode(item.Template)}
                        ></iframe>
                        {/* <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div> */}
                      </div>
                    </div>
                    {/* <CardContent>
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        sx={{ color: "#70d8bd" }}
                        component="div"
                      >
                        {item.TemplateSID}
                      </Typography>
                    </CardContent> */}
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        padding: "15px",
                      }}
                    >
                      {
                        !item.isDefault && <Box sx={{
                          marginRight: "10px"
                        }}>
                          <Rating
                            onClick={() => {
                              addToFavourite(item);
                            }}
                            emptyIcon={
                              item.isDefault ? (
                                <StarIcon
                                  fontSize="inherit"
                                  sx={{ color: "#FFFFFF" }}
                                />
                              ) : (
                                <StarBorderIcon
                                  fontSize="inherit"
                                  sx={{ color: "#FFFFFF" }}
                                />
                              )
                            }
                            max={1}
                          />
                        </Box>
                      }
                      
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          navigate(
                            PageRoutes.EmailEditor.replace(":id", item.Id) +
                              `?type=template`
                          );
                        }}
                      >
                        Edit
                      </Button>
                      {/* <Popup
                        title="Delete"
                        data={item}
                        buttonName="Delete"
                        discription="Are you want to delete this template? this process can't be undo."
                        type="TemplateDelete"
                        submitForm={submitForm}
                      /> */}
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default EmailTemplate;
