import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageRoutes from "../../../utils/constants";
import Footer from "../Footer";

import Header from "../Header";
import HeaderPublicRoute from "../HeaderPrivateRoute/HeaderPrivateRoute";
import Sidebar from "../Sidebar";
import Style from "./MainContainer.style";
import Breadcrumb from "../Breadcrumbs";
import { Logout } from "../../../hooks/useAxiosPrivate";
import commonDetailSlice from "../../../store/reducers/commonReducer";
import { useDispatch } from "react-redux";
const MainContainer: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const [isFixed, setIsFixed] = useState<boolean>(true);
  const location = useLocation();
  let [count, setCount] = useState(
    60 * parseInt(process.env.REACT_APP_MINS_TO_LOGOUT || "")
  );
  const [open, setOpen] = useState(false);
  let ref: any = useRef(null);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const startTimeOut = () => {
    if (ref.current) {
      return;
    }

    ref.current = setInterval(() => {
      setCount(count--);

      if (count == 0) {
        handleSignout(true);
      }
    }, 1000);
  };

  const navigate = useNavigate();
  const handleSignout = (inActivity: boolean) => {
    localStorage.clear();
    sessionStorage.setItem("IsSessionActive", "false");
    dispatch(commonDetailSlice.actions.removecommonDetails());
    Logout();

    handleClose();
    navigate(PageRoutes.Login);
  };
  const stopTimeOut = () => {
    clearInterval(ref.current);
    ref.current = null;

    setCount(60 * parseInt(process.env.REACT_APP_MINS_TO_LOGOUT || ""));
    startTimeOut();
  };
  const handleClose = () => {
    stopTimeOut();
    setOpen(false);
  };
  const converTime = (counter) => {
    var minutes = Math.floor(counter / 60);
    var secondsRemaining = counter % 60;
    return (
      minutes + " min " + secondsRemaining.toString().padStart(2, "0") + " sec "
    );
  };

  useEffect(() => {
    startTimeOut();

    // if (!sessionStorage.getItem("IsSessionActive")) {
    //   handleSignout(true);
    // }

    return () => {
      clearInterval(ref.current);
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById("footer");
      const sidebarElement = document.getElementById("sidebar");

      if (footerElement && sidebarElement) {
        const footerOffset = footerElement.getBoundingClientRect().top;
        const sidebarHeight = sidebarElement.offsetHeight;
        const windowHeight = window.innerHeight;

        setIsFixed(footerOffset - sidebarHeight >= windowHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box
      onMouseMove={() => {
        !open && stopTimeOut();
      }}
      onKeyDown={() => {
        !open && stopTimeOut();
      }}
      onClick={() => {
        !open && stopTimeOut();
      }}
      onTouchStart={() => {
        !open && stopTimeOut();
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Sidebar />
      </Box>

      <Box
        className="right-panel"
        sx={{
          ml: {
            xs: "0px",
            md: "280px",
          },
        }}
      >
        {
          !location.pathname.startsWith(PageRoutes.Onboard) && <HeaderPublicRoute />
        }
        <Breadcrumb />
        <Box className="right-panel-padding" sx={{ bgcolor: "#031827", p: 3 }}>
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainContainer;
