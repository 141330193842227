import React from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Logout, getCurrentAuth } from "../../hooks/useAxiosPrivate";
import MainContainer from "../Components/MainContainer";

import PageRoutes from "../../utils/constants";
import useCommonDetails from "../Hooks/useCommonDetails";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import commonDetailSlice from "../../store/reducers/commonReducer";
function PrivateRoutes({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  const userDetails = useCommonDetails();
  const auth = getCurrentAuth();
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onIdle = () => {
    if (auth?.access) {
      Logout();
      navigate(PageRoutes.Login);
      toast.success("Logout successfully");
      dispatch(commonDetailSlice.actions.removecommonDetails());
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1800000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  if (!auth?.access) {
    return (
      <Navigate to={PageRoutes.Login} state={{ from: location }} replace />
    );
  }

  return <MainContainer>{children}</MainContainer>;
}

export default PrivateRoutes;
