import { CloseOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import Style from "../MyCampaigns/MyCampaigns.style";
import ProfileImage from "../../assets/img/Profile.png";
import Get_Started from "../../assets/img/Get_Started.png";
const Profile = () => {
  const [openColleps, setOpeColleps] = useState(true);
  const navigate = useNavigate();

  const handleCSVData = (result: any) => {
    // Define the expected column names
    const expectedColumns = [
      "No.",
      "First Name",
      "Last Name",
      "Email",
      "Gender",
      "Birth Date",
      "Intrest",
      "Location",
    ];
    console.log(result);
    const columnNames = result.meta.fields;

    // Check if all expected columns are present
    const missingColumns = expectedColumns.filter(
      (col) => !columnNames.includes(col)
    );
    if (missingColumns.length > 0) {
      console.log("Missing columns:", missingColumns);
      // Handle the error or show a notification to the user
      return;
    }

    // Additional validation if required (e.g., data formats, etc.)
    // ...

    // If everything is valid, you can access the CSV data in result.data
    console.log("CSV data:", result.data);
  };
  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={Style.MyCampaignsHeader}>
          <Typography variant="h2">Account Information</Typography>
        </Box>
      </Box>
      <Box sx={{ py: 5 }}>
        <Collapse sx={{ width: 1 }} in={openColleps}>
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpeColleps(false);
                }}
              >
                {" "}
                <CloseOutlined sx={{ color: "white" }} fontSize="inherit" />
              </IconButton>
            }
            sx={{
              bgcolor: "common.blue",

              "&.MuiPaper-root": { justifyContent: "space-between" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box>
                <img src={Get_Started} width="200px" />
              </Box>
              <Box
                sx={{
                  background: "common.blue",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1.5,
                }}
              >
                <Typography variant="body2">Get started with Airyis</Typography>
                <Typography variant="body2">
                  Check out our guide to get started on the right foot.
                </Typography>
                <Box>
                  <Button sx={{ display: "block" }} variant="outlined">
                    Marketer guide
                  </Button>
                </Box>
              </Box>
            </Box>
          </Alert>
        </Collapse>
        <Box sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} minWidth="177px" display={"flex"}>
              <Box sx={{ border: "1px solid #CCCCCC" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height:"100%",
                    ".MuiMenuItem-root+.MuiDivider-root": {
                      m: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      py: 3,
                      display: "flex",
                      height:"100%",
                      // flexWrap:"wrap",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1}}
                    >
                      <Typography variant="body1">Profile</Typography>
                      <Typography sx={{ color: "#cccccc" }} variant="body2">
                        Your personal & company information.
                      </Typography>
                    </Box>
                    <Box>
                        <img src={ProfileImage} width="50px" />
                    </Box>
                    
                  </Box>
                  <Divider sx={{ borderColor: "#cccccc", p: 0, m: 0 }} />
                  <MenuItem
                    onClick={() => navigate(PageRoutes.UpdateProfile)}
                    sx={{ p: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body2">Update Profile</Typography>
                    </Box>
                  </MenuItem>
                  <Divider sx={{ borderColor: "#cccccc", p: 0, m: 0 }} />
                  <MenuItem
                    onClick={() => navigate(PageRoutes.UpdateOnboard)}
                    sx={{ p: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body2">Update Onboarding</Typography>
                    </Box>
                  </MenuItem>
                  {/* <Divider sx={{ borderColor: "#cccccc", p: 0, m: 0 }} />
                  <MenuItem sx={{ p: 2 }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body2">
                        My Support tickets
                      </Typography>
                    </Box>
                  </MenuItem> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} minWidth="177px">
              <Box sx={{ border: "1px solid #CCCCCC" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ".MuiMenuItem-root+.MuiDivider-root": {
                      m: 0,
                    },
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      py: 3,
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body1">Account Settings</Typography>
                      <Typography sx={{ color: "#cccccc" }} variant="body2">
                        Enable tracking, display options in your dashboard.
                      </Typography>
                    </Box>
                    <Box>
                      <img src={ProfileImage} width="50px" />
                    </Box>
                  </Box>
                  <Divider sx={{ borderColor: "#cccccc", p: 0, m: 0 }} />
                  <MenuItem
                    onClick={() => navigate(PageRoutes.AccountSetting)}
                    sx={{ p: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body2">Account Settings</Typography>
                    </Box>
                  </MenuItem>
                  <Divider sx={{ borderColor: "#cccccc", p: 0, m: 0 }} />
                  <MenuItem
                    onClick={() => navigate(PageRoutes.StoreSetting)}
                    sx={{ p: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography variant="body2">Store Settings</Typography>
                    </Box>
                  </MenuItem>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
export default Profile;
