import React, { useState,useEffect } from 'react'
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Theme,
    Tooltip,
    Typography,
  } from "@mui/material";
import theme from '../../style/core/config/theme';
import { log } from 'console';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  sx: {
    ".MuiPaper-root": {
      fontSize: "14px",
      bgcolor: "#072B41",
    },
  },
};
function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const OnboardBox = ({id,que,ans,group,queType,option,data,setError}:{id:number,que:string,ans:string,group:string,queType:string,option:[],setData:any,data:any,setError:any})=> {

    const [error,setErrors] = useState(false)
    const [answer,setAnswer] = useState<any>(ans);  

    const handleChangeData = (e: any,i:any) => {
        setError(e.target.value?false:true);
        setErrors(e.target.value?false:true);
        setAnswer(e.target.value)
        data[id-1].ans=e.target.value; 
    };
    const handleChange = (event: any) => {
        
        const {
            target: { value },
        } = event;
        
        setError(value.length?false:true);
        setErrors(value.length?false:true);
        setAnswer(
            typeof value === "string" ? value.split(",") : value,
          );
        data[id-1].ans=typeof value === "string" ? value.split(",") : value; 
        }
    return (
      <Box sx={{ color: "#FFFFFF",bgcolor: "#102432",borderRadius:"10px",p:"30px",objectFit: ""}}>

        <Typography variant='h4' sx={{fontSize:{xs:"18px",sm:"22px"},fontWeight:500}}>{group}</Typography>
        <Box sx={{py:"16px",display:"flex",gap:"8px",alignItems:"flex-start"}}>
            <Typography variant='h6'sx={{fontSize:{xs:"14px",sm:"17px"}}}>{id}</Typography>
            <Box sx={{width:"60%"}}>
                <Typography variant='h6' sx={{fontSize:{xs:"14px",sm:"17px"},mb:"15px"}}>{que}</Typography>
                {queType == "textfeild" ?(
                    <TextField
                    placeholder="Answer"
                    onChange={(e) => handleChangeData(e,id)}
                    error={error}
                    helperText={error ? "Field is required" : ""}
                    value={answer}
                    sx={{
                    width: 1,
                    input: {
                        color: "white",
                    },}}/>
                ): queType == "textarea" ? (
                    <TextField
                      placeholder="Answer"
                      onChange={(e) => handleChangeData(e,id)}
                      // variant="standard"
                      multiline
                      rows={5}
                      error={error}
                      helperText={error ? "Field is required" : ""}
                      value={answer}
                      sx={{
                        width: 1,
                        // background: "white",
                        input: {
                          color: "white",
                        },
                      }}
                    />):queType == "select-multiple" ? (
                        <FormControl sx={{ width: 300 }}>
                          <Select
                            id="demo-multiple-chip"
                            multiple
                            value={answer || []}
                            onChange={handleChange}
                            input={<OutlinedInput/>}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                               {selected.map((value: any) => (
                                  <Chip
                                    sx={{
                                      color: "#FFFFFF",
                                      bgcolor: "#072B41",
                                    }}
                                    key={value}
                                    label={value}
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {option.map((name: any) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, answer, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ): queType == "select-single-id" ? (
                        <FormControl sx={{ width: 300 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={answer}
                            defaultValue={""}
                            displayEmpty
                            onChange={(e) => handleChangeData(e,id)}
                            autoWidth
                            required={true}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {option.map((ele: any) => (
                              <MenuItem value={ele.id}>{ele.name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : queType == "select-single" ? (
                        <FormControl sx={{ minWidth: 80 }}>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={answer}
                            defaultValue={""}
                            displayEmpty
                            onChange={(e) => handleChangeData(e,id)}
                            autoWidth
                            required={true}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {option.map((name: any) => (
                              <MenuItem value={name}>{name}</MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ): queType == "tone" ? (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={answer}
                            onChange={(e) => handleChangeData(e,id)}
                            color="red"
                          >
                            {option.map((el: any, i: number) => (
                              <Tooltip title={el?.tooltip} placement="top">
                                <FormControlLabel
                                  value={el?.itme}
                                  key={i + 1}
                                  control={<Radio />}
                                  label={el?.itme}
                                />
                              </Tooltip>
                            ))}
                          </RadioGroup>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      ) : (
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            value={answer}
                            onChange={(e) => handleChangeData(e,id)}
                            color="red"
                          >
                            {option.map((el: any, i: number) => (
                              <FormControlLabel
                                value={el}
                                key={i + 1}
                                control={<Radio />}
                                label={el}
                              />
                            ))}
                          </RadioGroup>
                          <FormHelperText
                            sx={{ color: "error.main" }}
                            id="component-error-text"
                          >
                            {error && "Please Select Option"}
                          </FormHelperText>
                        </FormControl>
                      )}
            </Box>
        </Box>
      </Box>
  )
}

export default OnboardBox