import {
    Box,
    CircularProgress,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useLocation, useParams } from "react-router-dom";
  import axios from "../../utils/axios";
  import adminaxios from "../../utils/adminaxios";
  import useAxiosPrivate from "../../hooks/useAxiosPrivate";
  import { APIRoutes } from "../../utils/constants";
  import { toast } from "react-toastify";
  import EnhancedTable from "./TableComponent";
  import axiosurl from 'axios';
  // import EnhancedTable from './TableComponent';
  
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  const StyledTab = styled(Tab)({
    color: "#FFFFFF",
    textTransform: "none",
    padding: 0,
  
    minHeight: "40px",
  });
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3 }}>
            <Typography variant="body2">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const Catogary = () => {
    const location = useLocation();
    const [templateData, setTemplateData] = useState<any>();
    const { id } = useParams();
    const client = useAxiosPrivate(axios);
    const [isLoading, setLoading] = useState(false);
    const handleGet = async () => {
      try {
        setLoading(true);
        //const res = await client.get(APIRoutes.ShopifyProductsList);
        const res = await adminaxios.get(APIRoutes.ShopifyProductsList);
        
        setTemplateData(res.data.data);
        setLoading(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
  
    useEffect(() => {
      handleGet();
    }, []);
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2">Shopify Products</Typography>
        </Box>
        <Box sx={{ py: { xs: 2, md: 5 } }}>
          <Box sx={{ width: "100%" }}>
            {isLoading ? (
              <CircularProgress
                size={36}
                sx={{
                  color: "#003876",
                }}
              />
            ) : (
              templateData && (
                <EnhancedTable data={templateData} isCatogary={true} />
              )
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default Catogary;
  