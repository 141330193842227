import { SxProps } from "@mui/material";

const CommonStyle: { [key: string]: SxProps } = {
  Heading: { fontWeight: 700, fontSize: "42px", lineHeight: "58px" },
  CardDesign: {
    background: "rgba(255, 255, 255, 0.05)",
    borderTop: "10px solid #58F2FD",
    borderRadius: "10px 10px 9px 9px",
    padding: "20px 20px 20px 14px",
    minHeight: 0.9,
  },
  CardDetailsList: {
    display: "flex",
    flexDirection: "column",
    gap: 1.5,
    mt: 2,
  },
  CardInnerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  HomepageContentCard: {
    display: "flex",
    flexDirection: "column",
    width: { xs: 1, md: 0.45 },
    gap: 2.5,
  },
  HomePageRightSideContentCard: {
    display: { xs: "none", md: "flex" },
    width: 0.45,
    flexGrow: 1,
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",

    justifyContent: "space-between",
  },
  ShorderCard: {
    height: "250px",
    width: "250px",
    m: "auto",

    background: " rgba(255, 255, 255, 0.05)",
  },
};

export default CommonStyle;
