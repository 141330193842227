import { SxProps } from "@mui/material";
import BgImage from "../../assets/img/SIGNUP.png";
const Style: { [key: string]: SxProps } = {
  MyCampaignsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Accordion: {
    backgroundColor: "common.blue",
    color: "#fff",
    // height: "80px",
  },
  AccordionSummary: {
    height: "100%",
    padding: "22px 16px",
  },
};
export default Style;
