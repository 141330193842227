import { SvgIcon } from "@mui/material";

const AutomationIcon = (prop: any) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="transparent"
      {...prop}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 19.25H13.125C17.5 19.25 19.25 17.5 19.25 13.125V7.875C19.25 3.5 17.5 1.75 13.125 1.75H7.875C3.5 1.75 1.75 3.5 1.75 7.875V13.125C1.75 17.5 3.5 19.25 7.875 19.25Z"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6238 16.1875V12.775M13.6238 6.51875V4.8125M13.6238 6.51875C13.9225 6.51875 14.2183 6.57759 14.4944 6.69192C14.7704 6.80625 15.0212 6.97383 15.2324 7.18508C15.4437 7.39633 15.6113 7.64713 15.7256 7.92315C15.8399 8.19916 15.8988 8.49499 15.8988 8.79375C15.8988 9.09251 15.8399 9.38834 15.7256 9.66435C15.6113 9.94037 15.4437 10.1912 15.2324 10.4024C15.0212 10.6137 14.7704 10.7812 14.4944 10.8956C14.2183 11.0099 13.9225 11.0687 13.6238 11.0687C13.0204 11.0687 12.4417 10.8291 12.0151 10.4024C11.5884 9.97577 11.3488 9.39712 11.3488 8.79375C11.3488 8.19038 11.5884 7.61173 12.0151 7.18508C12.4417 6.75844 13.0204 6.51875 13.6238 6.51875ZM7.37626 16.1875V14.4812M7.37626 14.4812C7.67501 14.4812 7.97085 14.4224 8.24686 14.3081C8.52288 14.1937 8.77367 14.0262 8.98492 13.8149C9.19618 13.6037 9.36375 13.3529 9.47808 13.0769C9.59241 12.8008 9.65126 12.505 9.65126 12.2062C9.65126 11.9075 9.59241 11.6117 9.47808 11.3356C9.36375 11.0596 9.19618 10.8088 8.98492 10.5976C8.77367 10.3863 8.52288 10.2188 8.24686 10.1044C7.97085 9.99009 7.67501 9.93125 7.37626 9.93125C6.77289 9.93125 6.19423 10.1709 5.76759 10.5976C5.34094 11.0242 5.10126 11.6029 5.10126 12.2062C5.10126 12.8096 5.34094 13.3883 5.76759 13.8149C6.19423 14.2416 6.77289 14.4812 7.37626 14.4812ZM7.37626 8.225V4.8125"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
export default AutomationIcon;
