import { ResponsivePie } from "@nivo/pie";
import { mockPieData as data } from "../ColumnChart/mockData";

const PieChart = () => {
  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke:"#e0e0e0",
            },
          },
          legend: {
            text: {
              fill:"#e0e0e0",
            },
          },
          ticks: {
            line: {
              stroke:"#e0e0e0",
              strokeWidth: 1,
            },
            text: {
              fill:"#e0e0e0",
            },
          },
        },
        legends: {
          text: {
            fill:"#e0e0e0",
          },
        },
      }}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#e0e0e0"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0.4}
      arcLabelsSkipAngle={7}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      
    />
  );
};

export default PieChart;