import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import RootReducer from "./reducers";

const persistConfig = {
  key: "bitflexmine",
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [],
});

export default store;
