const RoundPlus = () => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6004 11.5473C21.6004 16.8493 17.3024 21.1473 12.0004 21.1473C6.69854 21.1473 2.40047 16.8493 2.40047 11.5473C2.40047 6.24541 6.69854 1.94734 12.0004 1.94734C17.3024 1.94734 21.6004 6.24541 21.6004 11.5473ZM23.5204 11.5473C23.5204 17.9096 18.3628 23.0673 12.0004 23.0673C5.63815 23.0673 0.480469 17.9096 0.480469 11.5473C0.480469 5.18502 5.63815 0.0273438 12.0004 0.0273438C18.3628 0.0273438 23.5204 5.18502 23.5204 11.5473ZM11.0404 10.5873V6.74734H12.9604V10.5873H16.8004V12.5073H12.9604V16.3473H11.0404V12.5073H7.20046V10.5873H11.0404Z"
          fill="white"
        />
      </svg>
    );

}
export default RoundPlus