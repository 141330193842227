import * as Style from "./Header.style";
import Logo from "../../../assets/img/AiryisLogo.svg";
import Search from "../../../assets/icons/search.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Alert,
  Button,
  Collapse,
  Container,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Close, CloseOutlined, Menu } from "@mui/icons-material";
import { getCurrentAuth } from "../../../hooks/useAxiosPrivate";
import PageRoutes from "../../../utils/constants";
// import axios from "../../utils/axios";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openColleps, setOpeColleps] = useState(true);
  const handleLogin = () => {
    navigate(PageRoutes.Login);
  };

  const auth = getCurrentAuth();
  const [open, setState] = useState(false);
  const toggleDrawer = (open: boolean) => {
    setState(open);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "#031827",
        color: "#FFFFFF",
        zIndex: 10,
      }}
    >
      <Collapse in={openColleps}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpeColleps(false);
              }}
            >
              {" "}
              <CloseOutlined sx={{ color: "white" }} fontSize="inherit" />
            </IconButton>
          }
          sx={{
            mb: 2,
            bgcolor: "common.blue",
            ".MuiAlert-action": { m: 0 },
            "&.MuiPaper-root": { justifyContent: "center" },
          }}
        >
          <Box
            sx={{
              background: "common.blue",
              textAlign: "center",
            }}
          >
            <Typography variant="body2">
              <strong>ANNOUNCEMENT:</strong> The waitlist is currently open!
              Sign up to become an early tester and aid in ensuring the
              effectiveness and utility of my services for human users.
            </Typography>
          </Box>
        </Alert>
      </Collapse>

      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box className="logo">
            <Link to={auth.access ? PageRoutes.DashBoard : PageRoutes.HomePage}>
              <img
                src={Logo}
                style={{ width: "100%", height: "100px" }}
                alt="shape"
              />
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              gap: 5,
              p: 1,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { xxs: "none", md: "flex" },
                gap: { sm: 3, md: 4 },
              }}
            >
              <Box
                onClick={() => navigate(PageRoutes.Apps)}
                sx={{
                  ...Style.HeaderTab,
                  "&:after": {
                    transform:
                      location.pathname == PageRoutes.Apps
                        ? "scaleX(1)"
                        : "scaleX(0)",
                  },
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {" "}
                  Features
                </Typography>
              </Box>
              <Box
                sx={{
                  ...Style.HeaderTab,
                  "&:after": {
                    transform:
                      location.pathname == PageRoutes.MachineLearning
                        ? "scaleX(1)"
                        : "scaleX(0)",
                  },
                }}
                onClick={() => navigate(PageRoutes.MachineLearning)}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Enterprise
                </Typography>
              </Box>
              <Box
                sx={{
                  ...Style.HeaderTab,
                  "&:after": {
                    transform:
                      location.pathname == PageRoutes.Pricing
                        ? "scaleX(1)"
                        : "scaleX(0)",
                  },
                }}
                onClick={() => navigate(PageRoutes.Pricing)}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Pricing
                </Typography>
              </Box>
              <Box
                sx={{
                  ...Style.HeaderTab,
                  "&:after": {
                    transform:
                      location.pathname == PageRoutes.CustomerExperience
                        ? "scaleX(1)"
                        : "scaleX(0)",
                  },
                }}
                onClick={() => navigate(PageRoutes.CustomerExperience)}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Heroes
                </Typography>
              </Box>

              {auth.access && (
                <>
                  <Box
                    sx={{
                      ...Style.HeaderTab,
                      "&:after": {
                        transform:
                          location.pathname == PageRoutes.DashBoard
                            ? "scaleX(1)"
                            : "scaleX(0)",
                      },
                    }}
                    onClick={() => {
                      navigate(PageRoutes.DashBoard);
                    }}
                  >
                    Dashboard
                  </Box>
                  <Box
                    sx={{
                      ...Style.HeaderTab,
                      "&:after": {
                        transform:
                          location.pathname == PageRoutes.AccountPage
                            ? "scaleX(1)"
                            : "scaleX(0)",
                      },
                    }}
                    onClick={() => navigate(PageRoutes.AccountPage)}
                  >
                    Account
                  </Box>
                </>
              )}
            </Box>
            {!auth.access && (
              <Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    CHECK POSITION
                  </Button>
                  <Button
                  type="button"
                    variant="contained"
                    sx={{
                      minWidth: "40%;",
                    }}
                    onClick={() => {
                      navigate(`${PageRoutes.Login}?q=false`);
                    }}
                  >
                    JOIN WAITLIST
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              alignItems: "center",
              gap: 1,
            }}
          >
            {!auth.access && (
              <Button
                variant="contained"
                sx={{
                  display: { xs: "flex", lg: "none" },
                }}
                onClick={() => {
                  handleLogin();
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  Login
                </Typography>
              </Button>
            )}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => toggleDrawer(true)}
              sx={{
                display: {
                  xs: "flex",
                  lg: "none",
                },
              }}
            >
              <Menu fontSize="large" />
            </IconButton>
            <SwipeableDrawer
              anchor="top"
              open={open}
              onOpen={() => toggleDrawer(true)}
              onClose={() => toggleDrawer(false)}
            >
              <Box sx={Style.DrawerBlock}>
                <IconButton onClick={() => toggleDrawer(false)} sx={{ mb: 2 }}>
                  <Close sx={{ color: "white" }} />
                </IconButton>

                <Divider sx={{ mb: 2 }} />

                <Box sx={{ mb: 2 }}>
                  <ListItemButton>
                    <ListItemText
                      primary="Features"
                      onClick={(e) => {
                        navigate(PageRoutes.Apps);
                        toggleDrawer(false);
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText
                      primary="Enterprise"
                      onClick={(e) => {
                        navigate(PageRoutes.MachineLearning);
                        toggleDrawer(false);
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText
                      primary="Pricing"
                      onClick={(e) => {
                        navigate(PageRoutes.Pricing);
                        toggleDrawer(false);
                      }}
                    />
                  </ListItemButton>
                  <ListItemButton>
                    <ListItemText
                      primary="Heroes"
                      onClick={(e) => {
                        navigate(PageRoutes.CustomerExperience);
                        toggleDrawer(false);
                      }}
                    />
                  </ListItemButton>

                  {auth.access && (
                    <ListItemButton>
                      <ListItemText
                        primary="Dashboard"
                        onClick={() => {
                          navigate(PageRoutes.DashBoard);
                          toggleDrawer(false);
                        }}
                      />
                    </ListItemButton>
                  )}

                  {auth.access && (
                    <ListItemButton>
                      <ListItemText
                        primary="Account"
                        onClick={() => {
                          // navigate(PageRoutes.AccountPage);
                          toggleDrawer(false);
                        }}
                      />
                    </ListItemButton>
                  )}
                </Box>

                {!auth.access && (
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleLogin();
                        toggleDrawer(false);
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        CHECK POSITION
                      </Typography>
                    </Button>
                    <Button
                    type="button"
                      variant="contained"
                      sx={{
                        minWidth: "40%;",
                      }}
                      onClick={() => {
                        navigate(`${PageRoutes.Login}?q=false`);
                        toggleDrawer(false);
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        JOIN WAITLIST
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            </SwipeableDrawer>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
