import { useNavigate } from "react-router-dom";

import { APIRoutes } from "../utils/constants";
import { authDetailsType, getCurrentAuth, Logout } from "./useAxiosPrivate";
import axios from "../utils/axios";
import PageRoutes from "../utils/constants";

export function setAuth(data: authDetailsType) {
  const oldAuth = getCurrentAuth();
  const tempAuth = { ...oldAuth, ...data };
  tempAuth.access = data.access;
  tempAuth.refresh = data.refresh;
  localStorage.setItem("auth", JSON.stringify(tempAuth));
}

const useRefreshToken = () => {
  const navigate = useNavigate();

  // var auth = useTypedSelector(getAuthState, shallowEqual);
  const auth = getCurrentAuth();

  const refresh = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${auth.access}`,
      };
      const response = await axios.post(
        APIRoutes.RefreshToken,
        {
          refreshToken: auth.refresh,
        },
        { headers: headers }
      );
      if (response.data) {
        const body: authDetailsType = response.data;
        console.log(body);
        setAuth(body);
        return response.data.access.token;
      }
      return;
    } catch (error: any) {
      // dispatch(logout());
      Logout();
      // navigate(PageRoutes.Login);
    }
  };
  return refresh;
};

export default useRefreshToken;
