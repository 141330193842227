import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import moment from "moment";
const CampaignViewPopup: any = ({
  title,
  buttonName,
  open,
  handleCloseScheduleViewPopup,
  submitViewPopup,
  data,
}: {
  title: string;
  buttonName: string;
  open: boolean;
  handleCloseScheduleViewPopup: any;
  submitViewPopup: any;
  data: any;
}) => {
  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          bgcolor: "common.blue",
          minWidth: "400px",
        },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "22px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            <Box
              sx={{
                width: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                marginBottom: "15px",
              }}
            >
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"h4"}>Name : </Typography>
                <Typography sx={{ marginLeft: "10px" }} variant={"body1"}>
                  {data.title}
                </Typography>
              </Box> */}

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"h4"}>Subject : </Typography>
                <Typography sx={{ marginLeft: "10px" }} variant={"body1"}>
                  {data.Subject}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"h4"}>From : </Typography>
                <Typography sx={{ marginLeft: "10px" }} variant={"body1"}>
                  {data.From}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"h4"}>Status : </Typography>
                <Typography sx={{ marginLeft: "10px" }} variant={"body1"}>
                  {data.Status}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography component={"h4"}>Schedule Time : </Typography>
                <Typography sx={{ marginLeft: "10px" }} variant={"body1"}>
                  {moment(data.start.toString()).format("DD/MM/YYYY h:mm a")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{ marginRight: "10px" }}
                variant="outlined"
                onClick={handleCloseScheduleViewPopup}
              >
                <Typography variant="body2">{buttonName}</Typography>
              </Button>
              <LoadingButton
                sx={{ marginRight: "10px" }}
                loading={false}
                onClick={() => submitViewPopup(data)}
                type="submit"
                loadingPosition="start"
                variant="contained"
              >
                View Campaign
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CampaignViewPopup;
