import axios from "axios";

 

export default axios.create({
  //baseURL: "https://api.airyis.com/v1",
  //baseURL: "https://airyis-be-old.vercel.app/v1",
  //baseURL: "1http://localhost:3000/v",
  //baseURL: "http://52.32.58.3/v1",
  //baseURL: " https://8618-103-107-61-97.ngrok-free.app/v1",

    baseURL: "https://api-airyis.denish-faldu.in/v1",
});
