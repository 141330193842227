import { Settings } from "@mui/icons-material";
import {
  TableCell,
  Checkbox,
  Box,
  Rating,
  Typography,
  Chip,
  IconButton,
  TableRow,
  Avatar,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { FC, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import Style from "../../../../Common/Components/HeaderPrivateRoute/HeaderPrivateRoute.style";
import Popup from "../../../../Common/Components/PopupMenu/Popup";
import { useNavigate } from "react-router-dom";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import UpdateCampaign from "../../../../Common/Components/Campaign/UpdateCampaign";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import axios from "../../../../utils/axios";

interface TableProps {
  data: any;
  labelId: any;
  isItemSelected: any;
  handleClick: any;
  updateMainAPI: any;
}
const StyleMenuItem = styled(MenuItem)({
  fontSize: "14px",
});
const TableRowData: FC<TableProps> = (props) => {
  const { data, labelId, isItemSelected, handleClick, updateMainAPI }: any = props;
  const [openColleps, setOpeColleps] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateOpen, setUpdateOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const client = useAxiosPrivate(axios);
  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitForm = (value: any) => {
    console.log(value);
    updateMainAPI();
  };

  const updateForm = (Value: any) => {
    handleUpdateClose();
    updateMainAPI();
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  }

  const addToFavourite = async () => {
    try {
      const dataBody: any = {
        isFavorite: data.isFavorite ? false : true
      };
      const res = await client.put(APIRoutes.AddtoFavourite.replace(":id", `${data.Id}`), dataBody);
      // setIsSkeleton(false);
      updateMainAPI();
    } catch (error: any) {
      toast.error(error.response.data.message);
      // setIsSkeleton(false);
    }
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={data.Name}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      {/* <TableCell onClick={(event) => handleClick(event)} padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell> */}
      <TableCell component="th" id={labelId} scope="row" sx={{ px: 1 }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Box>
            <Rating
              onClick={() => {
                addToFavourite()
              }}
              emptyIcon={
                data.isFavorite ? <StarIcon fontSize="inherit" sx={{ color: "#FFFFFF" }}/> : <StarBorderIcon fontSize="inherit" sx={{ color: "#FFFFFF" }} />
              }
              max={1}
            />
          </Box>
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{ textDecoration: "underline" }}
              variant="body2"
              component={"a"}
              onClick={() =>
                navigate(
                  PageRoutes.MyCampaignsInnerList.replace(":id", `${data.Id}`)
                )
              }
            >
              {data.Name}
            </Typography>
            {/* <Typography variant="body2" component={"a"}>
              Untitled campaign
            </Typography> */}
          </Box>
        </Box>
      </TableCell>
      <TableCell align="left">
        {data.NumberOfDaysCampaign}
        {/* <Chip sx={{ bgcolor: "#CCCCCC", height: "24px" }} label={"Draft"} /> */}
      </TableCell>
      <TableCell align="left">{data.clickCount}</TableCell>
      <TableCell align="left">{data.openCount}</TableCell>
      {/* <TableCell align="left"> -</TableCell>
      <TableCell align="left"> -</TableCell> */}
      <TableCell align="right">
        <IconButton onClick={handleClickMenu}>
          <Settings sx={{ color: "#FFFFFF" }} />
        </IconButton>
        <Menu
          id="header-menu"
          aria-labelledby="menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: Style.MenuDropDown,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <StyleMenuItem
            onClick={() => {
              setUpdateOpen(true);
            }}
          >
            Rename
          </StyleMenuItem>
          {/* <StyleMenuItem onClick={handleClose}>Priview</StyleMenuItem>
          <StyleMenuItem onClick={handleClose}>Duplicate</StyleMenuItem> */}

          <Popup
            title="Delete campaign"
            data={data}
            buttonName="Delete"
            discription="Are you want to delete this campain? this process can't be undo."
            type="Campaign"
            submitForm={submitForm}
          />

          {/* <Popup
            title="Archive campaign"
            buttonName="Archive"
            discription="Archived campaigns appear in the Archived Folder and are hidden from other lists. You can restore an archived campaign to its original status at any time."
          />

          <StyleMenuItem onClick={handleClose}>View activity log</StyleMenuItem> */}
        </Menu>
      </TableCell>
      {updateOpen && (
        <UpdateCampaign
          data={data}
          description={""}
          title={"Update Campaign"}
          open={updateOpen}
          buttonName={"Save"}
          handleUpdateClose={handleUpdateClose}
          updateForm={updateForm}
        />
      )}
    </TableRow>
  );
};
export default TableRowData;
