import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import PageRoutes from "./utils/constants";
import Dashboard from "./Pages/Dashboard";

import PrivateRoutes from "./Common/RouteAccessor/PrivateRoute";
import CustomerExperience from "./Pages/CustomerExperience";
import PublicRoute from "./Common/RouteAccessor/PublicRoute";
import HomePage from "./Pages/HomePage";
import Apps from "./Pages/Apps";
import MachineLearning from "./Pages/MachineLearning";
import Pricing from "./Pages/Pricing";
import Onboard from "./Pages/Onboard";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import VerifyEmail from "./Pages/VerifyEmail";
import ForgetPassword from "./Pages/ForgetPassword";

// import EmailEditorPage from "./Pages/EmailEditorPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import DataDeletion from "./Pages/DataDeletion";
import TermAndCondition from "./Pages/TermAndCondition";
import MyCapaigns from "./Pages/MyCampaigns";
import MyCampaignDetail from "./Pages/MyCampaignDetail";
import Contacts from "./Pages/Contact";
import Profile from "./Pages/Profile";
import UpdateProfile from "./Pages/Profile/UpdateProfile";
import ContantDetails from "./Pages/Contact/ContactDetails";
import AccountSetting from "./Pages/Profile/AccontSettings";
import WaitList from "./Pages/WaitList";
import NOPageFound from "./Pages/NoPageFound";
import UpdateOnboard from "./Pages/Profile/UpdateOnboard";
import ContactDetail from "./Pages/ContactDetail";
import MyInnerCampaignList from "./Pages/MyInnerCampaignList";
import StoreSetting from "./Pages/Profile/StoreSettings";
 
import EmailCatogary from "./Pages/EmailCatogary";
import EmailTemplate from "./Pages/EmailCatogary/Template";
 
import Calender from "./Pages/Calender";
import EasyEmailEditor from "./Pages/EasyEmailEditor";
import CustomEditor from "./Pages/CustomEditor";
import Catogary from "./Pages/EmailCatogary/Catogary";
import ShopifyProducts from "./Pages/ShopifyProducts";

function App() {
  return (
    <Router>
      <GoogleOAuthProvider clientId="544221636010-c56l6pceasttji7k3hgfotf454r4ot69.apps.googleusercontent.com">
        <ToastContainer
          position="top-center"
          autoClose={1500}
          limit={2}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          icon={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="dark"
        />
        <Routes>
          <Route
            path={PageRoutes.Login}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.CustomerExperience}
            element={
              <PublicRoute>
                <CustomerExperience />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.TermAndCondition}
            element={
              <PublicRoute>
                <TermAndCondition />
              </PublicRoute>
            }
          />

          <Route
            path={PageRoutes.HomePage}
            element={
              <PublicRoute>
                <HomePage />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.Apps}
            element={
              <PublicRoute>
                <Apps />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.PrivacyPolicy}
            element={
              <PublicRoute>
                <PrivacyPolicy />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.DataDeletion}
            element={
              <PublicRoute>
                <DataDeletion />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.MachineLearning}
            element={
              <PublicRoute>
                <MachineLearning />
              </PublicRoute>
            }
          />

          <Route
            path={PageRoutes.Pricing}
            element={
              <PublicRoute>
                <Pricing />
              </PublicRoute>
            }
          />
          <Route path={PageRoutes.Any} element={<NOPageFound />} />

          <Route
            path={PageRoutes.VerifyEmail}
            element={
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.ResetPassword}
            element={
              <PublicRoute>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={PageRoutes.Onboard}
            element={
              <PrivateRoutes>
                <Onboard />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRoutes.EmailEditor}
            element={
              <PrivateRoutes>
                {/* <EasyEmailEditor /> */}
                <CustomEditor />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRoutes.EmailCatogary}
            element={
              <PrivateRoutes>
                <EmailCatogary />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRoutes.EmailTemplate}
            element={
              <PrivateRoutes>
                <EmailTemplate />
              </PrivateRoutes>
            }
          />
          
          <Route
            path={PageRoutes.IndustryCatogary}
            element={
              <PrivateRoutes>
                <Catogary />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRoutes.ShopifyProducts}
            element={
              <PrivateRoutes>
                <ShopifyProducts />
              </PrivateRoutes>
            }
          />

          <Route
            path={PageRoutes.DashBoard}
            element={
              <PrivateRoutes>
                <Dashboard />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.MyCampaigns}
            element={
              <PrivateRoutes>
                <MyCapaigns />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.MyCampaignsInnerList}
            element={
              <PrivateRoutes>
                <MyInnerCampaignList />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.Profile}
            element={
              <PrivateRoutes>
                <Profile />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.UpdateProfile}
            element={
              <PrivateRoutes>
                <UpdateProfile />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.UpdateOnboard}
            element={
              <PrivateRoutes>
                <UpdateOnboard />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.WaitListPage}
            element={
              <PrivateRoutes>
                <WaitList />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.ContactDetails}
            element={
              <PrivateRoutes>
                <ContantDetails />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.Contact}
            element={
              <PrivateRoutes>
                <Contacts />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.AccountSetting}
            element={
              <PrivateRoutes>
                <AccountSetting />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.StoreSetting}
            element={
              <PrivateRoutes>
                <StoreSetting />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.ContactCreate}
            element={
              <PrivateRoutes>
                <ContactDetail />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.MyCampaignDetail}
            element={
              <PrivateRoutes>
                <MyCampaignDetail />
              </PrivateRoutes>
            }
          />
          <Route
            path={PageRoutes.Calender}
            element={
              <PrivateRoutes>
                <Calender />
              </PrivateRoutes>
            }
          />
        </Routes>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
