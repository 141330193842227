import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";

// import GoogleLogin from "react-google-login";
import AiryisLogo from "../../assets/img/AiryisLogo.svg";
import Style from "./Login.style";
// import FacebookLogin from "react-facebook-login";
// import AppleLogin from "react-apple-login";
import "./index.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { useFormik } from "formik";
import { commonDetailSlice } from "../../store/reducers/commonReducer";
import { useDispatch } from "react-redux";
import useCommonDetails from "../../Common/Hooks/useCommonDetails";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../utils/axios";
import PageRoutes, { APIRoutes } from "../../utils/constants";
import { setAuth } from "../../hooks/useRefreshToken";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import {
  Apple,
  Google,
  Facebook,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";

const Login: FC = () => {
  const [flagLogin, setFlagLogin] = useState(true);
  const [serachParams, setSearchParams] = useSearchParams({ q: "true" });
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [url, setUrl] = useState("");
  const passwordflag = serachParams.get("password-reset");
  const responseGoogle = (response: any) => {
    console.log(response);
  };
  const formikRef = useRef<any>(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  async function getEntries(data: any) {
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const client = useAxiosPrivate(axios);

  const responseFacebook = (response: any) => {
    console.log(response);
  };
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  } as any);

  const handleClickShowPassword = (flag: any) => {
    const item = { ...showPassword };
    item[flag] = !showPassword[flag];

    setShowPassword(item);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const intialValue: {
    [key: string]: string;
  } = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  };

  const handleLoginGoogle = async (credentialResponse: any) => {
    setIsSkeleton(true);
    try {
      const data = {
        token: credentialResponse.credential,
      };
      const res = await client.post(APIRoutes.GoogleAuth, data);
      if (res.data.tokens) {
        toast.success("Login Successful");
        setAuth(res.data.tokens);

        getEntries(res.data.user);
        res.data.user.IsOnboarded==false?navigate(PageRoutes.Onboard):navigate(PageRoutes.DashBoard);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const validate = (values: any) => {
    const errors: any = {};
    const flag = serachParams.get("q");
    const emailRegx = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const passwordRegx = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`=+ -])(?=.{8,})[^\s]+$/
    );
    if (!values.email) {
      errors.email = "Please fill the required fields";
    } else if (!emailRegx.test(values.email)) {
      errors.email = "Please enter valid email id";
    }

    if (!values.password && !passwordflag) {
      errors.password = "Please fill the required fields";
    } else if (!passwordRegx.test(values.password) && flag == "false") {
      errors.password =
        "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
    }
    if (flag == "false") {
      if (!values.first_name) {
        errors.first_name = "Please fill the required fields";
      }
      if (!values.last_name) {
        errors.last_name = "Please fill the required fields";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "Please fill the required fields";
      }
    }
    return errors;
  };

  const responseApple = async (response: any) => {
    try {
      const res = await client.post(APIRoutes.AppleAuth, {
        code: response.code,
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: intialValue,
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }
      try {
        setIsSkeleton(true);
        if (serachParams.get("q") == "false") {
          const first_name = values.first_name;
          const last_name = values.last_name;
          const password = values.password;
          const email = values.email;
          const dataBody: any = {
            first_name,
            last_name,
            password,
            email,
          };
          const res = await client.post(APIRoutes.Register, dataBody);
          if (res.data.tokens) {
            toast.success(
              "Varification email has been sent please verify you email"
            );
            serachParams.delete("q");
            setSearchParams(serachParams);
            setIsSkeleton(false);
          }
          setIsSkeleton(false);
        } else if (passwordflag) {
          const email = values.email;
          const dataBody: any = {
            email,
          };

          const res: any = await client.post(
            APIRoutes.ForgetPassword,
            dataBody
          );
          if (res) {
            toast.success("Password reset email has been sent");

            setIsSkeleton(false);
          }
          setIsSkeleton(false);
        } else {
          const password = values.password;
          const email = values.email;
          const dataBody: any = {
            password,
            email,
          };

          const res: any = await client.post(APIRoutes.Login, dataBody);
          if (res.data.tokens) {
            toast.success("Login Successful");
            setAuth(res.data.tokens);
            console.log(res.data);
            getEntries(res.data.user);
            res.data.user.IsOnboarded==false?navigate(PageRoutes.Onboard):navigate(PageRoutes.DashBoard);
            setIsSkeleton(false);
          }

          setIsSkeleton(false);
          formik.resetForm();
        }
      } catch (error: any) {
        toast.error(error.response.data.message);
        setIsSkeleton(false);
        formik.resetForm();
      }
    },
  });
  const commonDetails = useCommonDetails();

  const condition = serachParams.get("q") == "true" || !serachParams.get("q");

  const handleChangeLogin = () => {
    formik.resetForm();

    if (condition) {
      setSearchParams({ q: "false" });
    } else {
      setSearchParams({ q: "true" });
    }
  };
  const getAppleLoginUrl = async () => {
    setIsSkeleton(true);
    try {
      const res: any = await client.get(APIRoutes.AppleAuth);

      setUrl(res?.data);

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    getAppleLoginUrl();
    if (location?.state?.email) {
      intialValue.email = location?.state?.email;
    }
    if (serachParams.get("appleLogin")) {
      const data = JSON.parse(serachParams.get("appleLogin") || "");

      if (data?.isAppleLoginFailed) {
        toast.error("Something went wrong");
      } else {
        toast.success("Login Successful");
        setAuth(data.tokens);

        getEntries(data.user);
        navigate(PageRoutes.WaitListPage);
      }
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse, "tokenResponse");
      handleLoginGoogle(tokenResponse);
    },
    flow: 'auth-code'
  });

  return (
    <Box sx={{ minHeight: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={Style.LoginBox}>
              <Box sx={Style.LoginLeft}>
                <Box>
                  <img src={AiryisLogo} width="100%" />
                </Box>
                {!passwordflag ? (
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        handleLoginGoogle(credentialResponse);
                      }}
                      useOneTap
                      size="large"
                      width="100%"
                      containerProps={{
                        style: {
                          width: "100% !important",
                        },
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                    {/* <Button
                      sx={Style.LoginWithExternal}
                      onClick={() => login()}
                    >
                      <Google sx={{ color: "black" }} />
                      Login with Google
                      <Box></Box>
                    </Button> */}

                    {/* <FacebookLogin
                      appId={"174179425629680"}
                      autoLoad={false}
                      fields="name,email,picture"
                      onSuccess={(response) => {
                        console.log("Login Success!", response);
                      }}
                      onFail={(error) => {
                        console.log("Login Failed!", error);
                      }}
                      onProfileSuccess={(response) => {
                        console.log("Get Profile Success!", response);
                      }}
                      style={{
                        padding: "0px",
                        border: "none",
                        borderRadius: "2px",
                      }}
                      children={
                        <Button
                          fullWidth
                          sx={{
                            width: 1,
                            color: "#000000",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: 1,
                            }}
                          >
                            <Facebook sx={{ color: "#4267B2" }} />
                            Login with Facebook
                            <Box></Box>
                          </Box>
                        </Button>
                      }
                    /> */}

                    <Button
                      type="button"
                      sx={Style.LoginWithExternal}
                      onClick={() => {
                        if (url) {
                          window.location.href = url;
                        }
                      }}
                    >
                      <Apple sx={{ color: "black" }} />
                      Login with Apple
                      <Box></Box>
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                {condition ? (
                  // <Box sx={{ width: 1 }}>
                  //   <Divider>
                  //     <Chip
                  //       sx={{ bgcolor: "white", color: "black" }}
                  //       label="OR"
                  //     />
                  //   </Divider>
                  // </Box>
                  <></>
                ) : (
                  <></>
                )}
                <Box sx={{ width: 1, textAlign: "end" }}>
                  {!passwordflag && (
                    <Typography
                      variant="body2"
                      onClick={handleChangeLogin}
                      sx={{ color: "white", mb: 0.5, cursor: "pointer" }}
                    >
                      {condition
                        ? "Don't have account?"
                        : "Already have account?"}
                    </Typography>
                  )}
                  <TextField
                    size="small"
                    fullWidth
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.email = "";
                    }}
                    error={formik.errors.email ? true : false}
                    helperText={formik.errors.email}
                    sx={{
                      ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    type={"email"}
                    InputProps={{ sx: { bgcolor: "white" } }}
                    placeholder={"Email"}
                  />
                </Box>
                {condition ? (
                  <></>
                ) : (
                  <Box
                    sx={{
                      width: 1,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1.5,
                    }}
                  >
                    <TextField
                      size="small"
                      fullWidth
                      name="first_name"
                      id="first_name"
                      value={formik.values.first_name}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.first_name = "";
                      }}
                      error={formik.errors.first_name ? true : false}
                      helperText={formik.errors.first_name}
                      sx={{
                        ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                        input: {
                          color: "black",
                        },
                      }}
                      type={"text"}
                      InputProps={{ sx: { bgcolor: "white" } }}
                      placeholder={"First Name"}
                    />
                    <TextField
                      size="small"
                      fullWidth
                      name="last_name"
                      value={formik.values.last_name}
                      id="last_name"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.last_name = "";
                      }}
                      error={formik.errors.last_name ? true : false}
                      helperText={formik.errors.last_name}
                      sx={{
                        ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                        input: {
                          color: "black",
                        },
                      }}
                      type={"text"}
                      InputProps={{ sx: { bgcolor: "white" } }}
                      placeholder={"Last Name"}
                    />
                  </Box>
                )}
                {!passwordflag && (
                  <TextField
                    size="small"
                    fullWidth
                    type={showPassword?.password ? "text" : "password"}
                    value={formik.values.password}
                    name="password"
                    id="password"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.password = "";
                    }}
                    error={formik.errors.password ? true : false}
                    helperText={formik.errors.password}
                    sx={{
                      ".MuiInputBase-root": { fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    InputProps={{
                      sx: { bgcolor: "white" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("password")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword.password ? (
                                <VisibilityOff sx={{ color: "black" }} />
                              ) : (
                                <Visibility sx={{ color: "black" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                          {condition && (
                            <Button
                              onClick={() => {
                                serachParams.set("password-reset", "true");
                                setSearchParams(serachParams);
                                formik.resetForm();
                              }}
                              sx={{ color: "#CCCCCC", fontSize: "14px" }}
                              variant="text"
                            >
                              Forgot?
                            </Button>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={"Password"}
                  />
                )}

                {condition ? (
                  <></>
                ) : (
                  <TextField
                    value={formik.values.confirm_password}
                    size="small"
                    fullWidth
                    type={showPassword?.confirm_password ? "text" : "password"}
                    name="confirm_password"
                    id="confirm_password"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.confirm_password = "";
                      setConfirmPasswordError("");
                    }}
                    onBlur={(e) => {
                      if (formik.values.password) {
                        if (formik.values.password != e.target.value) {
                          setConfirmPasswordError(
                            "Confirm password do not match with entered password"
                          );
                        } else {
                          setConfirmPasswordError("");
                        }
                      }
                    }}
                    error={
                      formik.errors.confirm_password || confirmPasswordError
                        ? true
                        : false
                    }
                    helperText={
                      formik.errors.confirm_password || confirmPasswordError
                    }
                    InputProps={{
                      sx: { bgcolor: "white" },
                      endAdornment: (
                        <InputAdornment position="end">
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("confirm_password")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword.confirm_password ? (
                                <VisibilityOff sx={{ color: "black" }} />
                              ) : (
                                <Visibility sx={{ color: "black" }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      ".MuiInputBase-root": { fontSize: "14px" },
                      input: {
                        color: "black",
                      },
                    }}
                    placeholder={"Confirm Password"}
                  />
                )}

                <Box
                  sx={{
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {!passwordflag && (
                    <Box>
                      <FormControlLabel
                        sx={{
                          visibility: condition ? "auto" : "hidden",
                          color: "white",
                          ".MuiTypography-root": { fontSize: "14px" },
                        }}
                        control={
                          <Checkbox size="small" sx={{ color: "white" }} />
                        }
                        label="Remember my username"
                      />
                    </Box>
                  )}
                  {!passwordflag ? (
                    <Box>
                      {condition || !serachParams.get("q") ? (
                        <LoadingButton
                          loading={isSkeleton}
                          type="submit"
                          loadingPosition="start"
                          startIcon={isSkeleton && <SaveIcon />}
                          variant="contained"
                        >
                          Login
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          loading={isSkeleton}
                          type="submit"
                          startIcon={isSkeleton && <SaveIcon />}
                          loadingPosition="start"
                          variant="contained"
                        >
                          Sign up
                        </LoadingButton>
                      )}
                    </Box>
                  ) : (
                    <Box>
                      {" "}
                      <LoadingButton
                        loading={isSkeleton}
                        type="submit"
                        loadingPosition="start"
                        startIcon={isSkeleton && <SaveIcon />}
                        variant="contained"
                      >
                        Send
                      </LoadingButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Login;
