import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import Logo from "../../assets/img/AiryisLogo.svg";
import SpikeLeft from "../../assets/img/SpikeLeft.svg";
import SpikeRight from "../../assets/img/SpikeRight.svg";
import Shopifylogo from "../../assets/img/shopifylogo.png";
import Wordpresslogo from "../../assets/img/wordpresslogo.png";
import Wixlogo from "../../assets/img/wixlogo.png";
import DividerImg from "../../assets/img/DividerImage.svg";
import WaitListComponent from "../../Common/Components/WaitListComponent";

const Apps = () => {
  return (
    <Box>
      <Box>
        <Box>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "center",
              px: { xs: 2, lg: 9, md: 5 },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ py: 6 }}>
              <Typography variant="h1" sx={CommonStyle.Heading}>
                Connect to your favorite apps
              </Typography>
              <Typography variant="subtitle2" sx={{ my: 5 }}>
                With over 920+ apps, you can connect with the tools that <br />
                make the most sense for your business
              </Typography>
              <WaitListComponent />
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img src={Logo} style={{ width: "100%" }} alt="shape" />
            </Box>
          </Container>
        </Box>
      </Box>

      <Box>
        <Box sx={{ display: "flex", gap: 2, px: { xs: 2, sm: 0 }, mt: 10 }}>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img src={SpikeLeft} alt="SpikeLeft" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              gap: { xs: 12, lg: 0 },
            }}
          >
            <Grid
              container
              spacing={4}
              rowGap={3}
              sx={{ justifyContent: "center", height: "max-content" }}
            >
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <img src={Shopifylogo} alt="Shopifylogo" />
                    </Box>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, mt: 1 }}
                      >
                        Shopify
                      </Typography>
                      <Typography variant="body2" sx={{}}>
                        Ecommerce
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={CommonStyle.CardInnerBox}>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      Drive more purchases by integrating AIRYIS with Shopify.
                      Strengthen your Ecommerce platform with world- class
                      marketing automation.
                    </Typography>
                    <Typography
                      sx={{
                        color: "#58F2FD",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      variant="body1"
                    >
                      Learn More
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <img src={Wixlogo} alt="Wixlogo" />
                    </Box>
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Wix
                      </Typography>
                      <Typography variant="body2" sx={{}}>
                        Website builder
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={CommonStyle.CardInnerBox}>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      The AIRYIS and Wix integration allows you to identify and
                      nurture leads using industry-leading automation. Learn how
                      to integrate with AIRYIS today.
                    </Typography>
                    <Typography
                      sx={{
                        color: "#58F2FD",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      variant="body1"
                    >
                      Learn More
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                <Box sx={CommonStyle.CardDesign}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box>
                      <img src={Wordpresslogo} alt="Wordpresslogo" />
                    </Box>
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        Wordpress
                      </Typography>
                      <Typography variant="body2" sx={{}}>
                        content management system
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={CommonStyle.CardInnerBox}>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      Use the AIRYIS Wordpress integration to send transactional
                      emails from your automations.
                    </Typography>
                    <Typography
                      sx={{
                        color: "#58F2FD",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                      variant="body1"
                    >
                      Learn More
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <img src={DividerImg} alt="divider" />
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <img src={SpikeRight} alt="SpikeRight" />
          </Box>
        </Box>
        <Container maxWidth="xl" disableGutters>
          <Box
            sx={{
              mt: 10,
              pb: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Typography sx={{ textAlign: "center" }}>
              Bring your imagination to life with the AIRYIS API
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "#FFFFFF",
              }}
            >
              Go to Developer Portal
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Apps;
