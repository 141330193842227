import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Typography,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  DialogActions,
} from "@mui/material";

import { useFormik } from "formik";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCommonDetails from "../../../../Common/Hooks/useCommonDetails";
import useAxiosPrivate, {
  getCurrentAuth,
} from "../../../../hooks/useAxiosPrivate";
import PageRoutes, { APIRoutes } from "../../../../utils/constants";
import SaveIcon from "@mui/icons-material/Save";
import axios from "../../../../utils/axios";
const ChangePassowordPopup = () => {
  const auth = getCurrentAuth();
  const userDetails = useCommonDetails();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const client = useAxiosPrivate(axios);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isSucces, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validate = (values: any) => {
    const errors: any = {};

    const passwordRegx = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.[\]{}()?\-"!@#%&\/\\,><':;|_~`=+ -])(?=.{8,})[^\s]+$/
    );
    if (!values.current_password) {
      errors.current_password = "Please fill the required fields";
    }
    if (!values.confirm_password) {
      errors.confirm_password= "Please fill the required fields";
    }
    if (!values.password) {
      errors.password = "Please fill the required fields";
    } else if (!passwordRegx.test(values.password)) {
      errors.password =
        "The password must contain at least one lowercase letter, one uppercase letter, one digit, one symbol, and be a minimum of 8 characters long.";
    }
    // console.log(errors);

    return errors;
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      current_password: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (confirmPasswordError) {
        return;
      }
      try {
        setIsSkeleton(true);
        const res: any = await client.post(APIRoutes.PasswordChange, {
          password: values.current_password,
          newPassword: values.password,
        });
        if (res) {
          toast.success("Password reset successfully");
          navigate(PageRoutes.Login);
          setIsSkeleton(false);
        }
        setIsSkeleton(false);
      } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.message);

        setIsSuccess(false);
        setIsSkeleton(false);
      }
    },
  });

  return (
    <>
      <Typography
        onClick={handleClickOpen}
        variant="caption"
        sx={{ color: "#b2b2b2", mx: 1, my: 0.5, cursor: "pointer" }}
      >
        Change password
      </Typography>

      <Dialog
        open={open}
        sx={{
          ".MuiPaper-root": {
            bgcolor: "common.blue",
          },
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            id="alert-dialog-title"
          >
            <Typography variant="h3">{"Change Password"}</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <TextField
                  size="small"
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"password"}
                  value={formik.values.current_password}
                  name="current_password"
                  id="current_password"
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    formik.errors.current_password = "";
                  }}
                  error={formik.errors.current_password ? true : false}
                  helperText={formik.errors.current_password}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Current Password"}
                />
                <TextField
                  size="small"
                  fullWidth
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"password"}
                  value={formik.values.password}
                  name="password"
                  id="password"
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    formik.errors.password = "";
                  }}
                  error={formik.errors.password ? true : false}
                  helperText={formik.errors.password}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Password"}
                />
                <TextField
                  value={formik.values.confirm_password}
                  size="small"
                  fullWidth
                  autoComplete="off"
                  name="confirm_password"
                  id="confirm_password"
                  onChange={(e: any) => {
                    formik.handleChange(e);
                    formik.errors.confirm_password = "";
                    setConfirmPasswordError("");
                  }}
                  onBlur={(e: any) => {
                    if (formik.values.password) {
                      if (formik.values.password != e.target.value) {
                        setConfirmPasswordError(
                          "Confirm password do not match with entered password"
                        );
                      } else {
                        setConfirmPasswordError("");
                      }
                    }
                  }}
                  error={
                    formik.errors.confirm_password || confirmPasswordError
                      ? true
                      : false
                  }
                  helperText={
                    formik.errors.confirm_password || confirmPasswordError
                  }
                  InputProps={{
                    sx: { bgcolor: "white" },
                  }}
                  type={"text"}
                  sx={{
                    my: 1,
                    ".MuiInputBase-root": { pr: "0px", fontSize: "14px" },
                    input: {
                      color: "black",
                    },
                  }}
                  placeholder={"Confirm Password"}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <LoadingButton
              loading={isSkeleton}
              type="submit"
              onClick={() => {
                // console.log("kas");
              }}
              loadingPosition="start"
              startIcon={isSkeleton && <SaveIcon />}
              variant="contained"
            >
              Reset
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default ChangePassowordPopup;
