import { Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import React from 'react'

const InfoInput = ({infoText}:any) => {
  return (  
    <Tooltip title={<Typography variant="body2">{infoText}</Typography>} placement="right">
    <InfoIcon fontSize='medium'/>
  </Tooltip>
  )
}

export default InfoInput