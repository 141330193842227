import { Settings } from "@mui/icons-material";
import {
  TableCell,
  Checkbox,
  Box,
  Rating,
  Typography,
  Chip,
  IconButton,
  TableRow,
  Avatar,
  Menu,
  MenuItem,
  styled,
  Button,
} from "@mui/material";
import { FC, useState } from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageRoutes from "../../../utils/constants";
import Style from "../../../Common/Components/HeaderPrivateRoute/HeaderPrivateRoute.style";
import Popup from "../../../Common/Components/PopupMenu/Popup";

interface TableProps {
  data: any;
  labelId: any;
  isItemSelected: any;
  handleClick: any;
  updateMainAPI: any;
}
const StyleMenuItem = styled(MenuItem)({
  fontSize: "14px",
});
const ContactDetailsTableRow: FC<TableProps> = (props) => {
  const { data, labelId, isItemSelected, handleClick ,updateMainAPI}: any = props;
  const [openColleps, setOpeColleps] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClickMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (value:any) =>{
    console.log(value);
    updateMainAPI();
  }
  const formateDate = (strDate:any) =>{
    const date = new Date(strDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} at ${hours}:${minutes} ${amOrPm}`;
  }
  const newCreate = formateDate(data.createdAt);
  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={data.name}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      <TableCell onClick={(event) => handleClick(event)} padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" sx={{ px: 0 }}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",

              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{ textDecoration: "underline" }}
              variant="body2"
              component={"a"}
            >
              {data.Email}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell align="left">
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2">
            {newCreate}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.FirstName}</Typography>
      </TableCell>
      <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.LastName}</Typography>
      </TableCell>
      <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.Gender}</Typography>
      </TableCell>
      <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.Birthday}</Typography>
      </TableCell>
      {/* <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.Interests}</Typography>
      </TableCell>
      <TableCell align="left">
        {" "}
        <Typography variant="body2">{data.Location}</Typography>
      </TableCell> */}
      <TableCell align="right">
        <IconButton onClick={handleClickMenu}>
          <Settings sx={{ color: "#FFFFFF" }} />
        </IconButton>
        <Menu
          id="header-menu"
          aria-labelledby="menu-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: Style.MenuDropDown,
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* <StyleMenuItem onClick={handleClose}>Unsubscribe</StyleMenuItem>

          <StyleMenuItem onClick={handleClose}>
            Exclude from Campaigns
          </StyleMenuItem> */}
          <Popup
            title="Delete contact"
            data={data}
            buttonName="Delete"
            discription="Are you want to delete this contact? this process can't be undo."
            type="InnerContact"
            submitForm={handleSubmit}
          />
        </Menu>
      </TableCell>
    </TableRow>
  );
};
export default ContactDetailsTableRow;
