const DataDeletion = () => {
  return (
    <div className="container">
      <h1>Facebook Data Deletion Instructions</h1>
      <p>To delete your Facebook data, follow the steps below:</p>
      <ol>
        <li>Log in to your Facebook account.</li>
        <li>Go to the "Settings & Privacy" section.</li>
        <li>Click on "Settings".</li>
        <li>Scroll down and click on "Your Facebook Information".</li>
        <li>Click on "Delete Your Account and Information".</li>
        <li>Follow the on-screen instructions to confirm the deletion.</li>
      </ol>
      <p>
        Note: Deleting your Facebook account is irreversible and will
        permanently remove all your data from the platform.
      </p>
    </div>
  );
};
export default DataDeletion;
