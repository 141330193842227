import React from "react";
import "../dashboard-style.css"
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function Cards({ Icons, text, values }:{Icons:any;text:string;values:string }) {
  const style = {
    display: "flex",
    padding: "20px 22px",
    bgcolor:"#102432",
    borderRadius:"10px",
    justifyContent: "flex-start",
    flex: 1,
    alignItems: "center",
  };
  return (
    <Box sx={style}>
      <Icons sx={{ fontSize: "45px" }} /> 
      <Box sx={{ padding: "0 0 0 35px"}}>
        <Typography variant="h3" color="white">{values}</Typography>
        <Typography variant="body2" sx={{ color : "#70d8bd", cursor:"pointer"}}>{text}</Typography>
      </Box>
    </Box>);
}

export default Cards;
